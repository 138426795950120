import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hol-snackbar',
  templateUrl: './hol-snackbar.component.html',
  styleUrls: ['./hol-snackbar.component.sass'],
})
export class HolSnackbarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
