export const acceptedMimeTypes: string[] = [
  'audio/aac', // .aac
  'video/x-msvideo', // .avi
  'text/csv', // .csv
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/epub+zip', // .epub
  'image/gif', // .gif
  // 'text/html', // .htm, .html
  'text/calendar', // .ics
  'image/jpeg', // .jpeg, .jpg
  'text/markdown', // .md
  'video/mpeg', // .mpeg
  'video/quicktime', // .mov
  'audio/mpeg', // .mp3
  'video/mp4', // .mp4
  'application/vnd.oasis.opendocument.presentation', // .odp
  'application/vnd.oasis.opendocument.spreadsheet', // .ods
  'application/vnd.oasis.opendocument.text', // .odt
  'application/pdf', // .pdf
  'image/png', // .png
  'application/vnd.ms-powerpoint', // .ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'application/rtf', // .rtf
  'image/tiff', // .tif, .tiff
  'text/plain', // .txt
  'text/vcard', // .vcf
  'audio/wav', // .wav
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/zip', // .zip
];
