import { OclDecision } from '../../ocl/models/ocl-decision.model';
import { EclFunction } from './ecl-function';
import { HolTag } from '../../common/models/hol-tag';
import { EclCrisis } from './ecl-crisis';
import { EclSummary } from './ecl-summary';
import { InSummary } from '../../ocl/models/ocl-interface.model';

export class EclDecision extends OclDecision implements InSummary {
  public function: EclFunction;
  public crisis: EclCrisis;
  public summary: EclSummary;

  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject, tags);
    if (!parseObject) {
      return;
    }
    this.function = new EclFunction(parseObject.get('function'));
    this.crisis = new EclCrisis(parseObject.get('crisis'));
  }
}
