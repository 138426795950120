import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeOnlyDay',
})
export class TimeOnlyDayPipe implements PipeTransform {
  public transform(value: any, utc?: any): any {
    if (value) {
      let momentDate = moment(value);
      let dateFormat = 'ddd DD';
      if (utc) {
        dateFormat += '[Z]';
        momentDate = momentDate.utc();
      }
      return momentDate.format(dateFormat);
    }
    return null;
  }
}
