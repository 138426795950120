import { Component, Inject, Input, OnInit } from '@angular/core';
import { HolFlight } from '../../models/hol-flight.model';
import * as moment from 'moment';

@Component({
  selector: 'app-holster-stopover',
  templateUrl: './holster-stopover.component.html',
  styleUrls: ['./holster-stopover.component.scss'],
})
export class HolsterStopoverComponent implements OnInit {
  @Input() flights: HolFlight[];
  @Input() accessRights: any;
  @Input() isReadOnly: boolean;
  @Input() isDeparture: boolean;
  private currentState = '';

  constructor(@Inject('$state') private $state) {}

  ngOnInit(): void {
    this.currentState = this.$state.current.name; 
  }

  openOtherFlightPage($event, flight): void {
    this.$state.go(this.currentState, { flightId: flight.objectId});
  }

  trackByFunction(index, item): string | number {
    if (!item || !item.objectId) {
      return index;
    }
    return item.objectId;
  }
}
