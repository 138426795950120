<div class="hol-dialog-wrapper add-next-info-modal">
  <mat-toolbar class="mat-toolbar">
    <div class="title" mat-dialog-title>
      <span [innerHTML]="modalTitle"></span>
    </div>
    <button [disabled]="inProgress ? 'true' : 'false'" mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <div class="content-grid">
      <p class="{{ modalType }}" [innerHTML]="modalContent"></p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="action-bar">
      <div class="fill-space"></div>
      <button (click)="close()" [disabled]="inProgress" mat-dialog-close class="action action-validate">
        <span class="material-symbols-outlined">check</span>
        <span>{{ 'COMMON.BUTTON.OK' | translate | uppercase }}</span>
      </button>
    </div>
  </mat-dialog-actions>
</div>
