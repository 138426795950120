import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddNextInfoModalComponent } from '../../modals/add-next-info-modal/add-next-info-modal.component';
import { DetailNextInfoModalComponent } from '../../modals/detail-next-info-modal/detail-next-info-modal.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  openAddNextInfo(config: MatDialogConfig) {
    return this.dialog.open(AddNextInfoModalComponent, config);
  }

  openDetailNextInfo(config: MatDialogConfig) {
    return this.dialog.open(DetailNextInfoModalComponent, config);
  }
}
