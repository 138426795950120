import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy',
})
export class GroupByPipe implements PipeTransform {
  public transform(collection: Array<any>, property: string): Array<any> {
    if (!collection) {
      return null;
    }
    const grouped = collection.reduce((prev, current) => {
      if (!prev[current[property]]) {
        prev[current[property]] = [current];
      } else {
        prev[current[property]].push(current);
      }
      return prev;
    }, {});
    return Object.keys(grouped).map(key => ({ key, value: grouped[key] }));
  }
}
