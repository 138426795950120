import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss'],
})
export class ClockComponent implements OnInit, OnDestroy {
  @Input() utcOffset;
  time: Moment;

  private intervalId;

  constructor() {}

  ngOnInit() {
    this.time = moment();
    this.intervalId = setInterval(() => {
      this.time = moment();
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }
}
