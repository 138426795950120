import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { extend, orderBy } from 'lodash';
import * as moment from 'moment';
import { FlyIn } from 'src/app/common/models/hol-animations';
import { CommonService } from 'src/app/common/services/common.service';
import { LocalStorageService } from 'src/app/common/services/local-storage.service';

import { BaseComponent } from '../../../common/components/base/base.component';
import { HolNextInfo } from '../../../common/models/hol-next-info.model';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { NotificationsService } from '../../../common/services/notifications/notifications.service';
import { RolesService } from '../../../common/services/roles.service';
import { OclGroupModalComponent } from '../../modals/ocl-group-modal/ocl-group-modal.component';
import { OclDecision } from '../../models/ocl-decision.model';
import { OclEventGroup } from '../../models/ocl-event-group.model';
import { OclEvent } from '../../models/ocl-event.model';
import { OclGlobalInstructionGroup } from '../../models/ocl-global-instruction-group.model';
import { OclLogbook } from '../../models/ocl-logbook.model';
import { OclGroupService } from '../../services/ocl-group-service/ocl-group.service';
import { HolUTCDisplay, utcEval } from '../../../common/enums/hol-utc-display.enum';
import { OclLinksHelperService } from '../../services/ocl-links-helper-service/ocl-links-helper.service';
import { OclLinkedDisplayedItemsStoreManager } from '../../store/linked-display-items/ocl-linked-displayed-items-store-manager.service';
import { OclDecisionsStoreManager } from '../../store/decisions/ocl-decisions.store-manager';
import { OclEventsStoreManager } from '../../store/events/ocl-events-store-manager.service';
import { MatDialogRef } from '@angular/material/dialog';
import { OCL_LOGBOOK_MODAL_TOKEN } from '../../modals/ocl-logbook-modal/ocl-logbook-modal.token';
import { OCL_DECISIONS_MODAL_TOKEN } from '../../modals/ocl-decisions-modal/ocl-decisions-modal.token';
import { OCL_EVENT_MODAL_TOKEN } from '../../modals/ocl-event-modal/ocl-event-modal-tokens';
import { OclFlightStatusService } from '../../services/ocl-flight-status-service/ocl-flight-status.service';
import { take } from 'rxjs/operators';
import { OclScenariosStoreManager } from '../../store/scenarios/ocl-scenarios.store-manager';
import { HolScenario } from '../../../common/models/hol-scenario';
import { OclScenarioService } from '../../services/ocl-scenario-service/ocl-scenario.service';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { EclDecisionGroup } from '../../../ecl/models/ecl-decision-group.model';
import { EclLogbookGroup } from '../../../ecl/models/ecl-logbook-group.model';

@Component({
  selector: 'app-ocl-container-group-item',
  templateUrl: './ocl-container-group-item.component.html',
  styleUrls: ['./ocl-container-group-item.component.scss'],
  animations: [FlyIn],
})
export class OclContainerGroupItemComponent extends BaseComponent implements OnInit {
  @Input() userCanEditCards?: boolean;
  @Input() eclInfos;
  @Input()
  public group: any;
  @Input()
  public groupsFromApi: OclEventGroup[];
  @Input()
  public isReadOnly: boolean;
  @Input() isSummaryActivated? = false;
  @Input()
  public events: OclEvent[];
  @Input()
  public decisionItems: OclDecision[];
  @Input()
  public logBookItems: OclLogbook[];
  @Input()
  public globalInstructionItems: OclGlobalInstructionGroup[];
  @Input()
  public utcDisplay: HolUTCDisplay;
  @Input()
  mostCriticalNextInfo: HolNextInfo;
  @Input() modeLinkIsActivate;
  @Input() decisionToDisplay; // soit la plus récente // soit la pus critique
  @Input() mostRecentLogBook;
  @Input() mostCriticalDecisionInfo;
  @Input() globalInstructionToDisplay; // la plus récente

  @Input() linkedIds;
  @Input() type;

  @Input() airportsCode;
  @Input() notifications: HolNotification[];

  public isItemsRequired: boolean;

  @Output()
  public updatedEvent: EventEmitter<OclEvent> = new EventEmitter();

  public isOpened: boolean;
  public lastDisplayTime: Date;
  i18nkey: string;
  public RolesService = RolesService;
  public originalScenarios: HolScenario[];
  public scenariosByGroup = {};
  public isOnSummary = false;
  public isOnSummaryBriefing = false;
  public groupSummaryTooltip: string;

  constructor(
    protected readonly commonService: CommonService,
    protected readonly localStorage: LocalStorageService,
    @Inject('CONSTANTS') protected CONSTANTS,
    @Inject('$state') public $state,
    @Inject(OCL_LOGBOOK_MODAL_TOKEN) protected logbookModal,
    @Inject(OCL_DECISIONS_MODAL_TOKEN) protected decisionsModal,
    @Inject(OCL_EVENT_MODAL_TOKEN) protected eventModal,
    protected flightsStatusService: OclFlightStatusService,
    protected scenariosStoreManager: OclScenariosStoreManager,
    protected scenarioService: OclScenarioService,
    protected notificationsService: NotificationsService,
    protected dialog: MatDialog,
    protected groupService: OclGroupService,
    public moduleConfig: ModuleConfigService,
    protected linksHelperService: OclLinksHelperService,
    protected occLinkedDisplayedItemsStoreManager: OclLinkedDisplayedItemsStoreManager,
    protected occDecisionsStoreManager: OclDecisionsStoreManager,
    protected occEventsStoreManager: OclEventsStoreManager,
  ) {
    super();

    this.lastDisplayTime = this.localStorage.getOccDashboardLastDisplayTime() || new Date();
  }

  public get isUtc(): boolean {
    return utcEval(this.utcDisplay, this.isReadOnly);
  }

  public ngOnInit(): void {
    if (this.type === 'EVENT') {
      this.i18nkey = 'EVENTS';
    }
    if (this.type === 'LOGBOOK') {
      this.i18nkey = 'LOGBOOK';
    }
    if (this.type === 'DECISION') {
      this.i18nkey = 'STATUS_DECISIONS';
    }
    if (this.type === 'GLOBAL_INSTRUCTION') {
      this.i18nkey = 'GLOBAL_INSTRUCTION';
    }
    this.isItemsRequired = this.moduleConfig.config.moduleName === 'ecl' ? false : true;

    this.isOnSummary = this.isOnSummaryFunc();
    this.isOnSummaryBriefing = this.isOnSummaryBriefingFunc();
    this.groupSummaryTooltip = this.itemsInGroupSummaryTooltip();
  }

  public onEditGroup(): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(OclGroupModalComponent, {
      data: {
        decisionItems: this.type === 'DECISION' ? this.decisionItems : null,
        logBookItems: this.type === 'LOGBOOK' ? this.logBookItems : null,
        events: this.type === 'EVENT' ? this.events : null,
        globalInstructionItems: this.type === 'GLOBAL_INSTRUCTION' ? this.globalInstructionItems : null,
        isReadOnly: this.isReadOnly,
        group: this.group,
        groupsFromApi: this.groupsFromApi,
        type: this.type,
        isItemsRequired: this.isItemsRequired,
        isSummaryActivated: this.isSummaryActivated,
      },
    });
    dialogRef.afterClosed().subscribe(obj => {
      if (obj) {
        this.updatedAfterClose();
      }
    });
  }

  public itemsInGroupSummaryTooltip(): string {
    if (this.type === 'LOGBOOK') {
      return 'ECL.DASHBOARD.LOGBOOK.TOOLTIP_GROUP_SUMMARY';
    } else if (this.type === 'DECISION') {
      return 'ECL.DASHBOARD.STATUS_DECISIONS.TOOLTIP_GROUP_SUMMARY';
    }

    return '';
  }

  updatedAfterClose(): void {
    if (this.type === 'EVENT') {
      this.groupService.fetchNewDataEventGroup();
    }
    if (this.type === 'LOGBOOK') {
      this.groupService.fetchNewDataLogBookGroup();
    }
    if (this.type === 'DECISION') {
      this.groupService.fetchNewDataDecisionGroup();
    }
    if (this.type === 'GLOBAL_INSTRUCTION') {
      this.groupService.fetchNewDataGlobalInstructionGroup();
    }
  }

  public openAddModal(event): void {
    event.stopPropagation();
    if (this.type === 'EVENT') {
      this.openAddModalEvent();
    }
    if (this.type === 'LOGBOOK') {
      this.addModalDecisionOrLogbook(event, this.logbookModal);
    }
    if (this.type === 'DECISION') {
      this.addModalDecisionOrLogbook(event, this.decisionsModal);
    }
  }

  addModalDecisionOrLogbook(event, modal): void {
    const dialogRef: MatDialogRef<any> = this.dialog.open(modal, {
      data: {
        decisionItems: this.type === 'DECISION' ? this.decisionItems : null,
        logBookItems: this.type === 'LOGBOOK' ? this.logBookItems : null,
        events: this.type === 'EVENT' ? this.events : null,
        globalInstructionItems: this.type === 'GLOBAL_INSTRUCTION' ? this.globalInstructionItems : null,
        isReadOnly: this.isReadOnly,
        isCreatedByGroup: true,
        group: this.group,
        groupsFromApi: this.groupsFromApi,
        type: this.type,
        isItemsRequired: this.isItemsRequired,
        isSummaryActivated: this.isSummaryActivated,
      },
    });
    dialogRef.afterClosed().subscribe((): void => {
      this.groupService.fetchNewDataLogBookGroup();
    });
  }

  public async openAddModalEvent(): Promise<void> {
    this.getScenarios();
    this.dialog
      .open(this.eventModal, {
        data: {
          notifications: await this.notificationsService.getAll(),
          fromLogbook: null,
          fromDecision: null,
          events: this.type === 'EVENT' ? this.events : null,
          isCreatedByGroup: true,
          type: this.type,
          group: this.group,
          isUtc: utcEval(this.utcDisplay, this.isReadOnly),
          scenarios: this.originalScenarios,
          airportsCode: this.airportsCode,
        },
      })
      .afterClosed()
      .subscribe(event => {
        if (event) {
          this.flightsStatusService.setNeedsUpdate(true);
        }
      });
  }

  getScenarios() {
    this.scenariosStoreManager.scenariosState
      .pipe(take(1))
      .toPromise()
      .then(scenarios => {
        this.originalScenarios = orderBy(scenarios, 'code');

        if (this.moduleConfig.config.scenariosByGroup) {
          this.scenariosByGroup = this.scenarioService.orderByGroupingCode(this.originalScenarios);
        }
      })
      .catch(error => console.log(error));
  }

  public onEventUpdated(event): void {
    if (event) {
      //  this.updateNextInfo();
      // const toUpdate = this.group.items.find(e => e.objectId === event.objectId);
      // if (toUpdate) {
      //   extend(toUpdate, event);
      //   this.updatedEvent.emit(toUpdate)
      // }
      const toUpdateG = this.events.find(e => e.objectId === event.objectId);
      if (toUpdateG) {
        extend(toUpdateG, event);
        this.updatedEvent.emit(toUpdateG);
      }
    }
    //  this.eventsService.fetchNewData();
    // this.groupService.fetchNewDataEventGroup();
  }

  /* public openEditAssetModal(assetType: EclAssetType, asset?: EclAsset) {
    this.dialog.open(EclAssetModalComponent, {
      disableClose: true,
      data: {
        assetType: this.selectedAssetType,
        crisis: this.selectedCrisis,
        asset: asset,
        isReadOnly: this.isReadOnly,
      },
    });
  }*/

  public isSeen(item: OclEvent): any {
    return item && item.createdAt ? this.commonService.isSeen(item, this.lastDisplayTime) : true;
  }

  // public updateNextInfo(): void {
  //   if (!this.group.objectId && this.group.items.length !== 0) {
  //     return;
  //   }
  //   this.mostCriticalNextInfo = this.group.getMostCriticalNextInfo();
  // }

  public isElapsed(date): boolean {
    return moment(date).isBefore(moment());
  }

  public isAlmostElapsed(date): boolean {
    return moment(date).diff(moment(), 'minutes') < this.CONSTANTS.TIME_BEFORE_WARNING;
  }

  public isOnSummaryBriefingFunc() {
    if (this.group && (this.group instanceof EclLogbookGroup || this.group instanceof EclDecisionGroup) && this.group.summary) {
      return !!this.group.summary.isDisplayInBriefing;
    }
    return false;
  }

  public isOnSummaryFunc() {
    if (this.group instanceof EclLogbookGroup || this.group instanceof EclDecisionGroup) {
      return !!this.group.summary;
    }
    return false;
  }
}
