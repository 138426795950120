'use strict';

angular.module('gestiondecriseApp').controller('CrewTeamCtrl', function ($scope, CrewTeamService, $mdDialog, $rootScope) {
  // Initialisation des variables du scope
  $scope.loading = false;
  $scope.functions = [];
  $scope.isReadOnly = false;

  // Fonction d'initialisation
  function init() {
    $scope.loading = true;
    // Vérifiez que CrewTeamService.getAllFunctionsWithUsers retourne une promesse
    CrewTeamService.getAllFunctionsWithUsers($scope.currentFilter)
      .then(function (functions) {
        $scope.functions = functions;
      })
      .finally(function () {
        $scope.loading = false;
      });
  }

  // Surveillance des changements dans $rootScope.crewEventFilter
  var unregisterWatchFilter = $rootScope.$watch(
    function () {
      return $rootScope.crewEventFilter;
    },
    function () {
      $scope.currentFilter = $rootScope.crewEventFilter;
      init();
    }
  );

  // Écoute des événements
  var unregisterSituationEvent = $scope.$on('crew-situation-updated', init);
  var unregisterPoolService = $scope.$on('crewPoolService-functions', function (e, functions) {
    $scope.functions = functions;
  });

  // Ouverture de la modale utilisateur
  $scope.openUserModal = function ($event, user, event) {
    $mdDialog
      .show({
        controller: 'CrewUserModalCtrl',
        templateUrl: 'views/crew/modals/user.modal.html',
        clickOutsideToClose: false,
        targetEvent: $event,
        locals: {
          user: user,
          event: event,
          situation: null,
          isReadOnly: $scope.isReadOnly,
          isArchivesOpened: false
        }
      })
      .then(function (retValue) {
        console.log('retValue', retValue);
        if (retValue) {
          init();
        }
      });
  };

  // Vérifie si l'utilisateur a une situation en attente
  $scope.hasWaitingSituation = function (user) {
    return (
      _.find(user.events, function (event) {
        return event.lastSituation && event.lastSituation.status === 'WAITING';
      }) !== undefined
    );
  };

  // Ouverture de la modale d'ajout d'utilisateur
  $scope.openAddUserModal = function ($event, func) {
    $mdDialog
      .show({
        controller: 'CrewAddUserModalCtrl',
        templateUrl: 'views/crew/modals/addUser.modal.html',
        clickOutsideToClose: false,
        targetEvent: $event,
        locals: {
          func: func
        }
      })
      .then(function (retValue) {
        if (retValue) {
          init();
        }
      });
  };

  // Désinscription des écouteurs d'événements à la destruction du scope
  $scope.$on('$destroy', function () {
    unregisterWatchFilter();
    unregisterSituationEvent();
    //unregisterPoolService();
  });
});
