<div class="wrapper-holster-stopover">
  <img [ngClass]="{ land: !isDeparture }" class="icon" src="/assets/images/goc_depart.svg" />

  <div appHorizontalScroll class="holster">
    <button #btnPrev class="button button-prev" mat-icon-button>
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>

    <div #scrollWrapper class="scrolling-wrapper">
      <div (click)="openOtherFlightPage($event, f)" *ngFor="let f of flights; trackBy: trackByFunction" class="hol-clickable flight-item">
        {{ f.flightNumber }}
      </div>
    </div>

    <button #btnNext class="button button-next" mat-icon-button>
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>
