import { Injectable } from '@angular/core';
import { OclDecision } from '../../models/ocl-decision.model';
import { OclLogbook } from '../../models/ocl-logbook.model';
import { OclEvent } from '../../models/ocl-event.model';
import { union } from 'lodash';
import { OclLinkedItemsStoreManager } from '../../store/linked-items/ocl-linked-items-store-manager.service';
import { RequestService } from '../../../common/services/request.service';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';

@Injectable({
  providedIn: 'root',
})
export abstract class OclLinksService {
  private decisionIdsCache: string[] = [];
  private logBookIdsCache: string[] = [];
  private eventsIdsCache: string[] = [];
  // tslint:disable:variable-name
  protected abstract ParseOclLogBook;
  protected abstract ParseOclEvents;
  protected abstract ParseOclDecisions;
  protected abstract ParseOclLinkLogBookDecision;
  protected abstract ParseOclLinkDecisionEvent;
  protected abstract ParseOclLinkLogBookEvent;

  // tslint:enabled
  protected constructor(
    protected requestService: RequestService,
    protected occLinkedItemsStoreManager: OclLinkedItemsStoreManager,
    public moduleConfig: ModuleConfigService,
  ) {}

  public getLinkLogBookForDecisionById(id: string): Promise<any[]> {
    const queryLinkLogBookDecision = new Parse.Query(this.ParseOclLinkLogBookDecision);
    queryLinkLogBookDecision.equalTo('decision', new this.ParseOclDecisions({ id }));
    queryLinkLogBookDecision.include('logBook');
    return new Promise((resolve, reject) => {
      this.requestService.performFindQuery(
        queryLinkLogBookDecision,
        res => {
          if (!res) {
            resolve([]);
          } else {
            const bufferData = res
              .filter(el => !!(el.get('logBook') && el.get('logBook').get('createdAt')))
              .map(el => {
                return new OclLogbook(el.get('logBook'));
              });
            resolve(bufferData);
          }
        },
        reject,
      );
    });
  }

  public getLinkDecisionForLogBookById(id: string): Promise<any[]> {
    const queryLinkLogBookDecision = new Parse.Query(this.ParseOclLinkLogBookDecision);
    queryLinkLogBookDecision.equalTo('logBook', new this.ParseOclLogBook({ id }));
    queryLinkLogBookDecision.include('decision');
    return new Promise((resolve, reject) => {
      this.requestService.performFindQuery(
        queryLinkLogBookDecision,
        res => {
          if (!res) {
            resolve([]);
          } else {
            const bufferData = res
              .filter(el => !!(el.get('decision') && el.get('decision').get('createdAt')))
              .map(el => {
                return new OclDecision(el.get('decision'));
              });
            resolve(bufferData);
          }
        },
        reject,
      );
    });
  }

  public getLinkDecisionEventById(id: string): Promise<any> {
    if (this.moduleConfig.config.moduleName === 'ecl') {
      console.log('getLinkDecisionEventById');
      return new Promise((resolve, reject) => {
        return resolve([]);
      });
    }
    const queryLinkDecisionEvent = new Parse.Query(this.ParseOclLinkDecisionEvent);
    queryLinkDecisionEvent.equalTo('decision', new this.ParseOclDecisions({ id }));
    queryLinkDecisionEvent.include('event');
    return new Promise((resolve, reject) => {
      this.requestService.performFindQuery(
        queryLinkDecisionEvent,
        res => {
          if (!res) {
            resolve([]);
          } else {
            const bufferData = res
              .filter(el => !!(el.get('event') && el.get('event').get('createdAt')))
              .map(el => {
                return new OclEvent(el.get('event'));
              });
            resolve(bufferData);
          }
        },
        reject,
      );
    });
  }

  public getLinkDecisionEventByArrayId(decisionIds: string[]): Promise<any> {
    if (this.moduleConfig.config.moduleName === 'ecl') {
      console.log('getLinkDecisionEventByArrayId');
      return new Promise((resolve, reject) => {
        return resolve([]);
      });
    }
    const queryLinkDecisionEvent = new Parse.Query(this.ParseOclLinkDecisionEvent);
    const parseDecisionsObjectsToCheck = decisionIds.map(id => {
      return new this.ParseOclDecisions({ id });
    });
    queryLinkDecisionEvent.containedIn('decision', parseDecisionsObjectsToCheck);
    queryLinkDecisionEvent.include('event');
    return new Promise((resolve, reject) => {
      this.requestService.performFindQuery(
        queryLinkDecisionEvent,
        res => {
          if (!res) {
            resolve([]);
          } else {
            const bufferData = res
              .filter(el => !!(el.get('event') && el.get('event').get('createdAt')))
              .map(el => {
                return new OclEvent(el.get('event'));
              });
            resolve(bufferData);
          }
        },
        reject,
      );
    });
  }

  public getLinkEventDecisionById(id: string): Promise<any> {
    if (this.moduleConfig.config.moduleName === 'ecl') {
      return new Promise((resolve, reject) => {
        return [];
      });
    }
    const queryLinkDecisionEvent = new Parse.Query(this.ParseOclLinkDecisionEvent);
    queryLinkDecisionEvent.equalTo('event', new this.ParseOclEvents({ id }));
    queryLinkDecisionEvent.include('decision');
    return new Promise((resolve, reject) => {
      this.requestService.performFindQuery(
        queryLinkDecisionEvent,
        res => {
          if (!res) {
            resolve([]);
          } else {
            const bufferData = res
              .filter(el => !!(el.get('decision') && el.get('decision').get('createdAt')))
              .map(el => {
                return new OclDecision(el.get('decision'));
              });
            resolve(bufferData);
          }
        },
        error => {
          reject(error);
        },
      );
    });
  }

  public getLinkLogBookEventById(id: string): Promise<any> {
    if (this.moduleConfig.config.moduleName === 'ecl') {
      return new Promise((resolve, reject) => {
        return [];
      });
    }
    const queryLinkLogBookEvent = new Parse.Query(this.ParseOclLinkLogBookEvent);
    queryLinkLogBookEvent.equalTo('logBook', new this.ParseOclLogBook({ id }));
    queryLinkLogBookEvent.include('event');
    return new Promise((resolve, reject) => {
      this.requestService.performFindQuery(
        queryLinkLogBookEvent,
        res => {
          if (!res) {
            resolve([]);
          } else {
            const bufferData = res
              .filter(el => !!(el.get('event') && el.get('event').get('createdAt')))
              .map(el => {
                return new OclEvent(el.get('event'));
              });
            resolve(bufferData);
          }
        },
        reject,
      );
    });
  }

  public getLinkEventLogBookById(id: string): Promise<any> {
    if (this.moduleConfig.config.moduleName === 'ecl') {
      return new Promise((resolve, reject) => {
        return [];
      });
    }
    const queryLinkLogBookEvent = new Parse.Query(this.ParseOclLinkLogBookEvent);
    queryLinkLogBookEvent.equalTo('event', new this.ParseOclEvents({ id }));
    queryLinkLogBookEvent.include('logBook');
    return new Promise((resolve, reject) => {
      this.requestService.performFindQuery(
        queryLinkLogBookEvent,
        res => {
          if (!res) {
            resolve([]);
          } else {
            const bufferData = res
              .filter(el => !!(el.get('logBook') && el.get('logBook').get('createdAt')))
              .map(el => {
                return new OclLogbook(el.get('logBook'));
              });
            resolve(bufferData);
          }
        },
        error => {
          reject(error);
          console.log(error);
        },
      );
    });
  }

  public checkLinks(
    decisionIds: string[] = [],
    logBookIds: string[] = [],
    eventsIds: string[] = [],
    isUpdate: boolean = false,
  ): Promise<any> {
    const linksId = {};
    if (!isUpdate) {
      this.decisionIdsCache = decisionIds;
      this.logBookIdsCache = logBookIds;
      this.eventsIdsCache = eventsIds;
    } else {
      this.decisionIdsCache = union(this.decisionIdsCache, decisionIds);
      this.logBookIdsCache = union(this.logBookIdsCache, logBookIds);
      this.eventsIdsCache = union(this.eventsIdsCache, eventsIds);
    }

    const queryLinkDecisionLogBook = new Parse.Query(this.ParseOclLinkLogBookDecision);
    const queryLinkDecisionEvent = new Parse.Query(this.ParseOclLinkDecisionEvent);
    const queryLinkLogBookEvent = new Parse.Query(this.ParseOclLinkLogBookEvent);

    const parseDecisionsObjectsToCheck = this.decisionIdsCache.map(id => {
      return new this.ParseOclDecisions({ id });
    });
    const parseLogBooksObjectsToCheck = this.logBookIdsCache.map(id => {
      return new this.ParseOclLogBook({ id });
    });
    const parseEventsObjectsToCheck = this.eventsIdsCache.map(id => {
      return new this.ParseOclEvents({ id });
    });

    queryLinkDecisionLogBook.containedIn('decision', parseDecisionsObjectsToCheck);
    queryLinkDecisionLogBook.containedIn('logBook', parseLogBooksObjectsToCheck);

    if (this.moduleConfig.config.moduleName !== 'ecl') {
      queryLinkDecisionEvent.containedIn('decision', parseDecisionsObjectsToCheck);
      queryLinkDecisionEvent.containedIn('event', parseEventsObjectsToCheck);

      queryLinkLogBookEvent.containedIn('logBook', parseLogBooksObjectsToCheck);
      queryLinkLogBookEvent.containedIn('event', parseEventsObjectsToCheck);
    }
    return new Promise((resolve, reject) => {
      if (!this.decisionIdsCache.length && !this.eventsIdsCache.length && !this.logBookIdsCache.length) {
        resolve({});
      }

      const linkDecisionLogBook = new Promise<Parse.Object[]>((res, rej) => {
        this.requestService.performFindQuery(queryLinkDecisionLogBook, res, rej);
      });

      const linkDecisionEvent = new Promise<Parse.Object[]>((res, rej) => {
        if (this.moduleConfig.config.moduleName !== 'ecl') {
          this.requestService.performFindQuery(queryLinkDecisionEvent, res, rej);
        } else {
          return [];
        }
      });

      const linkLogBookEvent = new Promise<Parse.Object[]>((res, rej) => {
        if (this.moduleConfig.config.moduleName !== 'ecl') {
          this.requestService.performFindQuery(queryLinkLogBookEvent, res, rej);
        } else {
          return [];
        }
      });

      Promise.all([linkDecisionLogBook, linkDecisionEvent, linkLogBookEvent]).then(data => {
        const bufferArray = [];
        data[0].forEach(el => {
          bufferArray.push(el.get('decision').id);
          bufferArray.push(el.get('logBook').id);
        });
        if (this.moduleConfig.config.moduleName !== 'ecl') {
          data[1].forEach(el => {
            bufferArray.push(el.get('decision').id);
            bufferArray.push(el.get('event').id);
          });
          data[2].forEach(el => {
            bufferArray.push(el.get('logBook').id);
            bufferArray.push(el.get('event').id);
          });
        }

        bufferArray.forEach(el => (linksId[el] = 'SHOW_BUTTON'));

        const logBookIdsNotFound = this.logBookIdsCache.filter(id => !linksId[id]);
        const decisionIdsNotFound = this.decisionIdsCache.filter(id => !linksId[id]);

        if (logBookIdsNotFound.length || decisionIdsNotFound.length) {
          const promiseArray = [];

          if (logBookIdsNotFound.length) {
            promiseArray.push(this.checkLogBookArchiveLinks(logBookIdsNotFound));
          }

          if (decisionIdsNotFound.length) {
            promiseArray.push(this.checkDecisicionArchiveLinks(decisionIdsNotFound));
          }

          Promise.all(promiseArray).then(linksIdArchived => {
            resolve({ ...linksId, ...linksIdArchived[1], ...linksIdArchived[0] });
          });
        } else {
          resolve(linksId);
        }
      }, reject);
    });
  }

  public checkLogBookArchiveLinks(logBookIdsNotFound: string[]): Promise<any> {
    const linksIdArchived = {};
    const promiseArray = [];
    const queryLinkDecisionLogBookArchiveLink = new Parse.Query(this.ParseOclLinkLogBookDecision);
    const queryLinkLogBookEventArchiveLink = new Parse.Query(this.ParseOclLinkLogBookEvent);

    const parseLogBookObjectsArchiveLink = logBookIdsNotFound.map(id => {
      return new this.ParseOclLogBook({ id });
    });
    queryLinkDecisionLogBookArchiveLink.containedIn('logBook', parseLogBookObjectsArchiveLink);
    queryLinkLogBookEventArchiveLink.containedIn('logBook', parseLogBookObjectsArchiveLink);

    promiseArray.push(
      new Promise<Parse.Object[]>((res, rej) => {
        this.requestService.performFindQuery(queryLinkDecisionLogBookArchiveLink, res, rej);
      }),
    );
    promiseArray.push(
      new Promise<Parse.Object[]>((res, rej) => {
        this.requestService.performFindQuery(queryLinkLogBookEventArchiveLink, res, rej);
      }),
    );

    return new Promise((resolve, reject) => {
      Promise.all(promiseArray).then(resps => {
        [...resps[0], ...resps[1]].forEach(el => {
          linksIdArchived[el.get('logBook').id] = 'DISABLED_BUTTON';
        });
        resolve(linksIdArchived);
      });
    });
  }

  public checkDecisicionArchiveLinks(decisionIdsNotFound: string[]): Promise<any> {
    const linksIdArchived = {};
    const promiseArray = [];
    const queryLinkDecisionLogBookArchiveLink = new Parse.Query(this.ParseOclLinkLogBookDecision);
    const queryLinkDecisionEventArchiveLink = new Parse.Query(this.ParseOclLinkDecisionEvent);

    const parseDecisionObjectsArchiveLink = decisionIdsNotFound.map(id => {
      return new this.ParseOclDecisions({ id });
    });
    queryLinkDecisionLogBookArchiveLink.containedIn('decision', parseDecisionObjectsArchiveLink);
    queryLinkDecisionEventArchiveLink.containedIn('decision', parseDecisionObjectsArchiveLink);

    promiseArray.push(
      new Promise<Parse.Object[]>((res, rej) => {
        this.requestService.performFindQuery(queryLinkDecisionLogBookArchiveLink, res, rej);
      }),
    );
    promiseArray.push(
      new Promise<Parse.Object[]>((res, rej) => {
        this.requestService.performFindQuery(queryLinkDecisionEventArchiveLink, res, rej);
      }),
    );

    return new Promise((resolve, reject) => {
      Promise.all(promiseArray).then(resps => {
        [...resps[0], ...resps[1]].forEach(el => {
          linksIdArchived[el.get('decision').id] = 'DISABLED_BUTTON';
        });
        resolve(linksIdArchived);
      });
    });
  }

  public saveLinkLogBookDecision(objectId: string, type: string, dataToSave: any[]): Promise<any> {
    const parseObjectsToSave = dataToSave.map(data => {
      const parseObject = new this.ParseOclLinkLogBookDecision();
      if (type === 'logBook') {
        parseObject.set('logBook', new this.ParseOclLogBook({ id: objectId }));
        parseObject.set('decision', new this.ParseOclDecisions({ id: data }));
      } else {
        parseObject.set('decision', new this.ParseOclDecisions({ id: objectId }));
        parseObject.set('logBook', new this.ParseOclLogBook({ id: data }));
      }
      return parseObject;
    });

    return new Promise((resolve, reject) => {
      if (!dataToSave.length) {
        resolve(true);
        return;
      }
      this.requestService.performSaveAllQuery(
        parseObjectsToSave,
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        },
      );
    });
  }

  public saveLinkDecisionEvent(objectId: string, dataToSave: any[]): Promise<any> {
    if (this.moduleConfig.config.moduleName === 'ecl') {
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    }

    const parseObjectsToSave = dataToSave.map(data => {
      const parseObject = new this.ParseOclLinkDecisionEvent();
      parseObject.set('decision', new this.ParseOclDecisions({ id: objectId }));
      parseObject.set('event', new this.ParseOclEvents({ id: data }));
      return parseObject;
    });

    return new Promise((resolve, reject) => {
      if (!dataToSave.length) {
        resolve(true);
        return;
      } else {
        this.requestService.performSaveAllQuery(
          parseObjectsToSave,
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          },
        );
      }
    });
  }

  public saveLinkLogBookEvent(objectId: string, dataToSave: any[]): Promise<any> {
    if (this.moduleConfig.config.moduleName === 'ecl') {
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    }
    const parseObjectsToSave = dataToSave.map(data => {
      const parseObject = new this.ParseOclLinkLogBookEvent();
      parseObject.set('logBook', new this.ParseOclLogBook({ id: objectId }));
      parseObject.set('event', new this.ParseOclEvents({ id: data }));
      return parseObject;
    });

    return new Promise((resolve, reject) => {
      if (!dataToSave.length) {
        resolve(true);
        return;
      } else {
        this.requestService.performSaveAllQuery(
          parseObjectsToSave,
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          },
        );
      }
    });
  }

  public deleteLinkLogBookForDecisionById(objectId, dataToDelete): Promise<any> {
    if (!dataToDelete.length) {
      return;
    }
    const queryLinkLogBookDecision = new Parse.Query(this.ParseOclLinkLogBookDecision);
    queryLinkLogBookDecision.equalTo('decision', new this.ParseOclDecisions({ id: objectId }));

    const parseLogBookObjectsToDelete = dataToDelete.map(data => {
      return new this.ParseOclLogBook({ id: data });
    });
    return new Promise((resolve, reject) => {
      if (!dataToDelete.length) {
        resolve(true);
      }
      queryLinkLogBookDecision.containedIn('logBook', parseLogBookObjectsToDelete);
      this.requestService.performFindQuery(queryLinkLogBookDecision, res => {
        this.requestService.performDestroyAllQuery(
          res,
          resp => {
            resolve(resp);
          },
          error => {
            console.log(error);
            reject(error);
          },
        );
      });
    });
  }

  public async deleteAllLinkFromLogbook(objectId: string): Promise<any> {
    const parseLinkLogbookEvent = new Parse.Query(this.ParseOclLinkLogBookEvent);
    parseLinkLogbookEvent.equalTo('logBook', new this.ParseOclLogBook({ id: objectId }));
    const parseOclLinkLogBookDecision = new Parse.Query(this.ParseOclLinkLogBookDecision);
    parseOclLinkLogBookDecision.equalTo('logBook', new this.ParseOclLogBook({ id: objectId }));

    await this.requestService.performFindQuery(parseLinkLogbookEvent, res => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.requestService.performDestroyAllQuery(res, () => {});
    });

    await this.requestService.performFindQuery(parseOclLinkLogBookDecision, res => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.requestService.performDestroyAllQuery(res, () => {});
    });
  }

  public async deleteAllLinkFromDecision(objectId: string): Promise<any> {
    const parseLinkDecisionEvent = new Parse.Query(this.ParseOclLinkDecisionEvent);
    parseLinkDecisionEvent.equalTo('decision', new this.ParseOclDecisions({ id: objectId }));
    const parseOclLinkLogBookDecision = new Parse.Query(this.ParseOclLinkLogBookDecision);
    parseOclLinkLogBookDecision.equalTo('decision', new this.ParseOclDecisions({ id: objectId }));

    await this.requestService.performFindQuery(parseLinkDecisionEvent, res => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.requestService.performDestroyAllQuery(res, () => {});
    });

    await this.requestService.performFindQuery(parseOclLinkLogBookDecision, res => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.requestService.performDestroyAllQuery(res, () => {});
    });
  }

  public deleteLinkDecisionForLogBookById(objectId, dataToDelete): Promise<any> {
    const queryLinkLogBookDecision = new Parse.Query(this.ParseOclLinkLogBookDecision);
    queryLinkLogBookDecision.equalTo('logBook', new this.ParseOclLogBook({ id: objectId }));

    const parseDecisionObjectsToDelete = dataToDelete.map(data => {
      return new this.ParseOclDecisions({ id: data });
    });
    return new Promise((resolve, reject) => {
      if (!dataToDelete.length) {
        resolve(true);
      }
      queryLinkLogBookDecision.containedIn('decision', parseDecisionObjectsToDelete);
      this.requestService.performFindQuery(queryLinkLogBookDecision, res => {
        this.requestService.performDestroyAllQuery(
          res,
          resp => {
            resolve(resp);
          },
          error => {
            console.log(error);
            reject(error);
          },
        );
      });
    });
  }

  public deleteLinkDecisionEventById(objectId, dataToDelete): Promise<any> {
    if (!dataToDelete.length) {
      return;
    }
    const queryLinkDecisionEvent = new Parse.Query(this.ParseOclLinkDecisionEvent);
    queryLinkDecisionEvent.equalTo('decision', new this.ParseOclDecisions({ id: objectId }));

    const parseEventObjectsToDelete = dataToDelete.map(data => {
      return new this.ParseOclEvents({ id: data });
    });
    return new Promise((resolve, reject) => {
      if (!dataToDelete.length) {
        resolve(true);
      }
      queryLinkDecisionEvent.containedIn('event', parseEventObjectsToDelete);
      this.requestService.performFindQuery(queryLinkDecisionEvent, res => {
        this.requestService.performDestroyAllQuery(
          res,
          resp => {
            resolve(resp);
          },
          error => {
            console.log(error);
            reject(error);
          },
        );
      });
    });
  }

  public deleteLinkLogBookEventById(objectId, dataToDelete): Promise<any> {
    if (!dataToDelete.length) {
      return;
    }
    const queryLinkLogBookEvent = new Parse.Query(this.ParseOclLinkLogBookEvent);
    queryLinkLogBookEvent.equalTo('logBook', new this.ParseOclLogBook({ id: objectId }));

    const parseEventObjectsToDelete = dataToDelete.map(data => {
      return new this.ParseOclEvents({ id: data });
    });
    return new Promise((resolve, reject) => {
      if (!dataToDelete.length) {
        resolve(true);
      }
      queryLinkLogBookEvent.containedIn('event', parseEventObjectsToDelete);
      this.requestService.performFindQuery(queryLinkLogBookEvent, res => {
        this.requestService.performDestroyAllQuery(
          res,
          resp => {
            resolve(resp);
          },
          error => {
            console.log(error);
            reject(error);
          },
        );
      });
    });
  }

  public fetchNewData() {
    this.checkLinks([], [], [], true).then(linksId =>
      this.occLinkedItemsStoreManager.updateLinkedItemsFromPooling(linksId, this.moduleConfig.config.moduleName),
    );
  }
}
