import { EclCrisisState } from '../../ecl.model';
import { EclCrisisActions, EclCrisisActionTypes, UpdateManyEclCrisis } from './crisis.action';
import { EclCrisis, EclCrisisType } from '../../models/ecl-crisis';
import { EclSummary } from '../../models/ecl-summary';
import { EclUserFunction } from '../../models/ecl-function';

const crisisInitialState: Readonly<EclCrisisState> = {
  crisis: [],
  crisisTypes: [],
  selectedCrisis: undefined,
  announcements: [],
  summaries: [],
  userFunction: [],
};

export function eclCrisisReducer(state: EclCrisisState = crisisInitialState, action: EclCrisisActions): EclCrisisState {
  let crisis: EclCrisis;
  let crisisIndex: number;
  switch (action.type) {
    case EclCrisisActionTypes.INIT_ECL_CRISIS:
      // eslint-disable-next-line no-case-declarations
      const { crisisList, crisisTypeList, allCurrentUserFunction, crisisIdSaved } = action.payload;
      return {
        crisis: combineCrisisAndTypes(crisisList, crisisTypeList),
        crisisTypes: crisisTypeList,
        selectedCrisis: state.selectedCrisis || currentOpenedCrisis(crisisList, allCurrentUserFunction, crisisIdSaved),
        announcements: [],
        summaries: [],
        userFunction: [],
      };
    case EclCrisisActionTypes.SELECT_ECL_CURRENT_CRISIS:
      return {
        ...state,
        selectedCrisis: action.payload,
      };
    case EclCrisisActionTypes.INIT_ECL_CRISIS_LIST_FROM_POLLING:
      return {
        ...state,
        crisis: action.payload,
      };
    case EclCrisisActionTypes.CREATE_ECL_CRISIS:
      return {
        ...state,
        crisis: combineCrisisAndTypes([action.payload, ...state.crisis], state.crisisTypes),
        selectedCrisis: action.payload,
      };
    case EclCrisisActionTypes.CLOSE_ECL_CRISIS:
      crisisIndex = state.crisis.findIndex(crisis => crisis.objectId === action.payload.objectId);
      crisis = action.payload;
      if (crisisIndex) {
        state.crisis[crisisIndex] = crisis;
      }
      return {
        ...state,
        selectedCrisis: crisis,
      };
    case EclCrisisActionTypes.CREATE_ONE_ECL_CRISIS_ANNOUNCEMENT:
      // tslint:disable-next-line: max-line-length
      if (action.payload.objectId && state.announcements.findIndex(el => el.objectId === action.payload.objectId) === -1) {
        //state.announcements.push(action.payload);
        return {
          ...state,
          announcements: [action.payload, ...state.announcements],
        };
      } else {
        return state;
        //return cloneDeep(state);
      }
    case EclCrisisActionTypes.UPDATE_ONE_ECL_CRISIS_ANNOUNCEMENT:
      return {
        ...state,
        announcements: [
          ...state.announcements.map(el => {
            if (el.objectId === action.payload.objectId) {
              el = { ...el, ...action.payload };
            }
            return el;
          }),
        ],
      };

    case EclCrisisActionTypes.INIT_ECL_CRISIS_ANNOUNCEMENT_LIST:
      return {
        ...state,
        announcements: action.payload,
      };
    case EclCrisisActionTypes.INIT_ECL_CRISIS_ANNOUNCEMENT_LIST_FROM_POLLING:
      return {
        ...state,
        announcements: action.payload,
      };
    case EclCrisisActionTypes.CREATE_ONE_ECL_CRISIS_SUMMARY:
      // tslint:disable-next-line: max-line-length
      if (action.payload.objectId && state.summaries.findIndex(el => el.objectId === action.payload.objectId) === -1) {
        //state.announcements.push(action.payload);
        return {
          ...state,
          summaries: [...state.summaries, action.payload],
        };
      } else {
        return state;
        //return cloneDeep(state);
      }

    case EclCrisisActionTypes.UPDATE_ONE_ECL_CRISIS_SUMMARY:
      const summaries = [...state.summaries];

      const i = summaries.findIndex(s => s.objectId === action.payload.objectId);
      if (i !== -1) {
        summaries[i] = action.payload;
      } else {
        summaries.push(action.payload);
      }
      return {
        ...state,
        summaries: summaries,
      };

    case EclCrisisActionTypes.UPDATE_MANY_ECL_CRISIS_SUMMARY:
      const actualSummaries = [...state.summaries];
      action.payload.forEach(summary => {
        const index = actualSummaries.findIndex(s => s.objectId === summary.objectId);
        if (index !== -1) {
          actualSummaries[index] = summary;
        } else {
          actualSummaries.push(summary);
        }
      });
      return {
        ...state,
        summaries: actualSummaries,
      };

    case EclCrisisActionTypes.DELETE_ONE_ECL_CRISIS_SUMMARY:
      return {
        ...state,
        summaries: [
          ...state.summaries.filter((el: EclSummary) => {
            return el.objectId !== action.payload.objectId;
          }),
        ],
      };
    case EclCrisisActionTypes.INIT_ECL_CRISIS_SUMMARY_LIST:
      return {
        ...state,
        summaries: action.payload,
      };
    case EclCrisisActionTypes.INIT_ECL_CRISIS_SUMMARY_LIST_FROM_POLLING:
      return {
        ...state,
        summaries: action.payload,
      };
    case EclCrisisActionTypes.INIT_ECL_CRISIS_USERFUNCTION_LIST:
      return {
        ...state,
        userFunction: action.payload,
      };

    case EclCrisisActionTypes.UPDATE_ONE_ECL_CRISIS:
      return {
        ...state,
        crisis: [
          ...state.crisis.map((el: EclCrisis) => {
            if (el.objectId === action.payload.objectId) {
              el = { ...el, ...action.payload };
            }
            return el;
          }),
        ],
        selectedCrisis: action.payload,
      };

    case EclCrisisActionTypes.UPDATE_MANY_ECL_CRISIS:
      const actualCrisis = state.crisis;

      action.payload.forEach((crisis: EclCrisis) => {
        const index = actualCrisis.findIndex(c => c.objectId === crisis.objectId);
        if (index !== -1) {
          actualCrisis[index] = crisis;
        } else {
          actualCrisis.push(crisis);
        }
      });
      const index = actualCrisis.findIndex(c => c.objectId === state.selectedCrisis.objectId);
      return {
        ...state,
        crisis: actualCrisis,
        selectedCrisis: index !== -1 ? actualCrisis[index] : state.selectedCrisis,
      };
    default:
      return state;
  }
}

function combineCrisisAndTypes(crisisList: EclCrisis[], types: EclCrisisType[]): EclCrisis[] {
  return crisisList.map(crisis => {
    crisis.type = types.find(t => t.crisisTypeId === crisis.crisisTypeId);
    return crisis;
  });
}

function currentOpenedCrisis(crisisList: EclCrisis[], currentUserFunction: EclUserFunction[], crisisIdSaved: string): EclCrisis {
  if (crisisIdSaved) {
    const crisisFinded = crisisList.find(c => c.objectId == crisisIdSaved);
    if (crisisFinded) {
      return crisisFinded;
    }
  }
  return (
    crisisList.find(c => (c.isInPreparation || c.inProgress) && currentUserFunction.some(ucf => ucf.crisis.objectId === c.objectId)) ||
    crisisList[0]
  );
}
