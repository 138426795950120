<!-- <app-loading *ngIf="loading" class="full-loader"></app-loading> -->
<div class="hol-dialog-wrapper crisis-task-modal hol-modal">
  <mat-toolbar>
    <div mat-dialog-title>
      <span>
        {{ moduleConfig.config.translateKey + '.MODALS.TASK.ADD_TASK' | translate }}
      </span>
    </div>
    <app-acl-selector
      class="acl-full-width"
      [limitTo]="crisis?.acl"
      [moduleName]="moduleConfig.config.moduleName"
      [object]="crisisTask"
      [formGroup]="form"
      [isCompact]="true"
      [readOnly]="false"
      [startAllSelected]="true"
      alwaysOneSelected="true"
    ></app-acl-selector>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <form [formGroup]="form" class="content-grid cols-container">
      <div class="crisis-task-modal__content">
        <div class="section">
          <app-section-with-title [icon]="'diversity_3'" [titleKey]="moduleConfig.config.translateKey + '.MODALS.TASK.FUNCTION'">
            <mat-form-field *ngIf="functionsUserForCrisis.length && !data.isInErdTeam">
              <mat-select
                (ngModelChange)="selectTaskCode($event)"
                [ngModelOptions]="{ standalone: true }"
                [ngModel]="functionsUserForCrisisSelected"
                matNativeControl
              >
                <mat-option *ngFor="let fUser of functionsUserForCrisis" [value]="fUser.functionId">
                  {{ fUser.shortTitle }} - {{ fUser.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="allUserFunctions.length && data.isInErdTeam">
              <mat-select
                (ngModelChange)="selectTaskCode($event)"
                [ngModelOptions]="{ standalone: true }"
                [ngModel]="functionsUserForCrisisSelected"
                matNativeControl
              >
                <mat-option *ngFor="let fUser of allUserFunctions" [value]="fUser.functionId">
                  {{ fUser.shortTitle }} - {{ fUser.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </app-section-with-title>
        </div>
        <!--
        <div class="task-modal-row task-modal-row--functions">
          <span>
            {{ moduleConfig.config.translateKey + '.MODALS.TASK.FUNCTION' | translate }}
          </span>
        </div>
        -->
        <app-section-with-title [icon]="'edit'" [titleKey]="moduleConfig.config.translateKey + '.MODALS.TASK.TITLE'">
          <input
            maxlength="140"
            [placeholder]="titlePlaceHolder"
            type="text"
            [(ngModel)]="crisisTask.taskTitle"
            [ngModelOptions]="{ standalone: true }"
            (focusout)="titlePlaceHolder = defaultTitlePlaceHolder"
            (focusin)="titlePlaceHolder = ''"
          />
        </app-section-with-title>
        <br />
        <app-section-with-title
          [icon]="'rule'"
          [titleKey]="(moduleConfig.config.translateKey + '.MODALS.TASK.OUTPUT_TITLE' | translate) + ' *'"
        >
          <input
            maxlength="70"
            [placeholder]="outputTitlePlaceHolder"
            type="text"
            [(ngModel)]="crisisTask.outputTitle"
            [ngModelOptions]="{ standalone: true }"
            (focusout)="outputTitlePlaceHolder = defaultOutputTitlePlaceHolder"
            (focusin)="outputTitlePlaceHolder = ''"
          />
        </app-section-with-title>
        <br />
        <app-section-with-title
          [icon]="'badge'"
          [titleKey]="moduleConfig.config.translateKey + '.MODALS.TASK.OUTPUT_DATA_LABEL' | translate"
        >
          <input
            maxlength="70"
            [placeholder]="outputDataLabelPlaceHolder"
            type="text"
            [(ngModel)]="crisisTask.outputDataLabel"
            (ngModelChange)="outputDataLabelChange($event)"
            [ngModelOptions]="{ standalone: true }"
            (focusout)="outputDataLabelPlaceHolder = defaultOutputDataLabelPlaceHolder"
            (focusin)="outputDataLabelPlaceHolder = ''"
          />
        </app-section-with-title>
        <br />
        <div class="task-modal-column">
          <app-section-with-title [icon]="'task'" [titleKey]="moduleConfig.config.translateKey + '.MODALS.TASK.DESCRIPTION'">
            <form name="markdownForm">
              <md-editor
                #mdEditor
                [(ngModel)]="crisisTask.taskDescription"
                [ngModelOptions]="{ standalone: true }"
                [options]="options"
                mode="editor"
                name="Content"
              >
              </md-editor>
            </form>
          </app-section-with-title>
        </div>
        <div *ngIf="!data?.isCreationMode" class="task-modal-row task-modal-row-additional-info">
          <hr />
          <ng-container *ngIf="crisisTask.outputDataLabel && crisisTask.outputDataLabel.trim().length > 0">
            <app-erp-task-visibility
              [disabledVisiblitySelector]="false"
              [isInErdTeam]="data?.isInErdTeam"
              [frozenByErd]="false"
              [showFrozenByErd]="data?.isInErdTeam"
              [disableFrozenByErd]="!data?.isInErdTeam"
              [isCreationMode]="data?.isCreationMode"
              (updateCurrentVisibility)="changeVisibility($event)"
              (updateFrozenByErd)="changeFrozenByErd($event)"
            >
            </app-erp-task-visibility>
          </ng-container>
        </div>

        <!--
        <div class="task-modal-row task-modal-row-additional-info">
          <div class="task-modal-column task-modal-column-notification">
            <label>
              {{ 'DASHBOARD.MODAL.NOTIFICATION_LABEL' | translate }}
            </label>
            <app-notification-table
              [notifications]="[]"
              [notifyFunction]="notifyFunction"
              (saveNotifyFunction)="saveNotifyFunction($event)"
            ></app-notification-table>
          </div>
          <div class="task-modal-column task-modal-column-next-info">
            <div class="next-info">
              <label>
                {{ 'DASHBOARD.MODAL.NEXT_INFO' | translate }}
              </label>
              <app-hol-next-info
                [nextInfo]="{ nextInfoTime: crisisTask.nextInfoTime }"
                [isReadOnly]="false"
                [hasDoneBtn]="false"
                (saveNextInfo)="saveNextInfo($event)"
              >
              </app-hol-next-info>
            </div>
          </div>
        </div>

        -->
      </div>
    </form>
    <!--
    <div class="content-grid">
      <div class="section">
        <app-section-with-title [icon]="'shoppingmode'" [titleKey]="'COMMON.MODALS.BUTTONS.TAG' | translate">
          <app-tag-filter
            [(selectedTags)]="crisisTask.tags"
            [canAddTag]="false"
            [isCabin]="true"
            [isFilterMode]="false"
            [showOnlyDefaultTags]="crisisTask.defaultTags !== undefined && crisisTask.defaultTags.length > 0"
            [showTitle]="false"
          >
          </app-tag-filter>
        </app-section-with-title>
      </div>
      <br /><br />
    </div>
    -->
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="action-bar">
      <button
        class="action action-validate"
        [disabled]="
          !form.valid ||
          !crisisTask.taskTitle ||
          !crisisTask.taskDescription ||
          !crisisTask.outputTitle ||
          crisisTask.taskTitle.trim().length === 0 ||
          crisisTask.taskDescription.trim().length === 0 ||
          crisisTask.outputTitle.trim().length === 0 ||
          (data?.isCreationMode && !crisisTask.customVisibleBy && crisisTask.outputDataLabel.trim().length > 0)
        "
        (click)="saveCrisisTask()"
      >
        <span><i aria-hidden="true" class="fa fa-check"></i></span>
        <span>{{ 'COMMON.ADD' | translate }}</span>
      </button>
    </div>
  </mat-dialog-actions>
</div>
