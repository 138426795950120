'use strict';

/**
 * @ngdoc function
 * @name gestiondecriseApp.controller:LeftMenuCtrl
 * @description
 * # LeftMenuCtrl
 * Controller of the gestiondecriseApp
 */
angular
  .module('gestiondecriseApp')
  .controller(
    'LeftMenuCtrl',
    function ($scope, $state, ModalsService, $translate, ErpCrisisService, UserService, CommonService, EclUsersService) {
      $scope.isCrisisState = $state.includes('app.crisis');
      $scope.isOCCState = $state.includes('app.occ');
      $scope.isECLState = $state.includes('app.ecl');
      $scope.isCrewState = $state.includes('app.crew');
      $scope.isMCCState = $state.includes('app.mcc');
      $scope.isGOCState = $state.includes('app.goc');
      $scope.isOPSState = $state.includes('app.ops');

      $scope.userCanAccessRef = false;

      $scope.createNewCrisis = function (ev) {
        function openNewCrisisModal() {
          ModalsService.openCrisisModal({ erpCrisis: null });
        }

        if ($scope.crisis && ($scope.crisis.inProgress || $scope.crisis.isInPreparation)) {
          if (!$scope.crisis.isTraining) {
            //Crisis in progress
            ModalsService.openAlertModal({
              title: $translate.instant('ERP.MODALS.ALERT_CRISIS_IN_PROGRESS.TITLE'),
              content: $translate.instant('ERP.MODALS.ALERT_CRISIS_IN_PROGRESS.CONTENT'),
              targetEvent: ev
            });
          } else {
            // Exercise in progress
            ModalsService.openConfirmModal({
              title: $translate.instant('ERP.MODALS.CONFIRM_CLOSE_EXERCICE.TITLE'),
              content: $translate.instant('ERP.MODALS.CONFIRM_CLOSE_EXERCICE.CONTENT'),
              question: $translate.instant('ERP.MODALS.CONFIRM_CLOSE_EXERCICE.QUESTION'),
              targetEvent: ev
            }).then(result => {
              if (result) {
                ErpCrisisService.closeCrisis($scope.crisis).then(() => {
                  openNewCrisisModal(ev);
                });
              }
            });
          }
        } else {
          openNewCrisisModal(ev);
        }
      };

      EclUsersService.isCurrentUserCanSeeTeamsRef().then(userCanSeeRef => ($scope.userCanAccessRef = userCanSeeRef));
    }
  );
