<div [class.acl-selector]="roles || roles.length > 1" [class.hidden]="!roles || roles.length < 0" class="acl-selector-container">
  <div>
    <span *ngIf="!readOnly && !isCompact" class="acl-text">{{ 'COMMON.ACL' | translate }}</span>
    <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" class="roles-buttons" multiple>
      <ng-container *ngFor="let role of userRoles">
        <mat-button-toggle
          (change)="userRoles.length > 1 && updateACL(true)"
          *ngIf="!role.disabledFromLimit && selectValuesFromModuleFilter"
          [disabled]="
            userRoles.length === 1 ||
            (extendACLOnly && !!role.initialWriteRoles?.length) ||
            role.disabledFromLimit ||
            !role.userWriteRoles.length ||
            readOnly ||
            (alwaysOneSelected && btnGroup.value.length === 1 && isSelected(role))
          "
          [ngStyle]="{
            color: isSelected(role) ? 'white' : role.color,
            'border-color': role.color,
            'background-color': isSelected(role) ? role.color : mixColors('#FFF', role.color, 90)
          }"
          [value]="role"
        >
          {{ role.company }}
        </mat-button-toggle>
      </ng-container>
    </mat-button-toggle-group>
  </div>
</div>
