import { BaseComponent } from 'src/app/common/components/base/base.component';
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { OclHistoryService, OclTimeSlot } from '../../services/ocl-history-service/ocl-history.service';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { GocLog } from '../../../goc/models/goc-log.model';
import { cloneDeep, find, last, orderBy } from 'lodash';
import { OclHistoryLog } from '../../models/ocl-history-log.model';
import { RolesService } from '../../../common/services/roles.service';

@Component({
  selector: 'app-ocl-history',
  templateUrl: './ocl-history.component.html',
  styleUrls: ['./ocl-history.component.scss'],
})
export class OclHistoryComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public fromDate: Moment;
  @Input()
  public toDate: Moment;
  @Input()
  public searchText: string;
  @Input()
  public isValidDateForm: boolean;
  @Input()
  public isReadOnly: boolean;

  public loading = false;
  public timeSlots: OclTimeSlot[] = [];
  public additionalTimeSlots: OclTimeSlot[] = [];
  public originalTimeSlots: OclTimeSlot[] = [];
  public originalAdditionalTimeSlots: OclTimeSlot[] = [];
  public unregisterPoolLogs;

  public constructor(
    protected historyService: OclHistoryService<OclHistoryLog>,
    public moduleConfig: ModuleConfigService,
    @Inject('$rootScope') protected $rootScope,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.unregisterPoolLogs = this.$rootScope.$on('occPoolService-logs', (event, timeSlots) => {
      if (this.originalTimeSlots.length === timeSlots.length && this.originalTimeSlots[0].timeStart.isSame(timeSlots[0].timeStart)) {
        this.originalTimeSlots = timeSlots;
        this.timeSlots = timeSlots;
        this.applyFilter();
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ((changes.fromDate || changes.toDate) && this.isValidDateForm) {
      this.loadTimeSlots();
    }
    if (changes.searchText) {
      this.applyFilter();
    }
  }

  public loadTimeSlots(): void {
    this.loading = true;
    this.timeSlots = [];
    this.additionalTimeSlots = [];
    this.historyService
      .getLogsByTimeSlots(true, this.fromDate, this.toDate)
      .then((timeSlots: any[]) => {
        this.originalTimeSlots = timeSlots;
        this.timeSlots = timeSlots;
        this.applyFilter();
      })
      .finally(() => (this.loading = false));
  }

  public trackByFunction(index, item): any {
    return item && item.timeStart ? item.timeStart : null;
  }

  public isNewDay(timeSlot, utc): any {
    return !utc
      ? timeSlot.timeStart.get('day') !== timeSlot.timeEnd.get('day')
      : timeSlot.timeStart.utc().get('day') !== timeSlot.timeEnd.utc().get('day');
  }

  public loadMore(): void {
    let toDate;
    this.loading = true;
    if (this.additionalTimeSlots && this.additionalTimeSlots.length) {
      toDate = last(this.additionalTimeSlots).timeEnd;
    } else if (this.timeSlots && this.timeSlots.length) {
      toDate = last(this.timeSlots).timeEnd;
    }
    const to = moment(toDate).subtract(1, 'days');
    const from = moment(toDate).subtract(1, 'days');
    this.historyService
      .getLogsByTimeSlots(true, from, to)
      .then(timeSlots => {
        this.originalAdditionalTimeSlots = this.originalAdditionalTimeSlots.concat(timeSlots);
        this.additionalTimeSlots = this.additionalTimeSlots.concat(timeSlots);
      })
      .finally(() => (this.loading = false));
  }

  public applyFilter(): void {
    // numeros de vols / code escale / scenario
    this.timeSlots = cloneDeep(this.originalTimeSlots);
    this.additionalTimeSlots = cloneDeep(this.originalAdditionalTimeSlots);

    if (this.searchText && this.searchText.length) {
      const text = this.searchText.replace(/ /g, '').toUpperCase();
      this.timeSlots = this.filterTimeSlots(text, this.timeSlots);
      this.additionalTimeSlots = this.filterTimeSlots(text, this.additionalTimeSlots);
    }
  }

  public filterTimeSlots(text: string, timeSlots: any[]): any[] {
    return timeSlots.map(timeSlot => {
      if (timeSlot.logs && Object.keys(timeSlot.logs).length) {
        if (timeSlot.logs.logbook) {
          timeSlot.logs.logbook = this.filterLogbooks(text, timeSlot.logs.logbook);
        }
        if (timeSlot.logs.event) {
          timeSlot.logs.event = this.filterEvents(text, timeSlot.logs.event);
        }
        if (timeSlot.logs.decision) {
          timeSlot.logs.decision = this.filterDecisions(text, timeSlot.logs.decision);
        }
      }
      return timeSlot;
    });
  }

  public filterDecisions(text: string, decisions: GocLog[]): GocLog[] {
    return decisions.filter(dec => {
      let matchString = '';
      if (dec.jsonObject && dec.jsonObject.message) {
        matchString += dec.jsonObject.message;
      }
      matchString = matchString.replace(/ /g, '').toUpperCase();
      return matchString.includes(text);
    });
  }

  public filterLogbooks(text: string, logbooks: GocLog[]): GocLog[] {
    return logbooks.filter(logbook => {
      let matchString = '';
      matchString += logbook.jsonObject.text;
      matchString = matchString.replace(/ /g, '').toUpperCase();
      return matchString.includes(text);
    });
  }

  // ScenarioCode-ScenarioOrder-ScenarioTitle-Description-DescriptionOfLastNextInfo
  public filterEvents(text: string, events: GocLog[]): GocLog[] {
    return events.filter(event => {
      let matchString = '';
      if (event.jsonObject) {
        matchString += event.jsonObject.scenario.code;
        matchString += event.jsonObject.order;
        if (event.jsonObject.scenario) {
          matchString += event.jsonObject.scenario.title;
        }
        matchString += event.jsonObject.description;
        matchString += this.getNextInfo(event) ? this.getNextInfo(event).message : '';
        matchString = matchString.replace(/ /g, '').toUpperCase();
      }
      return matchString.includes(text);
    });
  }

  public getNextInfo(event: any): any {
    const eventOrderBy = orderBy(event.jsonObject.infos, 'nextInfoTime', 'asc');
    return find(eventOrderBy, info => !info.done);
  }

  public ngOnDestroy(): void {
    this.unregisterPoolLogs();
  }

  protected readonly RolesService = RolesService;
}
