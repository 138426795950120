import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-hol-switch-toggle',
  templateUrl: './hol-switch-toggle.component.html',
  styleUrls: ['./hol-switch-toggle.component.scss'],
})
export class HolSwitchToggleComponent implements OnInit {
  @Input() firstLabel: string;
  @Input() secondLabel: string;
  @Input() isActivated: boolean;
  @Output() valueInParentComponentChanged = new EventEmitter<boolean>();

  isChecked: boolean;

  constructor() {}

  ngOnInit() {
    this.isChecked = this.isActivated;
  }

  // onChange() {
  //   this.valueInParentComponentChanged.emit(this.isActivate);
  // }

  setValue() {
    this.isChecked = true;
    this.valueInParentComponentChanged.emit(this.isChecked);
  }

  unsetValue() {
    this.isChecked = false;

    this.isChecked = false;
    this.valueInParentComponentChanged.emit(this.isChecked);
  }
}
