import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import * as moment from 'moment';
import { differenceBy, flatten, isEqual, startsWith } from 'lodash';

import { FilesService } from 'src/app/common/services/files.service';
import { MarkdownService } from '../../common/components/markdown-editor/markdown.service';
import { HolContext } from '../../common/models/hol-context.model';
import { HolFlight } from '../../common/models/hol-flight.model';
import { HolNotification } from '../../common/models/hol-notification.model';
import { HolTag } from '../../common/models/hol-tag';
import { ModuleConfigService } from '../../common/services/module-config/module-config.service';
import { NotificationsService } from '../../common/services/notifications/notifications.service';
import { ParseMapperService } from '../../common/services/parse-mapper.service';
import { RequestService } from '../../common/services/request.service';
import { OclDecisionService } from '../../ocl/services/ocl-decision-service/ocl-decision.service';
import { OclDecisionTagService } from '../../ocl/services/ocl-decision-tag-service/ocl-decision-tag.service';
import { OclHistoryService } from '../../ocl/services/ocl-history-service/ocl-history.service';
import { OclMailService } from '../../ocl/services/ocl-mail-service/ocl-mail.service';
import { OclOptionsService } from '../../ocl/services/ocl-options-service/ocl-options.service';
import { OclSmsService } from '../../ocl/services/ocl-sms-service/ocl-sms.service';
import { OclDecisionsStoreManager } from '../../ocl/store/decisions/ocl-decisions.store-manager';
import { FltDecision } from '../models/flt-decision';
import { FltApplicabilityService } from './flt-applicability.service';
import { FltFlightInstructionService } from './flt-flight-instruction.service';
import { FltFlightLogbookService } from './flt-flight-logbook.service';
import { FltFlightService } from './flt-flight.service';
import { OclHistoryLog } from '../../ocl/models/ocl-history-log.model';
import { TranslatePipe } from '../../common/pipes/translate/translate.pipe';

@Injectable({
  providedIn: 'root',
})
export abstract class FltDecisionService<
  T extends FltDecision = FltDecision,
  U extends HolContext = HolContext,
> extends OclDecisionService<T> {
  // tslint:disable:variable-name
  protected abstract ParseFlightInstruction;
  protected abstract ParseFlightLogbook;

  // tslint:enabled

  protected constructor(
    protected readonly datePipe: DatePipe,
    protected readonly filesService: FilesService,
    protected translate: TranslatePipe,
    protected decisionsStoreManager: OclDecisionsStoreManager,
    protected parseMapper: ParseMapperService,
    protected requestService: RequestService,
    protected optionsService: OclOptionsService,
    protected notificationsService: NotificationsService,
    protected smsService: OclSmsService,
    protected mailService: OclMailService,
    protected historyService: OclHistoryService<OclHistoryLog>,
    protected decisionTagService: OclDecisionTagService,
    protected moduleConfig: ModuleConfigService,
    protected flightInstructionService: FltFlightInstructionService,
    protected flightService: FltFlightService,
    protected flightLogbookService: FltFlightLogbookService,
    protected markdownService: MarkdownService,
    protected applicabilityService: FltApplicabilityService,
    protected commonStoreManager: CommonStoreManager,
  ) {
    super(
      datePipe,
      filesService,
      translate,
      decisionsStoreManager,
      parseMapper,
      requestService,
      optionsService,
      notificationsService,
      smsService,
      mailService,
      historyService,
      decisionTagService,
      moduleConfig,
      markdownService,
      commonStoreManager,
    );
  }

  public setAdditionalFields(decision: FltDecision, parseDecision: Parse.Object) {
    if (decision.applicability) {
      decision.applicability.updateParseObject(parseDecision);
    }
    parseDecision.set('toInstruction', decision.toInstruction);
    if (decision.isFromFlight && decision.isFromFlight.objectId !== null) {
      parseDecision.set(
        'isFromFlight',
        new this.ParseFlight({
          id: decision.isFromFlight.objectId,
        }),
      );
    }
  }

  public create(decision: T, notifications: HolNotification[], context: HolContext): Promise<T> {
    let addBreakLinesBefore = false;
    if (decision.attachments && decision.attachments.note) {
      addBreakLinesBefore = true;
    }
    return super.create(decision, notifications, context).then(savedDecision => {
      decision.objectId = savedDecision.objectId;
      if (decision.isTodo) {
        return savedDecision;
      }
      return this.flightService.getFlightsForApplicability(decision.applicability, decision.acl).then(applicableFlights => {
        if (decision.isFromFlight && applicableFlights.find(f => f.objectId === decision.isFromFlight.objectId)) {
          applicableFlights = applicableFlights.filter(f => f.objectId !== decision.isFromFlight.objectId);
        }
        if (decision.toInstruction) {
          const instructions = flatten(
            applicableFlights.map(f => {
              return this.getFlightInstructionFromDecision(f, decision);
            }),
          );
          return this.requestService
            .performSaveAllQuery(instructions)
            .then(() => this.saveApplicabilityHistory(savedDecision, context, addBreakLinesBefore));
        } else {
          const logbooks = flatten(
            applicableFlights.map(f => {
              return this.getFlightLogbookFromDecision(f, decision);
            }),
          );
          return this.requestService
            .performSaveAllQuery(logbooks)
            .then(() => this.saveApplicabilityHistory(savedDecision, context, addBreakLinesBefore));
        }
      });
    });
  }

  public async update(decision: T, notifications: HolNotification[], context: HolContext): Promise<T> {
    const oldDecision = new FltDecision(new this.ParseDecision({ id: decision.objectId }));
    const oldApplicability = oldDecision.applicability;
    const newApplicability = decision.applicability;

    return super.update(decision, notifications, context).then(res => {
      return Promise.all([
        this.flightLogbookService.getAllFromDecision(decision),
        this.flightInstructionService.getAllFromDecision(decision),
        this.flightService.getFlightsForApplicability(decision.applicability, decision.acl),
      ]).then(([oldFlightLogbooks, oldFlightInstructions, matchingFlights]) => {
        const compareFlightItems = item => {
          return item.get('flight').id + '#' + item.get('station');
        };

        if (decision.toInstruction) {
          const newFlightInstructions = flatten(
            matchingFlights.map(f => {
              return this.getFlightInstructionFromDecision(f, decision);
            }),
          );
          const toRemove = differenceBy(oldFlightInstructions, newFlightInstructions, compareFlightItems);
          const toCreate = differenceBy(newFlightInstructions, oldFlightInstructions, compareFlightItems);
          return Promise.all([
            this.requestService.performDestroyAllQuery(oldFlightLogbooks),
            this.requestService.performDestroyAllQuery(toRemove),
            this.requestService.performSaveAllQuery(toCreate),
          ]).then(() => {
            if (!isEqual(newApplicability, oldApplicability) && !res.isTodo) {
              return this.saveApplicabilityHistory(res, context);
            } else {
              return res;
            }
          });
        } else {
          const newFlightLogbooks = flatten(
            matchingFlights.map(f => {
              return this.getFlightLogbookFromDecision(f, decision);
            }),
          );
          const toRemove = differenceBy(oldFlightLogbooks, newFlightLogbooks, compareFlightItems);
          const toCreate = differenceBy(newFlightLogbooks, oldFlightLogbooks, compareFlightItems);
          return Promise.all([
            this.requestService.performDestroyAllQuery(oldFlightInstructions),
            this.requestService.performDestroyAllQuery(toRemove),
            this.requestService.performSaveAllQuery(toCreate),
          ]).then(() => {
            if (!isEqual(newApplicability, oldApplicability) && !res.isTodo) {
              return this.saveApplicabilityHistory(res, context);
            } else {
              return res;
            }
          });
        }
      });
    });
  }

  private getFlightInstructionFromDecision(flight: HolFlight, decision: T): Parse.Object[] {
    const flightInstruction = this.initFlightInstructionFromDecision(flight, decision);

    if (decision.applicability.flightsDirection === 'DEP' || decision.applicability.stationsDirection === 'OUT') {
      flightInstruction.set('station', flight.departure);
    } else if (decision.applicability.flightsDirection === 'ARR' || decision.applicability.stationsDirection === 'IN') {
      flightInstruction.set('station', flight.destination);
    } else if (!decision.applicability.stationsDirection && decision.applicability.stations) {
      const flightInstructions = [];
      decision.applicability.stations.forEach(station => {
        if (flight.departure === station || flight.destination === station) {
          const tempInstruction = this.initFlightInstructionFromDecision(flight, decision);
          tempInstruction.set('station', station);
          flightInstructions.push(tempInstruction);
        }
      });
      return flightInstructions;
    }
    return [flightInstruction];
  }

  protected initFlightInstructionFromDecision(flight: HolFlight, decision: T) {
    const flightInstruction = new this.ParseFlightInstruction();
    flightInstruction.set('flight', new this.ParseFlight({ id: flight.objectId }));
    flightInstruction.set('decision', new this.ParseDecision({ id: decision.objectId }));
    if (!flight.acl.getPublicWriteAccess()) {
      Object.entries(flight.acl.permissionsById).forEach(([key, value]) => {
        if (key.startsWith('role:') && !startsWith(key.replace('role:', ''), this.moduleConfig.config.moduleName.toUpperCase())) {
          delete flight.acl.permissionsById[key];
        }
      });
      flightInstruction.setACL(flight.acl);
    } else {
      flightInstruction.setACL(decision.acl);
    }
    return flightInstruction;
  }

  private getFlightLogbookFromDecision(flight: HolFlight, decision: T): Parse.Object[] {
    const flightLogbook = this.initFlightLogbookFromDecision(flight, decision);
    if (decision.applicability.flightsDirection === 'DEP' || decision.applicability.stationsDirection === 'OUT') {
      flightLogbook.set('station', flight.departure);
    } else if (decision.applicability.flightsDirection === 'ARR' || decision.applicability.stationsDirection === 'IN') {
      flightLogbook.set('station', flight.destination);
    } else if (!decision.applicability.stationsDirection && decision.applicability.stations) {
      const flightLogbooks = [];
      decision.applicability.stations.forEach(async station => {
        if (flight.departure === station || flight.destination === station) {
          const tempLogbook = this.initFlightLogbookFromDecision(flight, decision);
          tempLogbook.set('station', station);
          flightLogbooks.push(tempLogbook);
        }
      });
      return flightLogbooks;
    }
    return [flightLogbook];
  }

  protected initFlightLogbookFromDecision(flight: HolFlight, decision: T): Parse.Object {
    const flightLogbook = new this.ParseFlightLogbook();
    flightLogbook.set('flight', new this.ParseFlight({ id: flight.objectId }));
    flightLogbook.set('decision', new this.ParseDecision({ id: decision.objectId }));
    if (!flight.acl.getPublicWriteAccess()) {
      Object.entries(flight.acl.permissionsById).forEach(([key, value]) => {
        if (key.startsWith('role:') && !startsWith(key.replace('role:', ''), this.moduleConfig.config.moduleName.toUpperCase())) {
          delete flight.acl.permissionsById[key];
        }
      });
      flightLogbook.setACL(flight.acl);
    } else {
      flightLogbook.setACL(decision.acl);
    }
    return flightLogbook;
  }

  protected newDecision(parseObject?: Parse.Object, tags?: Parse.Object[]): T {
    return new FltDecision(parseObject, tags && tags.map(t => new HolTag(t.get('tag')))) as T;
  }

  protected getAdditionnalQueries(query, queryPinned, filterDataStartDate) {
    // Filtrage du tableau de bord par date
    // - Soit T = date sélectionnée à 00:00Z ou date à 00:00Z si aucune date n’est sélectionnée
    // - Soit C = date de création d’une carte `createdAt`
    // - Soit S = date de fin d’applicabilité escale d’une carte
    // - Soit F = Max(STA) des vols applicables à une carte
    // - Soit N = NEXT INFO le plus élevé ou prochain NEXT INFO
    // | Applicabilité DECISION | Visibilité                                                                                   | Prise en compte valeur `…ToDisplay` |
    // | ------------------------------ | ----------------------------------------------------------------------------------- | ----------------------------------- |
    // | ESCALES                        | `C ≤ T && S ≥ T`                                                                    | NON                                 |
    // | VOLS                           | `C ≤ T && F ≥ T`                                                                    | NON                                 |
    // | TOUS VOLS                      | Toujours                                                                            | NON                                 |=> NON DEV pour l'instant
    // | AUCUNE                         | Si  `MAX(createdAt, customCreatedAt?)`  appartient à l’intervalle  `T - …ToDisplay` | OUI                                 |
    // | TODO (not done)                | `C ≤ T`
    // | TODO (done)                    | `C ≤ T && NIT ≥ T`                                                                  | NON |

    if (this.moduleConfig.config.canChooseDataStartDate && filterDataStartDate instanceof Date && filterDataStartDate !== undefined) {
      query.doesNotExist('applStations');
      query.doesNotExist('applFlights');
      query.notEqualTo('isTodo', true);

      const todayStart = moment.utc(filterDataStartDate).startOf('day');
      const todayEnd = moment.utc(filterDataStartDate).endOf('day');

      const queryStationApplicability = new Parse.Query(this.ParseDecision);
      queryStationApplicability.exists('applStations');
      queryStationApplicability.notEqualTo('applStations', '');
      queryStationApplicability.doesNotExist('customCreatedAt');
      queryStationApplicability.lessThanOrEqualTo('createdAt', todayEnd.toDate());
      queryStationApplicability.greaterThanOrEqualTo('validTo', todayStart.toDate());
      queryStationApplicability.notEqualTo('isTodo', true);

      const queryStationApplicability2 = new Parse.Query(this.ParseDecision);
      queryStationApplicability2.exists('applStations');
      queryStationApplicability2.notEqualTo('applStations', '');
      queryStationApplicability2.doesNotExist('customCreatedAt');
      queryStationApplicability2.lessThanOrEqualTo('createdAt', todayEnd.toDate());
      queryStationApplicability2.doesNotExist('validTo');
      queryStationApplicability2.notEqualTo('isTodo', true);

      const queryStationApplicability3 = new Parse.Query(this.ParseDecision);
      queryStationApplicability3.exists('applStations');
      queryStationApplicability3.notEqualTo('applStations', '');
      queryStationApplicability3.exists('customCreatedAt');
      queryStationApplicability3.notEqualTo('customCreatedAt', '');
      queryStationApplicability3.lessThanOrEqualTo('customCreatedAt', todayEnd.toDate());
      queryStationApplicability3.greaterThanOrEqualTo('validTo', todayStart.toDate());
      queryStationApplicability3.notEqualTo('isTodo', true);

      const queryStationApplicability4 = new Parse.Query(this.ParseDecision);
      queryStationApplicability4.exists('applStations');
      queryStationApplicability4.notEqualTo('applStations', '');
      queryStationApplicability4.exists('customCreatedAt');
      queryStationApplicability4.notEqualTo('customCreatedAt', '');
      queryStationApplicability4.lessThanOrEqualTo('customCreatedAt', todayEnd.toDate());
      queryStationApplicability4.doesNotExist('validTo');
      queryStationApplicability4.notEqualTo('isTodo', true);

      const queryLinkedFlights = new Parse.Query(this.ParseFlight);
      queryLinkedFlights.greaterThanOrEqualTo('sta', todayStart.toDate());
      queryLinkedFlights.notEqualTo('status', 'C');

      const queryFlightsApplicability = new Parse.Query(this.ParseDecision);
      queryFlightsApplicability.exists('applFlights');
      queryFlightsApplicability.notEqualTo('applFlights', '');
      queryFlightsApplicability.doesNotExist('customCreatedAt');
      queryFlightsApplicability.lessThanOrEqualTo('createdAt', todayEnd.toDate());
      queryFlightsApplicability.matchesQuery('applFlights', queryLinkedFlights);
      queryFlightsApplicability.notEqualTo('isTodo', true);

      const queryFlightsApplicability2 = new Parse.Query(this.ParseDecision);
      queryFlightsApplicability2.exists('applFlights');
      queryFlightsApplicability2.notEqualTo('applFlights', '');
      queryFlightsApplicability2.exists('customCreatedAt');
      queryFlightsApplicability2.notEqualTo('customCreatedAt', '');
      queryFlightsApplicability2.lessThanOrEqualTo('customCreatedAt', todayEnd.toDate());
      queryFlightsApplicability2.matchesQuery('applFlights', queryLinkedFlights);
      queryFlightsApplicability2.notEqualTo('isTodo', true);

      const queryToDo = new Parse.Query(this.ParseDecision);
      queryToDo.equalTo('isTodo', true);
      queryToDo.equalTo('done', true);
      queryToDo.doesNotExist('customCreatedAt');
      queryToDo.lessThanOrEqualTo('createdAt', todayEnd.toDate());
      queryToDo.greaterThanOrEqualTo('nextInfoTime', todayStart.toDate());

      const queryToDo2 = new Parse.Query(this.ParseDecision);
      queryToDo2.equalTo('isTodo', true);
      queryToDo2.notEqualTo('done', true);
      queryToDo2.doesNotExist('customCreatedAt');
      queryToDo2.lessThanOrEqualTo('createdAt', todayEnd.toDate());

      const queryToDo3 = new Parse.Query(this.ParseDecision);
      queryToDo3.equalTo('isTodo', true);
      queryToDo3.equalTo('done', true);
      queryToDo3.exists('customCreatedAt');
      queryToDo3.notEqualTo('customCreatedAt', '');
      queryToDo3.lessThanOrEqualTo('customCreatedAt', todayEnd.toDate());
      queryToDo3.greaterThanOrEqualTo('nextInfoTime', todayStart.toDate());

      const queryToDo4 = new Parse.Query(this.ParseDecision);
      queryToDo4.equalTo('isTodo', true);
      queryToDo4.notEqualTo('done', true);
      queryToDo4.exists('customCreatedAt');
      queryToDo4.notEqualTo('customCreatedAt', '');
      queryToDo4.lessThanOrEqualTo('customCreatedAt', todayEnd.toDate());

      return Parse.Query.or(
        query,
        queryPinned,
        queryStationApplicability,
        queryStationApplicability2,
        queryStationApplicability3,
        queryStationApplicability4,
        queryFlightsApplicability,
        queryFlightsApplicability2,
        queryToDo,
        queryToDo2,
        queryToDo3,
        queryToDo4,
      );
    } else {
      return Parse.Query.or(query, queryPinned);
    }
  }

  private async saveApplicabilityHistory(savedDecision: FltDecision, context: HolContext, addBreakLinesBefore = false) {
    const attachments = await this.applicabilityService.applicabilityHistoryInNotes(savedDecision, context, addBreakLinesBefore);
    const parseDecision = new this.ParseDecision();
    parseDecision.id = savedDecision.objectId;
    parseDecision.set('attachments', JSON.stringify(attachments));
    const d = await this.requestService.performSaveQuery(parseDecision);
    const newDecision = this.newDecision(d, d.tags);
    this.decisionsStoreManager.updateOneDecision(newDecision);
    return newDecision;
  }
}
