import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OclTimelineSearchModalComponent } from '../../modals/ocl-timeline-search-modal/ocl-timeline-search-modal.component';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';

@Component({
  selector: 'app-ocl-timeline',
  templateUrl: './ocl-timeline.component.html',
  styleUrls: ['./ocl-timeline.component.scss'],
})
export class OclTimelineComponent implements OnInit, OnDestroy {
  readonly TAB_HISTORY = 0;
  readonly TAB_AGENDA = 1;
  public isPhone: boolean;

  tabIndex = 0;

  fromDate: Moment;
  toDate: Moment;

  now = moment().utc().startOf('day');

  searchInputValueChanges: any;
  searchText: string;
  public form: FormGroup;
  public dateForm: FormGroup;

  @Input() isReadOnly;
  showManagersPanel: boolean;

  public constructor(
    protected dialog: MatDialog,
    protected fb: FormBuilder,
    @Inject('$state') protected $state,
    public moduleConfig: ModuleConfigService,
    @Inject('$rootScope') public $rootScope,
  ) {
    this.isPhone = $rootScope.isPhone;
    this.createForm();
  }

  get searchInput() {
    return this.form.get('searchInput') as FormControl;
  }

  get currentModuleEcl() {
    return true;
  }

  ngOnInit() {
    this.resetDates();
    this.watchSearchInputValueChanges();
  }

  createForm() {
    this.form = this.fb.group({
      searchInput: [undefined, null],
    });

    this.dateForm = this.fb.group({
      fromDateInput: [this.fromDate, null],
      toDateInput: [this.toDate, null],
    });
  }

  openSearchModal($event) {
    $event.stopPropagation();
    this.dialog.open(OclTimelineSearchModalComponent, {});
  }

  resetDates() {
    this.searchText = null;
    if (this.tabIndex === this.TAB_HISTORY) {
      this.fromDate = null;
      this.toDate = moment().utc().endOf('day');
    } else if (this.tabIndex === this.TAB_AGENDA) {
      this.fromDate = moment().utc().startOf('day');
      this.toDate = null;
    }
  }

  watchSearchInputValueChanges() {
    this.searchInputValueChanges = this.searchInput.valueChanges.subscribe(result => {
      if (result && result.length) {
        this.searchText = result;
      } else {
        this.searchText = null;
      }
    });
  }

  ngOnDestroy() {
    this.searchInputValueChanges.unsubscribe();
  }

  toggleManagerPanel() {
    this.showManagersPanel = !this.showManagersPanel;
  }

  closeExternalPanels() {
    if (this.showManagersPanel) {
      this.showManagersPanel = false;
    }
  }
}
