import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { OclDecision } from 'src/app/ocl/models/ocl-decision.model';

import { FilesService } from 'src/app/common/services/files.service';

import { MarkdownService } from '../../../common/components/markdown-editor/markdown.service';
import { HelperService } from '../../../common/services/helper.service';
import { NotificationsService } from '../../../common/services/notifications/notifications.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { RequestService } from '../../../common/services/request.service';
import { RolesService } from '../../../common/services/roles.service';
import { OclDecisionService } from '../../../ocl/services/ocl-decision-service/ocl-decision.service';
import { OclDecisionsStoreManager } from '../../../ocl/store/decisions/ocl-decisions.store-manager';
import { EclDecisionTagService } from '../ecl-decision-tag-service/ecl-decision-tag.service';
import { EclHistoryService } from '../ecl-history-service/ecl-history.service';
import { EclMailService } from '../ecl-mail-service/ecl-mail.service';
import { EclModuleConfigService } from '../ecl-module-config/ecl-module-config.service';
import { EclOptionsService } from '../ecl-options-service/ecl-options.service';
import { EclSmsService } from '../ecl-sms-service/ecl-sms.service';
import { EclDecision } from '../../models/ecl-decision';
import { EclFunctionStoreManager } from '../../store/function/function.store-manager';
import { HolTag } from '../../../common/models/hol-tag';
import { EclCrisis } from '../../models/ecl-crisis';
import { EclFunctionState } from '../../ecl.model';
import { EclCrisisStoreManager } from '../../store/crisis/crisis.store-manager';
import { TranslatePipe } from '../../../common/pipes/translate/translate.pipe';

@Injectable({
  providedIn: 'root',
})
export class EclDecisionService extends OclDecisionService<EclDecision> {
  // tslint:disable:variable-name
  protected ParseDecision = Parse.Object.extend('ECLDecisions');
  protected ParseEvent = Parse.Object.extend('ECLEvents');
  protected ParseDecisionTag = Parse.Object.extend('ECLDecisionTag');
  protected ParseTag = Parse.Object.extend('ECLTag');
  protected ParseErpDecision = Parse.Object.extend('GDCDecisions');
  protected ParseEclFunction = Parse.Object.extend('ECLFunction');
  protected ParseCrisis = Parse.Object.extend('GDCCrisis');
  protected eclFunctionState: EclFunctionState;
  protected ParseEclCrisis = Parse.Object.extend('ECLCrisis');
  protected eclOpenCrisis: EclCrisis;

  // tslint:enabled

  public constructor(
    protected readonly datePipe: DatePipe,
    protected readonly filesService: FilesService,
    protected translate: TranslatePipe,
    protected decisionsStoreManager: OclDecisionsStoreManager,
    protected parseMapper: ParseMapperService,
    protected requestService: RequestService,
    protected optionsService: EclOptionsService,
    protected notificationsService: NotificationsService,
    protected smsService: EclSmsService,
    protected mailService: EclMailService,
    protected historyService: EclHistoryService,
    protected decisionTagService: EclDecisionTagService,
    public moduleConfig: EclModuleConfigService,
    public commonStoreManager: CommonStoreManager,
    private helperService: HelperService,
    public markdownService: MarkdownService,
    private rolesService: RolesService,
    protected eclFunctionStoreManager: EclFunctionStoreManager,
    protected eclCrisisStoreManager: EclCrisisStoreManager,
  ) {
    super(
      datePipe,
      filesService,
      translate,
      decisionsStoreManager,
      parseMapper,
      requestService,
      optionsService,
      notificationsService,
      smsService,
      mailService,
      historyService,
      decisionTagService,
      moduleConfig,
      markdownService,
      commonStoreManager,
    );
    eclFunctionStoreManager.$eclFunctionState.subscribe(eclFs => (this.eclFunctionState = eclFs));
    this.eclCrisisStoreManager.$eclSelectedCrisis.subscribe(crisis => (this.eclOpenCrisis = crisis));
  }

  protected async duplicateDecisionToOtherModule(decision: OclDecision) {
    const crisis = await this.commonStoreManager.crisis.pipe(take(1)).toPromise();
    const parseErpDecision = new this.ParseErpDecision();
    parseErpDecision.set('eclDecision', new this.ParseDecision({ id: decision.objectId }));
    parseErpDecision.set('crisis', new this.ParseCrisis({ id: crisis.objectId }));
    const companies = this.helperService.parseACL(decision.acl);
    const acl: Parse.ACL = await this.rolesService.getACLForCompanies(['ERP'], companies);
    parseErpDecision.setACL(acl);
    this.requestService.performSaveAllQuery(parseErpDecision).then();
  }

  protected deleteDuplicateDecisionFromModule(decision: OclDecision) {
    const parseDecision = new this.ParseDecision({ id: decision.objectId });
    const query = new Parse.Query(this.ParseErpDecision);
    query.equalTo('eclDecision', parseDecision);

    this.requestService.performFirstQuery(query).then(result => {
      if (result) {
        const parseErpDecision = new this.ParseErpDecision({ id: result.id });
        this.requestService.performDestroyQuery(parseErpDecision).then();
      }
    });
  }

  protected setAdditionalFields(decision: OclDecision, parseDecision: Parse.Object) {
    const d = decision as EclDecision;
    // Set existing function or new from selected user function
    if (this.eclFunctionState.functions.length) {
      const objectId =
        (d.function && d.function.objectId) ||
        (this.eclFunctionState.selectedUserFunction && this.eclFunctionState.selectedUserFunction.objectId);
      parseDecision.set(
        'function',
        new this.ParseEclFunction({
          id: objectId,
        }),
      );
    }
    parseDecision.set(
      'crisis',
      new this.ParseEclCrisis({
        id: this.eclOpenCrisis.objectId,
      }),
    );
  }

  protected newDecision(parseObject?: Parse.Object, tags?: Parse.Object[]): EclDecision {
    return new EclDecision(parseObject, tags && tags.map(t => new HolTag(t.get('tag'))));
  }

  protected getAdditionnalQueries(query, queryPinned, filterDataStartDate): Parse.Query {
    query.include('function');
    return super.getAdditionnalQueries(query, queryPinned, filterDataStartDate);
  }
}
