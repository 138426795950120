import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { OclChecklistFunction, OclTasksSelectors } from '../../store/ocl-tasks.selectors';

@Component({
  selector: 'app-ocl-tasks',
  templateUrl: './ocl-tasks.component.html',
  styleUrls: ['./ocl-tasks.component.scss'],
})
export class OclTasksComponent implements OnInit, OnDestroy {
  showManagersPanel: boolean;
  checkList: OclChecklistFunction[] = [];
  loading: boolean;
  public searchCriteria: string;

  private tasksSub: Subscription;
  constructor(
    @Inject('isReadOnly') public isReadOnly,
    private tasksSelectors: OclTasksSelectors,
    public moduleConfig: ModuleConfigService,
  ) {}

  ngOnInit() {
    this.tasksSub = this.tasksSelectors.eventChecklists.subscribe(checklists => {
      this.checkList = checklists;
    });
  }

  toggleManagerPanel() {
    this.showManagersPanel = !this.showManagersPanel;
  }

  closeExternalPanels() {
    if (this.showManagersPanel) {
      this.showManagersPanel = false;
    }
  }

  public onChangeSearchEvent(e: string): void {
    this.searchCriteria = e;
  }

  ngOnDestroy(): void {
    this.tasksSub.unsubscribe();
  }
}
