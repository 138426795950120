import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { Component, Input, OnInit } from '@angular/core';
import { HolVacationChecklistItem, VACATION_CHECKLIST_ITEM_STATUS, VACATION_LOG_STATUS } from 'src/app/common/models/hol-vacation.model';
import { VacationService } from 'src/app/common/services/vacation.service';
import { MatDialog } from '@angular/material/dialog';
import { ChecklistsItemEditComponent } from '../checklists-item-edit/checklists-item-edit.component';
import { ModuleConfigService } from '../../../services/module-config/module-config.service';

@Component({
  selector: 'app-checklist-item-vac',
  templateUrl: './checklist-item.component.html',
  styleUrls: ['./checklist-item.component.scss'],
})
export class ChecklistItemComponent implements OnInit {
  VACATION_CHECKLIST_ITEM_STATUS: typeof VACATION_CHECKLIST_ITEM_STATUS = VACATION_CHECKLIST_ITEM_STATUS;

  @Input() checklistItem: HolVacationChecklistItem;
  @Input() checklistTitle: string;
  @Input() checklistTitleForLog: string;
  @Input() vacationId: string;
  @Input() checklistId: string;
  @Input() mod: string;
  @Input() isReadOnly: boolean;
  isUtc: boolean;
  itemTitle: string;

  constructor(
    private vacationService: VacationService,
    private dialog: MatDialog,
    private commonStoreManager: CommonStoreManager,
    private moduleConfig: ModuleConfigService,
  ) {}

  ngOnInit(): void {
    this.itemTitle = this.checklistTitleForLog + ' - ' + this.checklistItem.label;
    if (this.moduleConfig.config.localHours) {
      this.isUtc = false;
    } else {
      this.isUtc = !this.isReadOnly;
    }
  }

  statusItemUpdate($event, status): void {
    $event.stopPropagation();
    this.vacationService.statusItemUpdate(this.checklistItem, status).then(result => {
      let logStatus: VACATION_LOG_STATUS;

      if (result.status === 'NOT_APPLICABLE') {
        logStatus = VACATION_LOG_STATUS.DEACTIVATE_ITEM_CHECKLIST;
      } else if (result.status === 'DONE') {
        logStatus = VACATION_LOG_STATUS.CHECKED_ITEM_CHECKLIST;
      } else {
        logStatus = VACATION_LOG_STATUS.UNCHECKED_ITEM_CHECKLIST;
      }

      this.vacationService.logChecklist(
        this.mod,
        logStatus,
        null,
        null,
        this.checklistItem,
        this.itemTitle,
        this.checklistItem.attachments,
        this.vacationId,
      );

      this.commonStoreManager.updateHolVacation(this.vacationId, this.checklistId, this.checklistItem.objectId, result);
    });
  }

  addAttachments($event): void {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(ChecklistsItemEditComponent, {
      data: {
        title: this.checklistTitle,
        item: this.checklistItem,
        context: {
          module: this.mod,
          category: 'CHECKLIST_VACATION',
          action: this.checklistItem.label,
          htmlDate: this.checklistItem.createdAt,
          template: 'A',
        },
        isReadOnly: this.isReadOnly,
      },
    });

    dialogRef.afterClosed().subscribe(item => {
      if (!item) {
        return;
      }
      this.commonStoreManager.updateHolVacation(this.vacationId, this.checklistId, this.checklistItem.objectId, item);
      this.vacationService.logChecklist(
        this.mod,
        VACATION_LOG_STATUS.UPDATE_ATTACHEMENT_ITEM_CHECKLIST,
        null,
        null,
        this.checklistItem,
        this.itemTitle,
        item.attachments,
        this.vacationId,
      );
    });
  }
}
