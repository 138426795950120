import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { cloneDeep, isEqual } from 'lodash';
import { EclInfos } from 'src/app/common/store/common.model';
import { LocalStorageService } from 'src/app/common/services/local-storage.service';
import { CommonService } from 'src/app/common/services/common.service';

import { HolEventItemComponent } from '../../../common/components/hol-event-item/hol-event-item.component';
import { TimeOrDayPipe } from '../../../common/pipes/time-or-day/time-or-day.pipe';
import { TranslatePipe } from '../../../common/pipes/translate/translate.pipe';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { SystemNotificationService } from '../../../common/services/system-notification.service';
import { OCL_EVENT_MODAL_TOKEN } from '../../modals/ocl-event-modal/ocl-event-modal-tokens';

import { OclEvent } from '../../models/ocl-event.model';
import { OclLinksHelperService } from '../../services/ocl-links-helper-service/ocl-links-helper.service';
import { OclNotificationsService } from '../../services/ocl-notifications-service/ocl-notifications.service';
import { OclOptionsService } from '../../services/ocl-options-service/ocl-options.service';
import { OclDecisionsStoreManager } from '../../store/decisions/ocl-decisions.store-manager';
import { OclLinkedDisplayedItemsStoreManager } from '../../store/linked-display-items/ocl-linked-displayed-items-store-manager.service';
import { OclLogBooksStoreManager } from '../../store/logbooks/ocl-log-books-store-manager.service';

@Component({
  templateUrl: '../../../common/components/hol-event-item/hol-event-item.component.html',
  selector: 'app-ocl-event-item',
  styleUrls: ['../../../common/components/hol-event-item/hol-event-item.component.scss'],
})
export class OclEventItemComponent extends HolEventItemComponent implements OnInit, OnChanges {
  @Input() userCanEditCards?: boolean;
  @Input()
  public event: OclEvent;

  @Input() eclInfos: EclInfos;

  @Input() linkedIds;

  public linkedUid: string;
  public randomColor: string;
  public eventUseForActivateECL = false;
  @Input() airportsCode;

  constructor(
    @Inject('CONSTANTS') protected CONSTANTS,
    protected readonly commonService: CommonService,
    protected translatePipe: TranslatePipe,
    protected systemNotificationService: SystemNotificationService,
    protected timeOrDayPipe: TimeOrDayPipe,
    protected optionsService: OclOptionsService,
    public moduleConfig: ModuleConfigService,
    protected linksHelperService: OclLinksHelperService,
    protected occLinkedDisplayedItemsStoreManager: OclLinkedDisplayedItemsStoreManager,
    protected occLogBooksStoreManager: OclLogBooksStoreManager,
    protected occDecisionsStoreManager: OclDecisionsStoreManager,
    protected dialog: MatDialog,
    protected notificationsService: OclNotificationsService,
    protected readonly localStorage: LocalStorageService,
    @Inject(OCL_EVENT_MODAL_TOKEN) protected eventModal,
  ) {
    super(CONSTANTS, commonService, translatePipe, systemNotificationService, timeOrDayPipe);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.isNextInfoMandatory =
      this.optionsService.getIsNextInfoMandatory() !== undefined ? this.optionsService.getIsNextInfoMandatory() : true;
    this.eventUseForActivateECL = this.eclInfos && this.eclInfos.occEventId === this.event.objectId;
    // this.loadStorage();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.event.linkedData && !this.event.linkedData.linkedUid && this.event.linkedData.linkedUids.length === 0) {
      delete this.event.linkedData;
    }
    if (this.event && this.event.gocEvent) {
      this.fromModule = 'GOC';
    } else if (this.event && this.event.occEvent) {
      this.fromModule = 'OCC';
    } else if (this.event && this.event.fromMCC) {
      this.fromModule = 'MCC';
    } else {
      this.fromModule = undefined;
    }

    if (this.event.toOCC) {
      this.toModule = 'OCC';
    } else if (this.event.toGOC) {
      this.toModule = 'GOC';
    } else {
      this.toModule = undefined;
    }

    if (changes.event && changes.event.currentValue && changes.event.currentValue.linkedData && changes.event.currentValue.linkedData) {
      this.linkedUid = changes.event.currentValue.linkedData.linkedUid;
      this.localStorage.setBufferLinkedUid(this.event.linkedData.objectId);
    } else if (
      changes.event &&
      changes.event.previousValue &&
      changes.event.previousValue.linkedData &&
      changes.event.currentValue &&
      !changes.event.currentValue.linkedData
    ) {
      this.linkedUid = undefined;
      this.localStorage.removeBufferLinkedUid();
    }
    this.eventUseForActivateECL = this.eclInfos && this.eclInfos.occEventId === this.event.objectId;
  }
  public showLinkedItems($event): void {
    $event.stopPropagation();
    this.moduleConfig.setLoadingItemsLinked();
    const bufferLinkedUid = 'LinkFrom_' + this.event.objectId;

    if (!this.event.linkedData || !this.event.linkedData.linkedUid) {
      this.localStorage.setBufferLinkedUid(this.event.objectId);
      this.linksHelperService.getLinkFromEvent(this.event.objectId).then(data => {
        this.randomColor = data[0];
        const linkedItemsToDisplay = data[1];
        const logBooksToUpdate = data[2];
        const decisionsToUpdate = data[3];
        this.occLinkedDisplayedItemsStoreManager.addOrUpdateLinkedDiplayedItems(bufferLinkedUid, this.randomColor, linkedItemsToDisplay);
        if (decisionsToUpdate.length) {
          this.occDecisionsStoreManager.updateDecisionsFromLinkedValue(decisionsToUpdate, bufferLinkedUid);
        }
        if (logBooksToUpdate.length) {
          this.occLogBooksStoreManager.updateLogBooksFromLinkedValue(logBooksToUpdate, bufferLinkedUid);
        }
        this.moduleConfig.unsetLoadingItemsLinked();
      });
    } else {
      this.occLinkedDisplayedItemsStoreManager.removeLinkedDiplayedItems(bufferLinkedUid, this.randomColor);
      this.occDecisionsStoreManager.removeDecisionsFromLinkedValue(bufferLinkedUid);
      this.occLogBooksStoreManager.removeLogBooksFromLinkedValue(bufferLinkedUid);
      this.linkedUid = undefined;
      // this.localStorage.removeBufferLinkedUid();
      this.moduleConfig.unsetLoadingItemsLinked();
    }
  }

  public async openEditModal($event, event) {
    if (this.userCanEditCards || this.userCanEditCards === undefined) {
      const dialogRef = this.dialog.open(this.eventModal, {
        data: {
          event: cloneDeep(event),
          isReadOnly: this.isReadOnly || event.readOnly,
          notifications: await this.notificationsService.getAll(),
          isUtc: this.isUtc || event.readOnly,
          airportsCode: this.airportsCode,
        },
      });
      dialogRef
        .afterClosed()
        .toPromise()
        .then(updatedEvent => {
          if (updatedEvent && !isEqual(updatedEvent, this.event)) {
            this.updatedEvent.emit(updatedEvent);
          }
        });
    }
  }

  public getLastDisplayTime(): Date {
    return this.localStorage.getOccDashboardLastDisplayTime() || new Date();
  }

  public setLastDisplayTime(): void {
    this.localStorage.setOccDashboardLastDisplayTime(new Date());
  }
}
