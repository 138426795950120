import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { FormIoCredentials, OptionsService } from 'src/app/common/services/options.service';
import { RolesService } from '../../../common/services/roles.service';
import { OclTask } from '../../models/ocl-task';
import { OclHistoryLog } from '../../models/ocl-history-log.model';
import { OclTasksSelectors } from '../../store/ocl-tasks.selectors';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { HolManagersSelectors } from '../../../common/store/hol-managers.selectors';
import { CommonStoreManager } from '../../../common/store/common.store-manager';
import { HolUserFunction } from '../../../common/models/hol-user-function';

@Component({
  selector: 'app-ocl-task',
  templateUrl: './ocl-task.component.html',
  styleUrls: ['./ocl-task.component.scss'],
})
export class OclTaskComponent implements OnInit, OnDestroy {
  task: OclTask;
  formIoCredentials: FormIoCredentials;
  taskHistoryLogs: OclHistoryLog[] = [];
  functionUser: HolUserFunction[] = [];
  functionTitle = '';
  showManagersPanel: boolean;
  private tasksSub: Subscription;
  isHolder: boolean;

  constructor(
    @Inject('$stateParams') private $stateParams,
    @Inject('history') private history: OclHistoryLog[],
    private optionsService: OptionsService,
    private rolesService: RolesService,
    private tasksSelectors: OclTasksSelectors,
    public moduleConfig: ModuleConfigService,
    @Inject('isReadOnly') public isReadOnly,
    private managersSelectors: HolManagersSelectors,
    private commonStoreManager: CommonStoreManager,
  ) {
    this.taskHistoryLogs = history;
  }

  ngOnInit() {
    this.formIoCredentials = this.optionsService.getFormIoCredentials();
    this.tasksSub = combineLatest([
      this.tasksSelectors.allTasks,
      this.managersSelectors.managersFunctions,
      this.commonStoreManager.currentUser,
    ]).subscribe(([tasks, managerFunctions, currentUser]) => {
      this.task = tasks.find(t => t.objectId === this.$stateParams.taskId);
      if (this.task) {
        const managerFunction = managerFunctions.find(mf => mf.functionId === this.task.functionId);
        if (managerFunction) {
          this.functionTitle = managerFunction.title;
          this.isHolder =
            (managerFunction &&
              !!managerFunction.companies.find(c => {
                return c.holder && c.holder.user.userId === currentUser.userId;
              })) ||
            !!managerFunctions.find(
              mf => mf.isManagerFunction && !!mf.companies.find(c => c.holder && c.holder.user.userId === currentUser.userId),
            );
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.tasksSub.unsubscribe();
  }

  toggleManagerPanel() {
    this.showManagersPanel = !this.showManagersPanel;
  }

  closeExternalPanels() {
    if (this.showManagersPanel) {
      this.showManagersPanel = false;
    }
  }
}
