import { OclTagsStoreManager } from '../../../ocl/store/tags/ocl-tags.store-manager';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewRef,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HolTag } from '../../models/hol-tag';
import { TagChangeService } from '../../services/tag-change.service';
import { BaseComponent } from '../base/base.component';
import { TagsService } from '../../services/tags.service';
import { ModuleConfigService } from '../../services/module-config/module-config.service';
import { HelperService } from '../../services/helper.service';
import { cloneDeep, find, orderBy, remove } from 'lodash';
import { take } from 'rxjs/operators';
import { EclCrisisStoreManager } from '../../../ecl/store/crisis/crisis.store-manager';
import { CrisisStoreManager } from '../../../erp/store/crisis/crisis.store-manager';
import { RolesService } from '../../services/roles.service';
import { MccCabinTagService } from '../../../mcc/services/mcc-cabin-tag.service';
import { Observable, Subscription, Subject } from 'rxjs';
import { ErpTagsService } from 'src/app/erp/services/erp-tag.service';
import { EclTagsService } from '../../../ecl/services/ecl-tags-service/ecl-tags.service';

@Component({
  selector: 'app-tag-filter',
  templateUrl: './tag-filter.component.html',
  styleUrls: ['./tag-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagFilterComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public showOnlyDefaultTags = false;
  @Input()
  public readOnly: boolean;
  @Input()
  public isFilterMode: boolean = true;
  @Input()
  public addForm? = false;
  @Input()
  public showTitle? = true;
  @Input()
  public defaultTags: HolTag[] = [];
  @Input()
  public viewOnly: boolean;
  @Input()
  public canAddTag = false;
  @Output()
  public selectedTagsChange = new EventEmitter();
  @Output()
  public updateTags = new EventEmitter();
  public tags: HolTag[] = [];
  public tagForm: FormGroup;
  public showInput = false;
  public selectedCrisis?;
  public isLoading = false;
  public isSelectorVisible = false; //TODO mettre en false
  public eclTagsIsLoading = false;
  isLoading$ = new Observable(observer => {
    observer.next(this.isLoading);
  });
  subscription: Subscription;
  public isOverflowing = false;
  public isExpanded = true;
  public isPushing = false;
  @Input()
  public isCabin? = false;

  isCreateMode = false; // To toggle between tag selection and creation
  newTagName = '';
  newTagNameRaw = '';

  tagTypes = Object.values(TagTypes);
  selectedTagType = TagTypes.status;

  enumTagTypes = TagTypes;
  tagTypesPrefix = Object.values(TagTypesPrefix);
  creatingTag = false;
  selectedColor: string = TagTypeColor.status;

  protected readonly length = length;
  protected ParseECLTag = Parse.Object.extend('ECLTag');
  protected ParseCrisis = Parse.Object.extend('ECLCrisis');

  private liveQuerySubscription: Parse.LiveQuerySubscription;
  private initQuery = false;
  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    public moduleConfig: ModuleConfigService,
    private tagsService: TagsService,
    private tagChangeService: TagChangeService,
    @Inject('$rootScope') public $rootScope,
    private tagsStoreManager: OclTagsStoreManager,
    private eclCrisisStoreManager: EclCrisisStoreManager,
    private erpCrisisStoreManager: CrisisStoreManager,
    private eclTagService: EclTagsService,
    private erpTagService: ErpTagsService,
    private moduleConfigService: ModuleConfigService,
    private roleService: RolesService,
    private helperService: HelperService,
    private mccTagsService: MccCabinTagService,
    private cd: ChangeDetectorRef,
  ) {
    super();

    this.isLoading = true;
    if (this.viewOnly) {
      this.tags = this.selectedTags;
      this.selectedTags = this.selectedTags || [];
    } else if (this.defaultTags.length > 0) {
      this.tags = this.defaultTags;
      this.selectedTags = this.defaultTags;
      if (this.canAddTag && this.tags.length > 0) {
        this.isCreateMode = true;
      }
    } else {
      this.tagsStoreManager.tagsState
        .pipe(take(1))
        .toPromise()
        .then((tags: HolTag[]) => {
          this.roleService.$companiesRolesFilter.subscribe(v => {
            if (this.moduleConfigService.config.moduleName !== 'ecl') {
              const tagFilterByAclFilter = tags.filter(t => this.helperService.allowedACL(v, t.acl));
              this.tags = orderBy(tagFilterByAclFilter, ['name'], ['asc']);
              if (this.isFilterMode) {
                this.selectedTags = this.tagChangeService.getCurrentTagFilter();
                this.tagChangeService.setCurrentTagFilter(this.selectedTags);
              }
            }
          });
        });
    }
  }

  public _selectedTags: HolTag[] = [];

  @Input()
  get selectedTags() {
    return this._selectedTags || [];
  }

  set selectedTags(value: HolTag[]) {
    this._selectedTags = value;
    this.selectedTagsChange.emit(this._selectedTags);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.moduleConfigService.config.moduleName === 'mcc' && this.isCabin) {
      this.isLoading = true;
      this.mccTagsService.getAll().then(tags => {
        this.tags = tags;
        this.isLoading = false;
        this.cd.detectChanges();
      });
    }
    if (this.moduleConfigService.config.moduleName === 'ecl' && this.readOnly === true) {
      this.tags = this.selectedTags;
      this.cd.detectChanges();
    } else if (this.moduleConfigService.config.moduleName === 'ecl') {
      this.eclCrisisStoreManager.$eclSelectedCrisis.subscribe(async c => {
        this.eclTagsIsLoading = true;

        if (c) {
          this.selectedCrisis = c;
          //  await new Promise(resolve => setTimeout(resolve, 500)); // 2000ms = 2s
          const tags = await this.eclTagService.getAllTagByCrisis(c, true);
          this.tags = tags;
          this.cd.detectChanges();
        }
        this.eclTagsIsLoading = false;
        this.cd.detectChanges();
      });
    } else if (this.moduleConfigService.config.moduleName === 'erp') {
      if (this.defaultTags !== undefined && this.defaultTags.length > 0) {
        this.tags = this.defaultTags;
        this.selectedTags = this.defaultTags;
        this.viewOnly = true;
        this.readOnly = true;
        this.cd.detectChanges();

        this.selectedTagsChange.emit(this.selectedTags);
        this.updateTags.emit(true);
      } else {
        this.erpCrisisStoreManager.crisisErpState.subscribe(c => {
          this.isLoading = true;
          this.erpTagService.getAll().then(tags => {
            this.tags = tags;
            this.isLoading = false;
            setTimeout(() => {
              if (this.cd !== null && this.cd !== undefined && !(this.cd as ViewRef).destroyed) {
                this.cd.detectChanges();
              }
            }, 250);
          });
          if (c) {
            this.selectedCrisis = c;
            setTimeout(() => {
              if (this.cd !== null && this.cd !== undefined && !(this.cd as ViewRef).destroyed) {
                this.cd.detectChanges();
              }
            }, 250);
          }
          this.isLoading = false;
        });
      }
    } else {
      this.isLoading = false;
    }

    this.subscription = this.isLoading$.subscribe(() => {
      // this.checkListHeight();
      this.cd.detectChanges();

      if (this.canAddTag && this.tags.length > 0) {
        this.isCreateMode = true;
        this.cd.detectChanges();
      }
    });

    if (this.moduleConfigService.config.moduleName === 'ecl') {
      if (!this.initQuery) {
        this.initLiveQueryEcl().then();
      }
    }
    //document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  async initLiveQueryEcl() {
    if (this.moduleConfigService.config.moduleName !== 'ecl') {
      return;
    }
    if (this.selectedCrisis && this.selectedCrisis.objectId) {
      const myQuery = new Parse.Query(this.ParseECLTag);
      const crisisPar = new this.ParseCrisis({ id: this.selectedCrisis.objectId });
      myQuery.equalTo('crisis', crisisPar);

      this.liveQuerySubscription = await myQuery.subscribe();

      this.liveQuerySubscription.on('create', object => {
        const newEclTag = new HolTag(object);
        if (this.tags.includes(newEclTag)) {
          return;
        }
        this.tags.push(newEclTag);

        this.cd.detectChanges();
      });

      this.liveQuerySubscription.on('close', () => {
        this.initQuery = false;
      });

      this.liveQuerySubscription.on('open', () => {
        this.initQuery = true;
      });
    }
    this.initQuery = true;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.destroy$) {
      this.destroy$.next();
      this.destroy$.complete();
    }

    if (this.subscription) this.subscription.unsubscribe();
    if (this.liveQuerySubscription) this.liveQuerySubscription.unsubscribe();
    //   document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }

  public showUntoggleAll(): any {
    return !this.readOnly && this.selectedTags.length > 0;
  }

  public toggleTag($event: MouseEvent, tag: HolTag): void {
    if (!this.readOnly && !this.viewOnly) {
      const removed = remove(this.selectedTags, { objectId: tag.objectId });
      if (removed.length === 0) {
        this.selectedTags.push(tag);
      }
      if (this.isFilterMode) {
        this.tagChangeService.setCurrentTagFilter(this.selectedTags);
      }
      this.selectedTagsChange.emit(this.selectedTags);
      this.updateTags.emit(true);
    }
    $event.stopPropagation();
  }

  public onTagSelected($event: MouseEvent, tag: HolTag): void {
    $event.stopPropagation();
    const removed = remove(this.selectedTags, { objectId: tag.objectId });
    if (removed.length === 0) {
      this.selectedTags.push(tag);
    }
    if (this.isFilterMode) {
      this.tagChangeService.setCurrentTagFilter(this.selectedTags);
    }
    this.selectedTagsChange.emit(this.selectedTags);
    this.updateTags.emit(true);
  }

  public setAllTag(): void {
    this.selectedTags = cloneDeep(this.tags);
    if (this.isFilterMode) {
      this.tagChangeService.setCurrentTagFilter(this.selectedTags);
    }
  }

  public unsetAllTag($event: MouseEvent): void {
    $event.stopPropagation();
    this.selectedTags = [];
    if (this.isFilterMode) {
      this.tagChangeService.setCurrentTagFilter(this.selectedTags);
    }
  }

  public isSelectedTag(tag): any {
    return !!find(this.selectedTags, { objectId: tag.objectId });
  }

  public mixColors(color1: string, color2: string, weight: number): any {
    return HelperService.mixColors(color1, color2, weight);
  }

  public cancelAddTag(): void {
    this.tagForm = null;
    this.showInput = false;
  }

  processTagName(): void {
    this.newTagName = this.newTagNameRaw.trim();
  }

  public buttonStyle(tag: HolTag): any {
    return {
      'background-color': this.isSelectedTag(tag) ? tag.color : this.mixColors('#FFFFFF', tag.color, 90),
      'border-color': tag.color,
      color: this.isSelectedTag(tag) ? 'white' : tag.color,
    };
  }

  public buttonStylePreview(isSelected: boolean): any {
    return {
      'background-color': isSelected ? this.selectedColor : this.mixColors('#FFFFFF', this.selectedColor, 90),
      'border-color': this.selectedColor,
      color: isSelected ? 'white' : this.selectedColor,
    };
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  onClickExpand() {
    this.isExpanded = !this.isExpanded;
  }

  // I don't know why it works in some modules, but not in others.
  @Input() required: boolean = false;

  toggleSelector() {
    this.isSelectorVisible = !this.isSelectorVisible;
    if (!this.isSelectorVisible) {
      this.isCreateMode = false;
    }
  }

  toggleMode(): void {
    this.isCreateMode = !this.isCreateMode;
  }

  disableCreate(): boolean {
    return !this.newTagNameRaw || !this.newTagName || !this.selectedColor || this.creatingTag || this.uniqueValidatorNew();
  }

  createNewTag(): void {
    this.creatingTag = true;

    const realName = `${this.getTagPrefix(this.selectedTagType)}${this.newTagName}`;
    this.tagsService.create(this.selectedColor, realName, this.selectedCrisis, false).then(async newTag => {
      this.tagsStoreManager.addOneTag(newTag);

      this.tagForm = null;
      this.showInput = false;
      this.tags = orderBy(this.tags, ['name'], ['asc']);
      this.newTagNameRaw = '';
      this.newTagName = '';
      this.selectedColor = TagTypeColor.status;
      this.creatingTag = false;
      this.toggleMode();
    });
  }

  selectColor(color: string) {
    this.selectedColor = color;
  }

  tagTypeChange() {
    this.selectedColor = this.getTagColorDefault(this.selectedTagType);
  }

  getTagPrefix(tagType: TagTypes): string {
    switch (tagType) {
      case TagTypes.status:
        return TagTypesPrefix.status;
      case TagTypes.department:
        return TagTypesPrefix.department;
      case TagTypes.stakeholder:
        return TagTypesPrefix.stakeholder;
      case TagTypes.remaining:
        return TagTypesPrefix.remaining;
      default:
        return '';
    }
  }

  getTagColorDefault(tagType: TagTypes): string {
    switch (tagType) {
      case TagTypes.status:
        return TagTypeColor.status;
      case TagTypes.department:
        return TagTypeColor.department;
      case TagTypes.stakeholder:
        return TagTypeColor.stakeholder;
      case TagTypes.remaining:
        return TagTypeColor.remaining;
      default:
        return '';
    }
  }

  isVisibleButton(): boolean {
    return !(this.tags.length == 0 && !this.canAddTag);
  }

  private stripPrefix(tagName: string): string {
    const prefixes = Object.values(TagTypesPrefix);

    for (const prefix of prefixes) {
      if (tagName.startsWith(prefix)) {
        return tagName.substring(prefix.length);
      }
    }

    return tagName;
  }

  private uniqueValidatorNew() {
    const realName = this.newTagNameRaw.toLowerCase();
    return this.tags
      .map(tag => this.stripPrefix(tag.name).toLowerCase())
      .some(strippedValue => {
        return strippedValue === realName;
      });
  }
}

enum TagTypes {
  status = 'STATUS',
  department = 'DEPARTEMENT',
  stakeholder = 'STAKEHOLDER',
  remaining = 'REMAINING',
}

enum TagTypesPrefix {
  status = '#',
  department = '@',
  stakeholder = '§',
  remaining = '',
}

enum TagTypeColor {
  status = '#FF0000',
  department = '#009DFF',
  stakeholder = '#DB00C9',
  remaining = '#000000',
}
