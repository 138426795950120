<div class="hol-dialog-wrapper hol-modal-prompt">
  <mat-toolbar class="mat-toolbar">
    <div class="title" mat-dialog-title>
      <span>{{ 'ERP.TASKS.COMMENT_PLACEHOLDER' | translate }}</span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1" type="button">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <div class="content-grid">
      <mat-form-field class="hol-modal-prompt-textarea">
        <textarea
          [formControl]="text"
          matInput
          placeholder="{{ 'ERP.TASKS.COMMENT_PLACEHOLDER' | translate }}"
          required
          rows="3"
          type="text"
        ></textarea>
        <mat-error>{{ 'ERP.TASKS.COMMENT_REQUIRED_ERROR' | translate }}</mat-error>
        <mat-error *ngIf="text.invalid">{{ getErrorMessage() }}</mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="action-bar">
      <button (click)="cancel()" class="action action-refuse">
        <span class="material-symbols-outlined">cancel</span>
        <span>{{ 'COMMON.NO' | translate | uppercase }}</span>
      </button>
      <div class="spacer"></div>
      <button (click)="valid()" [disabled]="text.invalid" class="action action-validate">
        <span class="material-symbols-outlined">check</span>
        <span>{{ 'COMMON.SAVE' | translate | uppercase }}</span>
      </button>
    </div>
  </mat-dialog-actions>
</div>
