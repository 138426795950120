<div *ngIf="task && form" class="new-holis-theme">
  <div class="hol-content-box">
    <div class="hol-content-box__content">
      <form [formGroup]="form" class="hol-content-box__content__col">
        <!-- ACL -->
        <div class="hol-content-box__content__col__header">
          <button (click)="goBack()" [disabled]="isSaving" [ngClass]="{ 'rounded-icon-btn': phoneResolution }" class="back-link" mat-button>
            <i class="icon fa fa-angle-left"></i>
            <span *ngIf="!phoneResolution" class="back-link__text">{{ 'TASKS.BUTTONS.BACK' | translate }}</span>
          </button>
          <app-acl-selector
            (updateACLFromHand)="saveTask('UPDATE_TASK_ACL')"
            [alwaysOneSelected]="true"
            [formGroup]="form"
            [hidden]="countRoles <= 1"
            [limitTo]="aclLimit"
            [moduleName]="moduleConfig.config.moduleName"
            [object]="task"
            [readOnly]="!canChangeAcl(task) || task.readOnly"
            [startAllSelected]="true"
          ></app-acl-selector>
        </div>

        <ng-container *ngIf="phoneResolution; else taskDesktop">
          <mat-expansion-panel>
            <mat-expansion-panel-header class="hol-content-box__content__col__content--background-title" collapsedHeight="auto">
              <mat-panel-title class="hol-content-box__content__col__content">
                <div class="decimal-rounded-list">
                  <div [ngClass]="{ disabled: task.readOnly }" class="count">
                    <ng-container *ngTemplateOutlet="taskTitle"></ng-container>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="hol-content-box__content__col__content">
              <div class="hol-content-box__content__col__content--container">
                <ng-container *ngTemplateOutlet="taskDescriptionContent"></ng-container>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel (closed)="toggleView('showSection2')" (opened)="toggleView('showSection2')">
            <mat-expansion-panel-header class="hol-content-box__content__col__content--background-title" collapsedHeight="auto">
              <mat-panel-title class="hol-content-box__content__col__content">
                <div class="decimal-rounded-list">
                  <div [ngClass]="{ disabled: task.readOnly }" class="count count--2">
                    <ng-container *ngTemplateOutlet="taskAttachementsTitle"></ng-container>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="hol-content-box__content__col__content">
              <div class="hol-content-box__content__col__content--container">
                <ng-container *ngTemplateOutlet="taskAttachementsContent"></ng-container>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel (closed)="toggleView('showSection3')" (opened)="toggleView('showSection3')">
            <mat-expansion-panel-header class="hol-content-box__content__col__content--background-title" collapsedHeight="auto">
              <mat-panel-title class="hol-content-box__content__col__content">
                <div class="decimal-rounded-list">
                  <div [ngClass]="{ disabled: task.readOnly }" class="count count--3">
                    <ng-container *ngTemplateOutlet="taskTagsTitle"></ng-container>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="hol-content-box__content__col__content">
              <div class="hol-content-box__content__col__content--container">
                <ng-container *ngTemplateOutlet="taskTagsContent"></ng-container>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header class="hol-content-box__content__col__content--background-title" collapsedHeight="auto">
              <mat-panel-title class="hol-content-box__content__col__content">
                <div class="decimal-rounded-list">
                  <div [ngClass]="{ disabled: task.readOnly }" class="count count--4">
                    <ng-container *ngTemplateOutlet="taskValidationTitle"></ng-container>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="hol-content-box__content__col__content">
              <div class="hol-content-box__content__col__content--container">
                <ng-container *ngTemplateOutlet="taskValidationContent"></ng-container>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </form>
      <mat-divider vertical></mat-divider>

      <div *ngIf="phoneResolution; else dekstopTabs" class="hol-content-box__content__extra">
        <!-- Task history -->
        <mat-expansion-panel *ngIf="taskHistoryLogs.length > 0">
          <mat-expansion-panel-header class="hol-content-box__content__col__content--background-title">
            <mat-panel-title>
              {{ moduleConfig.config.translateKey + '.TASKS.HISTORY_LABEL' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngTemplateOutlet="historyContent"></ng-container>
        </mat-expansion-panel>

        <div *ngIf="phoneResolution && hasFormIo" class="warn-box">
          {{ moduleConfig.config.translateKey + '.TASKS.CANT_EDIT_MOBILE_WARNING' | translate }}
        </div>

        <!-- Task form.io -->
        <mat-expansion-panel *ngIf="!phoneResolution && hasFormIo">
          <mat-expansion-panel-header class="hol-content-box__content__col__content--background-title">
            <mat-panel-title>
              {{ moduleConfig.config.translateKey + '.TASKS.FORM_LABEL' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngTemplateOutlet="formioContent"></ng-container>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
</div>

<ng-template #taskDesktop>
  <div class="hol-content-box__content__col__content">
    <div class="hol-content-box__content__col__content--background-title">
      <ng-container *ngTemplateOutlet="taskTitle"></ng-container>
    </div>
    <div class="hol-content-box__content__col__content--container decimal-rounded-list">
      <div class="scroll-container">
        <!-- <ng-container *ngTemplateOutlet="taskcomment"></ng-container> -->
        <ng-container *ngTemplateOutlet="taskDescriptionBlock"></ng-container>
        <ng-container *ngTemplateOutlet="taskAttachementsBlock"></ng-container>
        <ng-container *ngTemplateOutlet="taskTagsBlock"></ng-container>
        <ng-container *ngTemplateOutlet="taskValidationBlock"></ng-container>
      </div>
    </div>
  </div>
</ng-template>
<!--
<ng-template #taskcomment>
  <div class="comment-container">
    <span class="comment-label" *ngIf="task.status === 'FROZEN'"
      >{{ moduleConfig.config.translateKey + '.TASKS.COMMENT_PLACEHOLDER' | translate
      }}<strong>:&nbsp;&nbsp;&nbsp;{{ task.comment }}</strong></span
    >
  </div>
</ng-template>
-->
<ng-template #taskTitle>
  <!-- Task title + warning not Holder -->
  <span class="title">{{ task?.taskTitle }}</span>
  <!--<div *ngIf="task.readOnly" class="hol-warn">{{ moduleConfig.config.translateKey + '.TASKS.IS_READ_ONLY' | translate }}</div>-->
</ng-template>

<ng-template #taskDescriptionBlock>
  <div [ngClass]="{ disabled: task.readOnly }" class="count">
    <ng-container *ngTemplateOutlet="taskDescriptionContent"></ng-container>
  </div>
</ng-template>

<ng-template #taskDescriptionContent>
  <!-- Task description -->
  <div *ngIf="task?.taskDescription" class="description-md">
    <ngx-md [data]="task?.taskDescription"></ngx-md>
  </div>
</ng-template>

<ng-template #taskAttachementsBlock>
  <div [ngClass]="{ disabled: task.readOnly }" class="count">
    <ng-container *ngTemplateOutlet="taskAttachementsContent"></ng-container>
  </div>
</ng-template>

<ng-template #taskAttachementsTitle>
  <div class="titleWrapper">
    <span [ngClass]="{ 'warn-Box': isOutputDataLabelDefined && task.attachments.isEmpty() }" class="title" id="attachmentSection">
      {{ moduleConfig.config.translateKey + '.TASKS.ADD_ATTACHEMENTS' | translate }}
    </span>
    <span *ngIf="!phoneResolution">
      <i
        (click)="toggleView('showSection2')"
        [ngClass]="{ 'glyphicon-menu-down': showSection2 === false, 'glyphicon-menu-up': showSection2 === true }"
        class="glyphicon"
      ></i>
    </span>
  </div>
</ng-template>

<ng-template #taskAttachementsContent>
  <!-- Task attachments -->
  <ng-container *ngIf="!phoneResolution">
    <ng-container *ngTemplateOutlet="taskAttachementsTitle"></ng-container>
  </ng-container>
  <div [ngClass]="{ open: showSection2 == true }" class="contentWrapper">
    <span
      *ngIf="erpTask && erpTask.outputDataHint"
      [ngClass]="[isOutputDataLabelDefined && erpTask.attachments.isEmpty() ? 'mandatory-attachment' : 'non-mandatory-attachment']"
      class="hol-info info-label"
    >
      {{ erpTask.outputDataHint }}
    </span>
    <ng-container *ngIf="erpTask">
      <ng-container>
        <div class="hol-column">
          <div class="toggle-lable-modal-default-dashboard label-box">
            <span>{{ isOutputDataLabelDefined ? erpTask.outputDataLabel : erpTask.outputTitle }}</span>
          </div>
        </div>
        <span class="hol-info info-label info-attachment">
          {{ moduleConfig.config.translateKey + '.TASKS.TASK_INFO_CHECKLIST_LABEL' | translate }}
        </span>
      </ng-container>
    </ng-container>
    <span
      class="hol-info info-label"
      *ngIf="task && !erpTask"
      [ngClass]="{
        'mandatory-attachment': task.outputDataLabel !== null && task.outputDataLabel !== undefined && task.attachments.isEmpty(),
        'non-mandatory-attachment': !task?.outputDataLabel || !task.attachments.isEmpty()
      }"
    >
      {{ task?.outputDataHint }}
    </span>
    <ng-container *ngIf="task && !erpTask">
      <div class="hol-column">
        <div class="toggle-lable-modal-default-dashboard label-box">
          <span>{{ task.outputDataLabel ? task.outputDataLabel : task.outputTitle }}</span>
        </div>
      </div>
      <span class="hol-info info-label info-attachment">
        {{ moduleConfig.config.translateKey + '.TASKS.TASK_INFO_CHECKLIST_LABEL' | translate }}
      </span>
    </ng-container>

    <div class="hol-row-task-details">
      <app-section-with-title [icon]="'demography'" [titleKey]="'Note'">
        <app-note-markdown
          (isNoteSave)="isNoteSave($event)"
          [attachment]="task.attachments.note"
          [attachments]="task.attachments"
          [context]="context"
          [noteFile]="task.attachments.noteFile"
          [readOnly]="isReadOnly"
          (onAttachmentUpdated)="updateAttachment()"
        ></app-note-markdown>
      </app-section-with-title>
      <app-section-with-title [icon]="'attachment'" [titleKey]="'COMMON.MODALS.BUTTONS.ATTACHMENTS' | translate">
        <app-file-and-image-upload
          (onAttachmentUpdated)="updateAttachment()"
          [attachments]="task.attachments"
          [context]="context"
          [disabled]="task.readOnly || isReadOnly"
          [hideNote]="true"
          [multipleFileEnabled]="moduleConfig.config.multipleFileEnabled"
        >
        </app-file-and-image-upload>
      </app-section-with-title>
    </div>
    <input [formControl]="attachmentControl" [value]="task.attachments" type="hidden" />
    <mat-error *ngIf="attachmentControl.invalid && attachmentControl.dirty">
      <strong>{{ 'ERP.TASKS.ATTACHMENT_REQUIRED_ERROR' | translate }}</strong>
    </mat-error>
    <div *ngIf="erpTask" class="checkbox">
      <app-erp-task-visibility
        (updateCurrentVisibility)="changeVisibility($event)"
        (updateFrozenByErd)="changeFrozenByErd($event)"
        [currentVisibility]="currentVisibility"
        [disableFrozenByErd]="!isInErdTeam"
        [disabledVisiblitySelector]="(task?.visibleBy && !isInErdTeam) || task.frozenByErd || task.readOnly"
        [frozenByErd]="task.frozenByErd"
        [isInErdTeam]="isInErdTeam"
        [showFrozenByErd]="!task?.visibleBy && isInErdTeam"
      >
      </app-erp-task-visibility>
    </div>
  </div>
</ng-template>

<ng-template #taskTagsBlock>
  <div [ngClass]="{ disabled: task.readOnly }" class="count">
    <ng-container *ngTemplateOutlet="taskTagsContent"></ng-container>
  </div>
</ng-template>

<ng-template #taskTagsTitle>
  <div class="titleWrapper">
    <span class="title">
      {{ moduleConfig.config.translateKey + '.TASKS.ORGANISATION_TEXT' | translate }}
    </span>
    <i
      (click)="toggleView('showSection3')"
      *ngIf="!phoneResolution"
      [ngClass]="{ 'glyphicon-menu-down': showSection3 === false, 'glyphicon-menu-up': showSection3 === true }"
      class="glyphicon"
    ></i>
  </div>
</ng-template>

<ng-template #taskTagsContent>
  <ng-container *ngIf="!phoneResolution">
    <ng-container *ngTemplateOutlet="taskTagsTitle"></ng-container>
  </ng-container>
  <div [ngClass]="{ open: showSection3 == true }" class="contentWrapper">
    <div class="hol-row">
      <!-- Task next-info -->
      <div>
        <label>{{ moduleConfig.config.translateKey + '.TASKS.NEXT_INFO_TITLE' | translate }}</label>
        <app-hol-next-info
          (saveNextInfo)="saveNextInfo($event)"
          [isReadOnly]="task.readOnly || isReadOnly"
          [nextInfo]="{ nextInfoTime: task.nextInfoTime, nextInfoDone: task.nextInfoDone }"
        >
        </app-hol-next-info>
      </div>

      <!-- Task notif -->
      <div>
        <label>{{ moduleConfig.config.translateKey + '.TASKS.NOTIFICATIONS_TITLE' | translate }}</label>
        <div class="checkbox">
          <app-notification-table
            (saveNotifications)="saveNotifications($event)"
            (saveNotifyFunction)="saveNotifyFunction($event)"
            [acl]="task.acl"
            [color]="'accent'"
            [notifications]="notifications"
            [notifyFunction]="{
              sendByMail: notifyFunction.sendByMail,
              sendBySms: notifyFunction.sendBySms,
              disabled: task.readOnly
            }"
          ></app-notification-table>
        </div>
      </div>
    </div>
    <!-- Task tags -->
    <div>
      <label>
        {{ moduleConfig.config.translateKey + '.TASKS.TAGS_TITLE' | translate }}
      </label>
      <app-tag-selector
        (updateTags)="task.defaultTags.length == 0 && saveTask('UPDATE_TASK_TAGS')"
        [(selectedTags)]="tags"
        [defaultTags]="task.defaultTags"
        [readOnly]="task.readOnly"
        [showOnlyDefaultTags]="task.defaultTags.length > 0"
        [showTitle]="false"
        class="tag-selector"
      ></app-tag-selector>
    </div>
  </div>
</ng-template>

<ng-template #taskValidationBlock>
  <div [ngClass]="{ disabled: task.readOnly }" class="count">
    <ng-container *ngTemplateOutlet="taskValidationContent"></ng-container>
  </div>
</ng-template>

<ng-template #taskValidationTitle>
  <span class="title">
    {{ moduleConfig.config.translateKey + '.TASKS.CONFIRM_RESULT' | translate }}
  </span>
</ng-template>

<ng-template #taskValidationContent>
  <ng-container *ngIf="!phoneResolution">
    <ng-container *ngTemplateOutlet="taskValidationTitle"></ng-container>
  </ng-container>

  <p [ngClass]="{ disabled: task.readOnly }" class="hol-info info-label">
    <label style="margin-bottom: 0">{{ moduleConfig.config.translateKey + '.TASKS.TASK_EXPECTED_RESULT' | translate }} :&nbsp;</label>
    <br />
    {{ task?.outputTitle }}
    <br />
  </p>

  <div *ngIf="task.readOnly || task.status === 'FROZEN'" [ngClass]="{ disabled: task.readOnly, frozen: task.status === 'FROZEN' }">
    <!-- Task validation comment -->
    <div class="hol-column">
      <br />
      <span *ngIf="statusControl.value === 'FROZEN' && !task.status" class="info-label">{{
        moduleConfig.config.translateKey + '.TASKS.TEXT_NO_CONFIRM_RESULT' | translate
      }}</span>
      <mat-form-field appearance="outline">
        <mat-label>{{ moduleConfig.config.translateKey + '.TASKS.COMMENT_PLACEHOLDER' | translate }}</mat-label>
        <textarea
          [formControl]="commentControl"
          [required]="statusControl.value === 'FROZEN'"
          matInput
          name="commentConfirm"
          type="text"
        ></textarea>
        <mat-error>{{ moduleConfig.config.translateKey + '.TASKS.COMMENT_REQUIRED_ERROR' | translate }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <!--
  <div *ngIf="isTaskStatusChanged" [ngClass]="isVisibleNotifInfo ? 'alert-notify' : 'hide-notify'">
    <span (click)="closeShowNotifyInfo()" class="closebtn-notify">&times;</span>
    <strong>{{ 'Info! ' }}</strong
    >{{ moduleConfig.config.translateKey + '.TASKS.NOTIFY_USERS' | translate : { fnc: functionTitle } }}
  </div>
  -->

  <br />
  <mat-button-toggle-group
    (valueChange)="updateFormValidity()"
    *ngIf="hackShowToggleButton && !isReadOnly"
    [disabled]="!!task.status"
    [formControl]="statusControl"
    [ngClass]="{ 'result-toggle-buttons ': true, disabled: !!task.status }"
    aria-label="Task status"
  >
    <mat-button-toggle (click)="!task.status && updateStatus()" class="mat-success" value="DONE">
      <span class="hol-icon-check-circle"></span>
      {{ moduleConfig.config.translateKey + '.TASKS.CONFIRM_RESULT_BTN' | translate | uppercase }}
    </mat-button-toggle>
    <mat-button-toggle (click)="!task.status && openModalStatutNoConfirm()" class="mat-warn" value="FROZEN">
      <span class="hol-icon-cancel"></span>
      {{ moduleConfig.config.translateKey + '.TASKS.NO_CONFIRM_RESULT_BTN' | translate | uppercase }}
    </mat-button-toggle>
    <mat-button-toggle (click)="!task.status && updateStatus()" *ngIf="task.isConditionalTask" class="mat-basic" value="NOT_APPLICABLE">
      {{ moduleConfig.config.translateKey + '.TASKS.NOT_APPLICABLE_RESULT_BTN' | translate | uppercase }}
    </mat-button-toggle>
  </mat-button-toggle-group>
  <button
    (click)="saveTask('UPDATE_TASK_STATUS', 'TODO')"
    *ngIf="task.status"
    [disabled]="task.readOnly || isSaving"
    color="primary"
    mat-flat-button
  >
    {{ moduleConfig.config.translateKey + '.TASKS.BACK_CONFIRM_RESULT_BTN' | translate }}
  </button>
</ng-template>

<ng-template #historyContent>
  <app-task-history
    *ngFor="let taskHistoryLog of taskHistoryLogs; let index = index; trackBy: trackByFunction"
    [taskHistoryLog]="taskHistoryLog"
  ></app-task-history>
</ng-template>

<ng-template #formioContent>
  <app-task-form-io [formIoCredentials]="formIoCredentials" [isHolder]="isHolder" [task]="task"></app-task-form-io>
</ng-template>

<ng-template #dekstopTabs>
  <div class="hol-content-box__content__col">
    <div class="hol-content-box__content__col__header">
      &nbsp;
      <div *ngIf="hasFormIo" class="button-group">
        <button (click)="openFormModal()">{{ moduleConfig.config.translateKey + '.TASKS.FORM_LABEL' | translate }}</button>
        <button (click)="openFormLink()" *ngIf="task.attachments.isEmpty() || task.attachments.files[0].url">Consulter</button>
      </div>
    </div>
    <div class="hol-content-box__content__col__content tabs">
      <div class="hol-content-box__content__col__content--background-title">
        {{ moduleConfig.config.translateKey + '.TASKS.HISTORY_LABEL' | translate }}
      </div>
      <ng-container *ngIf="taskHistoryLogs.length > 0">
        <div class="hol-content-box__content__col__content--container">
          <div class="scroll-container">
            <app-task-history
              *ngFor="let taskHistoryLog of taskHistoryLogs; let index = index; trackBy: trackByFunction"
              [taskHistoryLog]="taskHistoryLog"
            ></app-task-history>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<!-- delete? -->
<ng-template #editLabel>
  <form [formGroup]="formLabelInput" class="hol-dialog-wrapper" style="position: relative">
    <mat-toolbar>
      <div mat-dialog-title>
        {{ 'Modifier Etiquette' }}
      </div>
      <button (click)="cancelLabelEdit()" mat-dialog-close mat-icon-button tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
    <mat-dialog-content>
      <div *ngIf="context.module === 'erp' && context.category === 'CRISIS_TASK'" class="hol-column">
        <label>{{ moduleConfig.config.translateKey + '.TASKS.IS_NOT_ON_DASHBOARD_DEFAULT_SENTENCE' | translate }}</label>
        <mat-form-field *ngIf="erpTask.defaultIsOnDashboard" appearance="outline" class="attachement-name">
          <input
            [disabled]="true"
            [placeholder]="erpTask.outputDataLabel || moduleConfig.config.translateKey + '.TASKS.OUTPUT_DATA_LABEL_PLACEHOLDER' | translate"
            formControlName="title"
            matInput
            name="outputDataLabel"
            required="required"
            type="text"
          />
        </mat-form-field>
        <mat-form-field *ngIf="!erpTask.defaultIsOnDashboard" appearance="outline">
          <input
            [placeholder]="moduleConfig.config.translateKey + '.TASKS.OUTPUT_DATA_LABEL_PLACEHOLDER' | translate"
            formControlName="title"
            matInput
            maxlength="70"
            name="customOutputDataLabel"
            type="text"
          />
          <mat-hint>{{ moduleConfig.config.translateKey + '.TASKS.IS_NOT_ON_DASHBOARD_DEFAULT_HINT' | translate }}</mat-hint>
        </mat-form-field>
        <!-- Nuevo botón de confirmar -->
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button (click)="saveLabelEdit()" [disabled]="formLabelInput.invalid" class="right" color="primary" mat-raised-button>
        Confirme
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
