<div class="section section-title">
  <div class="icon">
    <img *ngIf="customImgUrl; else displayIcon" alt="icon-interne" src="/assets/images/icon_paperplan.svg" />
    <ng-template #displayIcon>
      <span class="material-symbols-outlined iconsize">{{ icon }}</span>
    </ng-template>
  </div>
  <div class="title">{{ titleKey | translate }}</div>
  <div class="empty">&nbsp;</div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
