import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { AppState } from '../../store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RolesService } from '../../common/services/roles.service';
import { CommonStoreManager } from '../../common/store/common.store-manager';
import { HolFunction } from '../../common/models/hol-function';
import { OclTask } from '../models/ocl-task';
import { OclEvent } from '../models/ocl-event.model';
import { intersection } from 'lodash';
import { HolOptionsService } from '../../common/services/hol-options.service';
import { ModuleConfigService } from '../../common/services/module-config/module-config.service';

export interface OclChecklistEvent {
  event: OclEvent;
  tasks: OclTask[];
  fromModule: string;
}
export interface OclChecklistFunction {
  func: HolFunction;
  events: OclChecklistEvent[];
}

@Injectable({
  providedIn: 'root',
})
export class OclTasksSelectors {
  protected constructor(
    protected store: Store<AppState>,
    protected rolesService: RolesService,
    protected commonStoreManager: CommonStoreManager,
    protected holOptionsService: HolOptionsService,
    protected moduleConfig: ModuleConfigService,
  ) {}

  get eventChecklists(): Observable<OclChecklistFunction[]> {
    return combineLatest([
      this.store.select('common', 'holUserFunctions'),
      this.store.select('common', 'holFunctions'),
      this.commonStoreManager.currentUser,
      this.store.select('ocl', 'tasks'),
      this.rolesService.$companiesRolesFilter,
    ]).pipe(
      map(([userFunctions, functions, user, tasks]) => {
        const managerFunctions = this.holOptionsService.getFunctionsIdsForManagerByModule(this.moduleConfig.config.moduleName);
        tasks = this.rolesService.filterFromCompanyRoles(tasks);
        const currentUserFunctionsIds = this.rolesService
          .filterFromCompanyRoles(userFunctions)
          .filter(uf => uf.userId === user.userId)
          .map(uf => uf.functionId);

        // si pas dans une fonction manager, on filtre les tasks des functions du currentUser
        if (!intersection(managerFunctions, currentUserFunctionsIds).length) {
          tasks = tasks.filter(t => currentUserFunctionsIds.includes(t.functionId));
        }

        const checklists: OclChecklistFunction[] = [];
        tasks.forEach(t => {
          const func = functions.find(f => f.functionId === t.functionId);
          if (func) {
            let cl = checklists.find(c => c.func.functionId === t.functionId);
            if (!cl) {
              cl = {
                func,
                events: [],
              };
              checklists.push(cl);
            }
            let event = cl.events.find(e => {
              return (
                (t.event && e.event && e.event.objectId === t.event.objectId) || (!t.event && !e.event && t.fromModule === e.fromModule)
              );
            });
            if (!event) {
              event = { event: t.event, fromModule: t.fromModule, tasks: [] };
              cl.events.push(event);
            }
            event.tasks.push(t);
            event.tasks.sort((a, b) => a.order - b.order);
          } else {
            console.warn(t.functionId + '  not found');
          }
        });
        return checklists;
      }),
    );
  }

  get allTasks(): Observable<OclTask[]> {
    return this.store.select('ocl', 'tasks');
  }
}
