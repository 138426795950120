import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { OptionsService } from 'src/app/common/services/options.service';
import * as moment from 'moment';
import { Moment } from 'moment/moment.d';

import { nextInfoType } from '../../components/hol-next-info/hol-next-info.component';
import { HolAttachments } from '../../models/hol-attachments.model';
import { HolNotification } from '../../models/hol-notification.model';
import { HolOptions } from '../../models/hol-options';
import { HolOptionsService } from '../../services/hol-options.service';

@Component({
  selector: 'app-add-next-info-modal',
  templateUrl: './add-next-info-modal.component.html',
  styleUrls: ['./add-next-info-modal.component.scss'],
})
export class AddNextInfoModalComponent implements OnInit {
  attachments: HolAttachments = new HolAttachments();
  notifications: HolNotification[];
  title: string;
  nextInfoLimit: number;
  public context: {
    module: string;
    category: string;
    htmlTitle: string;
    htmlScenario?: string;
    htmlDate;
    htmlNextInfo?;
    htmlTemplate: string;
  };
  formGroup: FormGroup;
  defaultNextInfoDelay: number;
  isUtc: boolean;
  pickerOpenedSub: Subscription;
  nextInfoTime;
  acl: Parse.ACL;
  public noteSaved = true;

  public holOptions: HolOptions;
  public isValidNextInfoDate: boolean = true;

  @ViewChild('picker', { static: false }) picker: MatDatepicker<Moment>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private optionsService: OptionsService,
    public dialogRef: MatDialogRef<AddNextInfoModalComponent>,
    public holOptionsService: HolOptionsService,
  ) {
    this.title = data.title;
    this.context = data.context;
    this.notifications = data.notifications || {};
    this.defaultNextInfoDelay = data.defaultNextInfoDelay;
    this.isUtc = data.isUtc;
    this.acl = data.acl;

    if (data.defaultNextInfoDelay) {
      if (data.isUtc) {
        this.nextInfoTime = moment().utc().add(data.defaultNextInfoDelay, 'minutes').toDate();
      } else {
        this.nextInfoTime = moment().add(data.defaultNextInfoDelay, 'minutes').toDate();
      }
    } else {
      if (data.isUtc) {
        this.nextInfoTime = moment().utc().toDate();
      } else {
        this.nextInfoTime = moment().toDate();
      }
    }

    this.formGroup = new FormGroup({
      message: new FormControl(undefined, Validators.required),
    });
  }

  async ngOnInit() {
    this.holOptions = await this.holOptionsService.get(false);
    this.nextInfoLimit = this.holOptions.nextInfoLimit;
  }

  save(): void {
    this.dialogRef.close({
      info: {
        message: this.formGroup.value.message,
        nextInfoTime: this.nextInfoTime,
        attachments: this.attachments || {},
      },
      notifications: this.notifications || {},
    });
  }

  gethtmlNextInfo() {
    return {
      message: this.formGroup.value.message,
      nextInfoTime: this.nextInfoTime ? this.nextInfoTime : null,
    };
  }

  saveNextInfo(nextInfo: nextInfoType) {
    this.nextInfoTime = nextInfo.nextInfoTime;
  }

  public isNoteSave(newValue: boolean) {
    this.noteSaved = newValue;
  }

  isValideDate(value: boolean) {
    this.isValidNextInfoDate = value;
  }
}
