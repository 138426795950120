<div class="hol-dialog-wrapper confirmation-modal">
  <mat-toolbar class="mat-toolbar">
    <div class="title" mat-dialog-title>
      <span>
        {{ data.historyTitle | translate }}
      </span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <div class="content-grid">
      <ng-container *ngFor="let itemData of itemsData">
        <ndc-dynamic
          *ngIf="itemData && data.historyItemComponent"
          [ndcDynamicComponent]="data.historyItemComponent"
          [ndcDynamicInputs]="itemData"
        ></ndc-dynamic>
      </ng-container>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="action-bar">
      <div class="fill-space"></div>
      <button (click)="valid()" class="action action-validate">
        <span class="material-symbols-outlined">check</span>
        <span>{{ 'COMMON.BUTTON.OK' | translate | uppercase }}</span>
      </button>
    </div>
  </mat-dialog-actions>
</div>
