import { Injectable } from '@angular/core';
import { OclEventTagService } from '../../../ocl/services/ocl-event-tag-service/ocl-event-tag.service';

@Injectable({
  providedIn: 'root',
})
export class GocEventTagService extends OclEventTagService {
  protected ParseEventTag = Parse.Object.extend('GOCEventTag');
  protected ParseEvents = Parse.Object.extend('GOCEvents');
  protected ParseTag = Parse.Object.extend('GOCTag');
}
