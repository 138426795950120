import { Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { EclFunction, EclFunctionTag, EclUserFunction, EclUserFunctionRef } from '../../models/ecl-function';
import { EclOptionsService } from '../ecl-options-service/ecl-options.service';
import { HolUser, HolUserWithCompanies } from '../../../common/models/hol-user.model';
import { EclCrisis } from '../../models/ecl-crisis';
import { HelperService } from '../../../common/services/helper.service';
import { EclFunctionStoreManager } from '../../store/function/function.store-manager';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EclFunctionService {
  ParseFunction = Parse.Object.extend('ECLFunction');
  ParseUserFunction = Parse.Object.extend('ECLUserFunction');
  ParseUserFunctionRef = Parse.Object.extend('ECLUserFunction_REF');
  ParseFunctionTag = Parse.Object.extend('ECLTags');
  protected ParseCrisis = Parse.Object.extend('ECLCrisis');

  constructor(
    private requestService: RequestService,
    private helperService: HelperService,
    private eclFuncStoreManager: EclFunctionStoreManager,
  ) {}

  async getFunctions(): Promise<EclFunction[]> {
    const functionQuery = new Parse.Query(this.ParseFunction);
    functionQuery.include('ACL');
    functionQuery.ascending('createdAt');
    const result = await this.requestService.performFindAllQuery(functionQuery);
    const tagMap = await this.getTags();
    return result
      ? result.map(item => {
          const func = new EclFunction(item);
          func.tag = tagMap.get(func.tagId);
          return func;
        })
      : [];
  }

  async getUserFunctions(): Promise<EclUserFunction[]> {
    const userFunctionQuery = new Parse.Query(this.ParseUserFunction);
    userFunctionQuery.include('ACL');
    userFunctionQuery.ascending('createdAt');
    const result = await this.requestService.performFindAllQuery(userFunctionQuery);
    return result ? result.map(item => new EclUserFunction(item)) : [];
  }

  async getCurrentUserFunctions(crisis: EclCrisis): Promise<EclUserFunction[]> {
    const userFunctionQuery = new Parse.Query(this.ParseUserFunction);
    userFunctionQuery.include('ACL');
    userFunctionQuery.ascending('createdAt');
    userFunctionQuery.equalTo('userId', Parse.User.current().get('userId'));
    if (crisis) {
      const crisisPar = new this.ParseCrisis({ id: crisis.objectId });
      userFunctionQuery.equalTo('crisis', crisisPar);
    }
    const urfList = this.requestService.performFindAllQuery(userFunctionQuery).then(
      async result => {
        const listUF = result ? result.map(item => new EclUserFunction(item)) : [];

        //ON VÉRIFIE SI LA Crise est fermé :
        if (!crisis.inProgress) {
          const currentRefFunction = await this.getCurrentUserFunctionsRef();

          const userFunctionIds = new Set(listUF.map(func => func.functionId));

          for (const ref of currentRefFunction) {
            if (!userFunctionIds.has(ref.functionId)) {
              listUF.push(ref as EclUserFunction); // convertissez si nécessaire
            }
          }
        }

        return listUF;
      },
      () => [],
    );
    return urfList;
  }

  async allUserFunctionForCrisis(crisis: EclCrisis): Promise<EclUserFunction[]> {
    const userFunctionQuery = new Parse.Query(this.ParseUserFunction);
    userFunctionQuery.include('ACL');
    userFunctionQuery.ascending('createdAt');
    const crisisPar = new this.ParseCrisis({ id: crisis.objectId });
    userFunctionQuery.equalTo('crisis', crisisPar);

    return this.requestService.performFindAllQuery(userFunctionQuery).then(
      result => {
        return result ? result.map(item => new EclUserFunction(item)) : [];
      },
      () => [],
    );
  }

  async getCurrentUserFunctionsRef(): Promise<EclUserFunctionRef[]> {
    const userFunctionQuery = new Parse.Query(this.ParseUserFunctionRef);
    userFunctionQuery.include('ACL');
    userFunctionQuery.ascending('createdAt');
    userFunctionQuery.equalTo('userId', Parse.User.current().get('userId'));

    return this.requestService.performFindAllQuery(userFunctionQuery).then(
      result => {
        return result ? result.map(item => new EclUserFunctionRef(item)) : [];
      },
      () => [],
    );
  }

  async getUserFunctionRefs(): Promise<EclUserFunctionRef[]> {
    const userFunctionQuery = new Parse.Query(this.ParseUserFunctionRef);
    userFunctionQuery.ascending('createdAt');
    const userQuery = new Parse.Query(Parse.User);
    userFunctionQuery.matchesKeyInQuery('functionId', 'functionId', new Parse.Query(this.ParseFunction));
    userFunctionQuery.matchesKeyInQuery('userId', 'userId', userQuery);

    return this.requestService.performFindAllQuery(userFunctionQuery).then(
      result => {
        return result ? result.map(item => new EclUserFunctionRef(item)) : [];
      },
      () => [],
    );
  }

  async getAllUserFunctions(crisis: EclCrisis): Promise<EclUserFunction[]> {
    const functionQuery = new Parse.Query(this.ParseFunction);
    const userQuery = new Parse.Query(Parse.User);
    //functionQuery.exists('tagId');
    const functionUserQuery = new Parse.Query(this.ParseUserFunction);
    functionUserQuery.include('ACL');
    functionUserQuery.descending('createdAt');
    functionUserQuery.include('createdBy');
    functionUserQuery.matchesKeyInQuery('functionId', 'functionId', functionQuery);
    functionUserQuery.matchesKeyInQuery('userId', 'userId', userQuery);

    if (crisis) {
      const crisisPar = new this.ParseCrisis({ id: crisis.objectId });
      functionUserQuery.equalTo('crisis', crisisPar);
    }
    const parseUf = await this.requestService.performFindAllQuery(functionUserQuery);
    if (parseUf) {
      return parseUf.map(functionUserFromApi => new EclUserFunction(functionUserFromApi));
    } else {
      return [];
    }
  }

  public async cloneRefUserFunctionNewCrisis(newCrisis: EclCrisis) {
    const query = new Parse.Query(this.ParseUserFunctionRef);
    query.include('ACL');
    //  query.limit(1000);
    const parseFuncRef = await this.requestService.performFindAllQuery(query);
    //  const tList = parseFuncRef.map(parseTag => new EclUserFunction(parseTag));
    const tList = parseFuncRef
      .filter(t => this.helperService.allowedACL(t.getACL(), newCrisis.acl))
      .map(parseTag => new EclUserFunction(parseTag));

    const allAccessACL = new Parse.ACL();
    allAccessACL.setPublicWriteAccess(true);
    allAccessACL.setPublicReadAccess(true);

    const newList2 = [];

    for (const tl of tList) {
      const newUser = new this.ParseUserFunction({
        functionId: tl.functionId,
        userId: tl.userId,
        isHolder: tl.isHolder,
        ACL: tl.acl || allAccessACL,
        crisis: newCrisis,
      });
      newUser.set('crisis', new this.ParseCrisis({ id: newCrisis.objectId }));

      if (newCrisis.functionsToNotify != undefined && newCrisis.functionsToNotify.length > 0 && newCrisis.functionsToNotify[0] !== '') {
        if (newCrisis.functionsToNotify.includes(tl.functionId)) {
          newList2.push(newUser);
        }
      } else {
        newList2.push(newUser);
      }
    }
    const parseObjectReturn = await this.requestService.performSaveAllQuery(newList2);
    const newUser = parseObjectReturn.map(parseObj => new EclUserFunction(parseObj));

    this.eclFuncStoreManager.addUserFunctions(newUser);
    return;
  }

  public async addNewFunctionOnCrisis(eclFunction: EclFunction, crisis: EclCrisis): Promise<EclUserFunction[]> {
    //First time save new function to notify
    crisis.functionsToNotify.push(eclFunction.functionId);

    const parseCrisis = new this.ParseCrisis({ id: crisis.objectId });
    parseCrisis.set('functionsToNotify', crisis.functionsToNotify.join('|'));

    return this.requestService.performSaveQuery(
      parseCrisis,
      null,
      async () => {
        const currentUserFunction = await this.getCurrentUserFunctions(crisis);
        //Copy all user on crisis
        const query = new Parse.Query(this.ParseUserFunctionRef);
        query.include('ACL');
        query.equalTo('functionId', eclFunction.functionId); // Filtrer par functionId

        const parseFuncRef = await this.requestService.performFindAllQuery(query);
        const tList = parseFuncRef
          .filter(t => this.helperService.allowedACL(t.getACL(), crisis.acl))
          .map(parseTag => new EclUserFunction(parseTag));

        const allAccessACL = new Parse.ACL();
        allAccessACL.setPublicWriteAccess(true);
        allAccessACL.setPublicReadAccess(true);

        const newUserFunctions: Parse.Object[] = [];
        tList.map(async tl => {
          let isPresent = currentUserFunction.some(
            userf => userf.functionId == tl.functionId && userf.userId === tl.userId && tl.companies[0] === userf.companies[0],
          );

          if (!isPresent) {
            const newUserFunction = new this.ParseUserFunction({
              functionId: tl.functionId,
              userId: tl.userId,
              isHolder: tl.isHolder,
              ACL: tl.acl,
              crisis: new this.ParseCrisis({ id: crisis.objectId }),
            });
            newUserFunctions.push(newUserFunction);
          }
        });

        const savedParseUserFunctions = await this.requestService.performSaveAllQuery(newUserFunctions);

        const usersList = await this.eclFuncStoreManager.$eclFunctionState
          .pipe(take(1))
          .toPromise()
          .then(({ allEclUsers }) => {
            return allEclUsers;
          });

        return savedParseUserFunctions.map(savedParseUserFunction => {
          const userFunction = new EclUserFunction(savedParseUserFunction);
          userFunction.function = eclFunction;
          userFunction.user = usersList.filter(value => {
            return userFunction.userId === value.userId;
          })[0];

          this.eclFuncStoreManager.addUserFunction(userFunction);
          return userFunction;
        });
      },
      error => {
        console.log(error);
        return [];
      },
    );
  }

  public async addNewFunctionOnCrisisWithUser(
    eclFunction: EclFunction,
    crisis: EclCrisis,
    user: HolUserWithCompanies,
  ): Promise<EclUserFunction[]> {
    //First time save new function to notify
    crisis.functionsToNotify.push(eclFunction.functionId);
    const parseCrisis = new this.ParseCrisis({ id: crisis.objectId });
    parseCrisis.set('functionsToNotify', crisis.functionsToNotify.join('|'));

    return this.requestService.performSaveQuery(
      parseCrisis,
      null,
      async () => {
        //Copy all user on crisis
        const query = new Parse.Query(this.ParseUserFunctionRef);
        query.include('ACL');
        query.equalTo('functionId', eclFunction.functionId); // Filtrer par functionId
        query.equalTo('userId', user.userId);

        const parseFuncRef = await this.requestService.performFindAllQuery(query);

        const listUserRef = parseFuncRef.map(parseTag => new EclUserFunctionRef(parseTag));
        const newUserFunctions: Parse.Object[] = [];

        for (const userRef of listUserRef) {
          if (this.helperService.allowedACL(userRef.acl, crisis.acl)) {
            const newUserFunction = new this.ParseUserFunction({
              functionId: eclFunction.functionId,
              userId: user.userId,
              isHolder: false,
              ACL: userRef.acl,
              crisis: new this.ParseCrisis({ id: crisis.objectId }),
            });
            newUserFunctions.push(newUserFunction);
          }
        }

        const savedParseUserFunctions = await this.requestService.performSaveAllQuery(newUserFunctions);

        const listNewUser = savedParseUserFunctions.map(savedParseUserFunction => {
          const userFunction = new EclUserFunction(savedParseUserFunction);
          userFunction.function = eclFunction;
          userFunction.user = user;
          return userFunction;
        });
        this.eclFuncStoreManager.addUserFunctions(listNewUser);
        return listNewUser;
      },
      () => {
        return [];
      },
    );
  }

  public async replaceAllUserFunctionFromREF() {
    // Remove
    const query = new Parse.Query(this.ParseUserFunction);
    query.include('ACL');
    const parseFunc = await this.requestService.performFindAllQuery(query);
    const tList = parseFunc.map(parseTag => new this.ParseUserFunction(new EclUserFunction(parseTag)));
    await this.requestService.performDestroyAllQuery(tList);

    // Copy
    const query2 = new Parse.Query(this.ParseUserFunctionRef);
    const parseFuncRef = await this.requestService.performFindAllQuery(query2);
    const tList2 = parseFuncRef.map(parseTag => new EclUserFunction(parseTag));
    const newList2 = tList2.map(tl => {
      const allAccessACL = new Parse.ACL();
      allAccessACL.setPublicWriteAccess(true);
      allAccessACL.setPublicReadAccess(true);
      return new this.ParseUserFunction({
        functionId: tl.functionId,
        userId: tl.userId,
        isHolder: tl.isHolder,
        ACL: tl.acl || allAccessACL,
      });
    });
    await this.requestService.performSaveAllQuery(newList2);
  }

  async addUserFunction(user: HolUserWithCompanies, func: EclFunction, crisis: EclCrisis): Promise<EclUserFunction[]> {
    const listEclUserFunction: EclUserFunction[] = [];
    const parseListToSave: Parse.Object[] = [];
    //get intersec ACL
    const sharedAcl = this.helperService.intersectACLs(crisis.acl, func.acl);
    //get all companies
    if (sharedAcl == null) {
      return [];
    }

    const companies = this.helperService.parseACLByCompany(sharedAcl);
    if (companies != null) {
      for (const company of companies) {
        const companiesUser = user.companies.find(uc => uc.name === company.name);

        if (companiesUser) {
          const parseUserFunction = new this.ParseUserFunction();
          parseUserFunction.set('userId', user.userId);
          parseUserFunction.set('functionId', func.functionId);
          parseUserFunction.set('isHolder', false);

          const newACL = new Parse.ACL();
          newACL.setRoleReadAccess(`ECL_${company.name.toUpperCase()}_READ`, company.read);
          if (companiesUser.write) {
            newACL.setRoleReadAccess(`ECL_${company.name.toUpperCase()}_WRITE`, company.write);
            newACL.setRoleWriteAccess(`ECL_${company.name.toUpperCase()}_WRITE`, company.write);
          }
          // parseUserFunction.setACL(newACL || crisis.acl || func.acl);
          parseUserFunction.setACL(newACL);
          if (crisis != null) {
            parseUserFunction.set('crisis', new this.ParseCrisis({ id: crisis.objectId }));
          }
          parseListToSave.push(parseUserFunction);
        }
      }

      if (parseListToSave.length > 0) {
        const savedParseUserFunction = await this.requestService.performSaveAllQuery(parseListToSave);
        savedParseUserFunction.forEach(parseObj => {
          const userFunction = new EclUserFunction(parseObj);
          userFunction.user = user;
          userFunction.function = func;
          listEclUserFunction.push(userFunction);
        });
      }
    }
    return listEclUserFunction;
  }

  async addUserFunctionRef(user: HolUserWithCompanies, func: EclFunction): Promise<EclUserFunctionRef[]> {
    const listEclUserFunctionRef: EclUserFunctionRef[] = [];
    const companies = func.companies;
    const userSelected = user.companies;
    const parseListToSave: Parse.Object[] = [];

    if (companies != null) {
      for (const companyName of companies) {
        const companiesUser = userSelected.find(company => company.name === companyName);

        if (companiesUser) {
          const parseUserFunctionRef = new this.ParseUserFunctionRef();
          parseUserFunctionRef.set('userId', user.userId);
          parseUserFunctionRef.set('functionId', func.functionId);
          parseUserFunctionRef.set('isHolder', false);

          const newACL = new Parse.ACL();

          newACL.setRoleReadAccess(`ECL_${companyName.toUpperCase()}_READ`, true);

          if (companiesUser.write) {
            newACL.setRoleReadAccess(`ECL_${companyName.toUpperCase()}_WRITE`, true);
            newACL.setRoleWriteAccess(`ECL_${companyName.toUpperCase()}_WRITE`, true);
          }

          parseUserFunctionRef.setACL(newACL);
          parseListToSave.push(parseUserFunctionRef);
        }
      }
      if (parseListToSave.length > 0) {
        const savedParseUserFunctionList = await this.requestService.performSaveAllQuery(parseListToSave);
        savedParseUserFunctionList.forEach(parseObj => {
          const userFunction = new EclUserFunctionRef(parseObj);
          userFunction.user = user;
          userFunction.function = func;
          listEclUserFunctionRef.push(userFunction);
        });
      }
    }
    return listEclUserFunctionRef;
  }

  async deleteUserFunction(userFunction: EclUserFunction) {
    const parseUserFunction = new this.ParseUserFunction({ id: userFunction.objectId });
    await this.requestService.performDestroyQuery(parseUserFunction);
  }

  async deleteUserFunctionRef(userFunctionRef: EclUserFunctionRef) {
    const parseUserFunction = new this.ParseUserFunctionRef({ id: userFunctionRef.objectId });
    await this.requestService.performDestroyQuery(parseUserFunction);
  }

  async createOrUpdateFunction(func: EclFunction): Promise<EclFunction> {
    const parseFunction = new this.ParseFunction(func.objectId && { id: func.objectId });
    parseFunction.set('functionId', func.functionId);
    parseFunction.set('title', func.title);
    parseFunction.set('shortTitle', func.shortTitle);
    parseFunction.set('tagId', func.tagId);
    parseFunction.setACL(func.acl);
    const parseResult = await this.requestService.performSaveQuery(parseFunction);
    return parseResult && new EclFunction(parseResult);
  }

  async getTags(tagIds: string[] = []): Promise<Map<string, EclFunctionTag>> {
    const tagQuery = new Parse.Query(this.ParseFunctionTag);
    tagQuery.ascending('order');
    if (tagIds.length) {
      tagQuery.containedIn('tagId', tagIds);
    }
    const parseResult = await this.requestService.performFindAllQuery(tagQuery);
    return new Map<string, EclFunctionTag>(parseResult.map(res => [res.get('tagId'), new EclFunctionTag(res)]));
  }

  async removeEclUser(userFunctions: EclUserFunction[]) {
    userFunctions.map(value => {
      this.eclFuncStoreManager.deleteUserFunction(value);
    });
    await this.requestService.performDestroyAllQuery(userFunctions.map(value => new this.ParseUserFunction({ id: value.objectId })));
  }

  async removeEclUserRef(userFunctionsRef: EclUserFunctionRef[]) {
    userFunctionsRef.map(value => {
      this.eclFuncStoreManager.deleteUserFunctionRef(value);
    });
    await this.requestService.performDestroyAllQuery(userFunctionsRef.map(value => new this.ParseUserFunctionRef({ id: value.objectId })));
  }

  async getUserFunctionAllCrisisForCurrentUser() {
    const functionQuery = new Parse.Query(this.ParseUserFunction);
    functionQuery.ascending('createdAt');
    functionQuery.equalTo('userId', Parse.User.current().get('userId'));
    functionQuery.include('crisis');

    const parseResult = await this.requestService.performFindAllQuery(functionQuery);
    return parseResult.map(value => new EclUserFunction(value));
  }
}
