import { ConfirmationModalComponent } from 'src/app/common/modals/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { HolAttachments } from 'src/app/common/models/hol-attachments.model';
import { HolTag } from 'src/app/common/models/hol-tag';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { OclGlobalInstruction } from '../../models/ocl-global-instruction.model';
import { OclGlobalInstructionService } from '../../services/ocl-global-instruction-service/ocl-global-instruction.service';
import { HolObject } from '../../../common/models/hol-object';
import { OclGlobalInstructionGroup } from '../../models/ocl-global-instruction-group.model';
import { OclGroupsStoreManager } from '../../store/groups/ocl-groups.store-manager';
import { OclGroupService } from '../../services/ocl-group-service/ocl-group.service';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { HolContext } from 'src/app/common/models/hol-context.model';
import { HolModalConfig } from '../../../common/modals/hol-modal/config/HolModalConfig';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-ocl-global-instruction-modal',
  templateUrl: './ocl-global-instruction-modal.component.html',
  styleUrls: ['./ocl-global-instruction-modal.component.scss'],
})
export class OclGlobalInstructionModalComponent implements OnInit, OnDestroy {
  loading: boolean;
  isCreate: boolean;
  isReadOnly: boolean;
  form: FormGroup;
  attachments = new HolAttachments();
  tags: HolTag[];
  globalInstuction: OclGlobalInstruction;
  notifications: HolNotification[];
  public globalInstGroup: OclGlobalInstructionGroup[] = [];
  public linkedGroup: string[] = [];
  public context: HolContext;

  public config: HolModalConfig;

  public config$: BehaviorSubject<HolModalConfig>;

  constructor(
    public moduleConfig: ModuleConfigService,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<OclGlobalInstructionModalComponent>,
    private globalInstructionService: OclGlobalInstructionService,
    private dialog: MatDialog,
    @Inject('$translate') private $translate,
    protected groupStoreManager: OclGroupsStoreManager,
    protected groupService: OclGroupService,
  ) {
    this.isReadOnly = data.isReadOnly;
    this.isCreate = !data.globalInstuction;
    this.globalInstuction = cloneDeep(data.globalInstuction) || new OclGlobalInstruction();
    this.notifications = cloneDeep(data.notifications);
  }

  get limitTo(): HolObject | undefined {
    return undefined;
  }

  ngOnInit() {
    this.context = {
      module: this.moduleConfig.config.translateKey,
      category: 'GLOBAL',
      htmlTitle: 'this.item.contentText',
      htmlDate: 'this.item.createdAt ? this.item.createdAt : ',
      htmlTemplate: 'A',
    };
    this.form = new FormGroup({
      description: new FormControl('', [Validators.required]),
    });
    this.config = new HolModalConfig();

    if (!this.isCreate) {
      if (this.globalInstuction.tags) {
        this.tags = cloneDeep(this.globalInstuction.tags);
      }
      if (this.globalInstuction.attachments) {
        this.attachments = cloneDeep(this.globalInstuction.attachments);
      }
      this.form.setValue({
        description: this.globalInstuction.description,
      });
    } else {
      this.globalInstuction.attachments = {};
      this.globalInstuction.contentText = '';
      this.globalInstuction.tags = [];
    }

    // Groups
    this.groupStoreManager.groupsState.subscribe(g => {
      this.globalInstGroup = g.globalInstructions;

      //  this.config.haveGroup = this.linkedGroup.length > 0;

      console.log('this.gglobalInstGroup', this.globalInstGroup);

      if (!this.isCreate) {
        if (!this.config.haveGroup) {
          this.config.haveGroup = this.globalInstGroup.some(g => g.items.some(v => v.objectId === this.globalInstuction.objectId));
          if (this.config$) {
            this.config$.next(this.config);
          }
        }
      }
    });

    this.context = {
      module: this.moduleConfig.config.translateKey,
      category: 'GLOBALINSTRUCTION',
      htmlTitle: this.globalInstuction.contentText,
      htmlDate: this.globalInstuction.createdAt,
      htmlTemplate: 'A',
    };
    this.config.haveGroup = this.linkedGroup.length > 0;
    this.config.item = this.globalInstuction;
    this.config.limitTo = this.limitTo;
    this.config.canArchive = false;
    this.config.canEditTitle = true;
    this.config.canUpdateFromOtherModule = false;
    this.config.contentTextLength = 200; // <= TODO mettre la valeur HolOptions
    this.config.context = this.context;
    this.config.type = 'GLOBALINSTRUCTION';
    this.config.notifications = this.notifications;
    this.config.isReadOnly = this.isReadOnly;
    this.config.isHistory = false;
    this.config.isCreate = false;
    this.config.historyItemComponent = this.data.itemComponent;
    this.config.historyItemListMode = true;
    this.config.historyItemObjectId = this.globalInstuction.objectId;
    this.config.historyItemType = 'GLOBALINSTRUCTION';
    this.config.historyTitle = this.moduleConfig.config.translateKey + '.MODALS.HISTORY.LOGBOOK';

    this.config.context = this.context;
    this.config$ = new BehaviorSubject<HolModalConfig>(this.config);
    this.loading = false;
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
  }

  saveNotifications(notifications: HolNotification[]) {
    this.notifications = cloneDeep(notifications);
  }

  save() {
    this.loading = true;
    this.globalInstuction.description = this.config.item.contentText;
    this.globalInstuction.tags = this.config.item['tags'];
    this.globalInstuction.attachments = this.config.item.attachments;
    this.globalInstructionService
      .save(this.config.item as OclGlobalInstruction, this.notifications)
      .then(saveGlobalInstruction => {
        this.globalInstuction = cloneDeep(saveGlobalInstruction);
        Promise.all(this.updateGroupSelected(this.isCreate ? this.globalInstuction : null))
          .then(() => {
            this.groupService.fetchNewDataGlobalInstructionGroup();
          })
          .catch(error => console.error('Error creating OCLGroup', error))
          .finally(() => {
            this.loading = false;
          });
      })
      .finally(() => {
        this.dialogRef.close(this.globalInstuction);
        this.loading = false;
      });
  }

  archive(): void {
    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: this.$translate.instant('COMMON.MODALS.CONFIRMATION.ARCHIVE_TITLE'),
          modalContent: this.$translate.instant(
            this.moduleConfig.config.translateKey + '.DASHBOARD.GLOBAL_INSTRUCTION.MODAL.WARNING_CANCEL',
          ),
          modalQuestion: this.$translate.instant('COMMON.MODALS.CONFIRMATION.QUESTION'),
        },
      })
      .afterClosed()
      .toPromise()
      .then(confirm => {
        if (confirm) {
          this.loading = true;
          this.globalInstructionService
            .archive(this.globalInstuction)
            .then((archivedGlobalInstuction: OclGlobalInstruction) => {
              this.loading = false;
              this.dialogRef.close(archivedGlobalInstuction);
            })
            .catch(e => {
              console.error(e);
              this.loading = false;
            });
        }
      });
  }

  public updateLinkedGroup(e) {
    this.linkedGroup = e;
    //  this.config.item.linkedGroup = this.linkedGroup;
  }

  protected updateGroupSelected(glbIns?: OclGlobalInstruction): Promise<any>[] {
    const listGroupPromise = [];

    // First we remove this item from all groups & then we add the item to the list of groups.
    this.globalInstGroup.forEach(lgr => {
      if (this.data.globalInstuction) lgr.items = lgr.items.filter(lg => lg.objectId !== this.data.globalInstuction.objectId);
      if (this.linkedGroup.includes(lgr.objectId)) {
        if (glbIns) {
          lgr.items.push(glbIns);
        } else {
          lgr.items.push(this.data.globalInstuction);
        }
      }
      listGroupPromise.push(this.groupService.save(lgr, 'GLOBAL_INSTRUCTION'));
    });

    return listGroupPromise;
  }
}
