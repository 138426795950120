<div class="timeline-stopover">
  <div
    *ngIf="!(notSameDayInStd && notSameDayInSta)"
    [departureDate]="stopover.inFlight.std"
    [status]="stopover.inFlight.status"
    appIndicatorTimelineColor
    class="indicators-timeline-stopover in-flight"
    [ngClass]="{
      dashed: notSameDayInStd || notSameDayInSta
    }"
    [ngStyle]="{
      left: inStdPosition + '%',
      width: widthInLine + '%'
    }"
  >
    <div class="circle-marker out" *ngIf="!notSameDayInStd">
      <div class="info">
        <div class="airport">
          <strong>{{ stopover.inFlight.departure }}</strong>
        </div>
        <div class="time">{{ stopover.inFlight.std | date: "HH:mm'Z'":'UTC' }}</div>
      </div>
    </div>
    <div class="circle-marker destination" *ngIf="!notSameDayInSta">
      <div class="info">
        <div class="airport"><strong></strong></div>
        <div class="time">{{ stopover.inFlight.sta | date: "HH:mm'Z'":'UTC' }}</div>
      </div>
    </div>
  </div>
  <div class="airport-stopover" [ngStyle]="{ left: aiportPosition + '%' }"
  [ngClass]="{ selected: departureSelected === stopover.airport }">
    {{ stopover.airport }}
  </div>
  <div
    *ngIf="!(notSameDayOutStd && notSameDayOutSta)"
    [departureDate]="stopover.outFlight.std"
    [status]="stopover.outFlight.status"
    appIndicatorTimelineColor
    class="indicators-timeline-stopover out-flight"
    [ngClass]="{ dashed: notSameDayOutSta }"
    [ngStyle]="{ left: outStdPosition + '%', width: widthOutLine + '%' }"
  >
    <div class="circle-marker out" *ngIf="!notSameDayOutStd">
      <div class="info">
        <div class="airport"></div>
        <div class="time">{{ stopover.outFlight.std | date: "HH:mm'Z'":'UTC' }}</div>
      </div>
    </div>
    <div class="circle-marker destination" *ngIf="!notSameDayOutSta">
      <div class="info">
        <div class="airport">
          <strong>{{ stopover.outFlight.destination }}</strong>
        </div>
        <div class="time">{{ stopover.outFlight.sta | date: "HH:mm'Z'":'UTC' }}</div>
      </div>
    </div>
  </div>
</div>
<!-- <br />
<br />
<br /><br /><br />

<p>dayTargeted {{ dayTargeted }}</p>

<p>
  {{ notSameDayInStd }} {{ stopover.inFlight.std | date: "ddMMM HH:mm'Z'":'UTC' }} - {{ notSameDayInSta }}
  {{ stopover.inFlight.sta | date: "ddMMM HH:mm'Z'":'UTC' }}
</p>
<p>
  {{ notSameDayOutStd }} {{ stopover.outFlight.std | date: "ddMMM HH:mm'Z'":'UTC' }} - {{ notSameDayOutSta
  }}{{ stopover.outFlight.sta | date: "ddMMM HH:mm'Z'":'UTC' }}
</p>
<p>AstdP {{ inStdPosition }} AstaP{{ inStaPosition }} DstdP{{ outStdPosition }} DstaP{{ outStaPosition }}</p>
<p>widthInLine{{ widthInLine }}</p>
<p>widthOutLine{{ widthOutLine }}</p> -->
