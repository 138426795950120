import { FltEvent } from '../../flt/models/flt-event';
import { HolTag } from 'src/app/common/models/hol-tag';
import { HolNextInfo } from 'src/app/common/models/hol-next-info.model';
import { GocLog } from './goc-log.model';
import { Internal } from '../../ocl/models/ocl-interface.model';

export class GocEvent extends FltEvent implements Internal {
  isPrivate: boolean;
  toOCC?: boolean;

  constructor(parseObject?: Parse.Object, tags?: HolTag[], infos?: HolNextInfo[], scenariosFromOtherModule?: Parse.Object[]) {
    super(parseObject, tags, infos, scenariosFromOtherModule);
    if (!parseObject) {
      return;
    }
    this.isPrivate = parseObject.get('isPrivate');
    this.toOCC = parseObject.get('toOCC');
  }

  toLog(): GocLog {
    const log = new GocLog();
    log.attachments = this.attachments;
    log.comment = this.description;
    log.type = 'event';
    log.jsonObject = this;

    return log;
  }
}
