import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddNextInfoModalComponent } from '../add-next-info-modal/add-next-info-modal.component';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  modalTitle: string;
  modalContent: string;
  modalQuestion: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, public dialogRef: MatDialogRef<AddNextInfoModalComponent>) {
    this.modalContent = data.modalContent;
    this.modalTitle = data.modalTitle;
    this.modalQuestion = data.modalQuestion;
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  valid(): void {
    this.dialogRef.close(true);
  }
}
