import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { AlertModalComponent } from 'src/app/common/modals/alert-modal/alert-modal.component';
import { HolisFile } from 'src/app/common/models/hol-attachments.model';

import { FilesService } from '../../services/files.service';
import { HelperService } from '../../services/helper.service';
import { FileUploadAttachmentModalComponent } from '../file-upload-attachment-modal/file-upload-attachment-modal.component';
import { TranslatePipe } from '../../pipes/translate/translate.pipe';
import { acceptedMimeTypes } from './file-upload-mime-config';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnChanges {
  @Input()
  public accept;
  @Input()
  public attachment;
  @Input()
  public column;
  @Input()
  public disabled: boolean = false;
  @Input()
  public icon;
  @Input()
  public label;
  @Input()
  public hideDeleteButton;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output()
  public onFileRemoved = new EventEmitter<any>();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output()
  public onUploadSuccess = new EventEmitter<any>();

  @Output()
  public isLoading = new EventEmitter<boolean>();

  @ViewChild('inputUpload', { static: false }) inputFileRef: ElementRef;

  public uploading = false;

  public fileName = null;

  private file = null;
  private url = null;

  constructor(
    private readonly filesService: FilesService,
    private readonly helperService: HelperService,
    public readonly dialog: MatDialog,
    protected translatePipe: TranslatePipe,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.attachment && changes.attachment.currentValue) {
      this.fileName = changes.attachment.currentValue.fileName;
      this.url = changes.attachment.currentValue.url;
    }
  }

  public openFileModal(): void {
    const dialogRef = this.dialog.open(FileUploadAttachmentModalComponent, {
      data: { attachment: this.attachment || new HolisFile(), type: 'file' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.attachment = result;
        this.onFileInputChange(result);
      }
    });
  }

  public onFileInputChange($event): void {
    if ($event.target.files.length > 0) {
      if ($event.target.files[0].type.includes('svg')) {
        this.dialog.open(AlertModalComponent, {
          data: {
            modalTitle: this.translatePipe.transform('COMMON.MODALS.SVGIMAGE.TITLE'),
            modalContent: this.translatePipe.transform('COMMON.MODALS.SVGIMAGE.CONTENT'),
            modalType: 'info',
            inProgress: false,
          },
        });
      } else {
        if (acceptedMimeTypes.includes($event.target.files[0].type)) {
          this.isLoading.emit(true);
          this.file = $event.target.files[0];
          this.fileName = this.helperService.removeDiacritics(this.file.name);
          this.uploading = true;
          this.filesService.uploadFile(this.fileName, this.file).then(
            url => {
              this.onUploadSuccess.emit({
                uploadedUrl: url,
                fileName: this.fileName,
              });
              this.inputFileRef.nativeElement.value = null;
              this.uploading = false;
              this.isLoading.emit(false);
            },
            err => {
              console.error(err);
              this.uploading = false;
              this.isLoading.emit(false);
            },
          );
        } else {
          this.dialog.open(AlertModalComponent, {
            data: {
              modalTitle: this.translatePipe.transform('ECL.DASHBOARD.LOGBOOK.FILE.TITLE'),
              modalContent: this.translatePipe.transform('ECL.DASHBOARD.LOGBOOK.FILE.CONTENT'),
              modalType: 'info',
              inProgress: false,
            },
          });
        }
      }
    }
  }

  public selectPdf(): void {
    this.inputFileRef.nativeElement.click();
  }
}
