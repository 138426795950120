import { Pipe, PipeTransform } from '@angular/core';
import { find } from 'lodash';

@Pipe({
  name: 'isDefaultTag',
})
export class IsDefaultTagPipe implements PipeTransform {
  transform(tag: any, tags: any[]): boolean {
    return !!find(tags, { objectId: tag.objectId });
  }
}
