<app-hol-list-item
  [item]="task"
  [displayAllTextInItem]="true"
  [nextInfo]="{ nextInfoTime: taskHistoryLog.jsonObject.nextInfoTime, nextInfoDone: taskHistoryLog.jsonObject.nextInfoDone }"
  [attachments]="taskHistoryLog.jsonObject.attachments"
  [ngClass]="task.status"
>
  <div class="header-addon hol-spaced-line">
    <app-tag-chips [tags]="tagsToChip" [isShowName]="false"> </app-tag-chips>
  </div>
  <div class="create-infos-addon hol-spaced-line">
    <!--    <div *ngIf="task.visibleByGroup" class="private-icon"><img class="icon" src="/assets/images/internal.svg" />&nbsp;&nbsp;</div>-->
  </div>
  <div class="hol-spaced-line addon">
    <p class="comment" *ngIf="task.comment">{{ moduleConfig.config.translateKey + '.HISTORY.COMMENT' | translate }}: {{ task.comment }}</p>
  </div>
</app-hol-list-item>
