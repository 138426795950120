import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hol-filter-panel',
  templateUrl: './hol-filter-panel.component.html',
  styleUrls: ['./hol-filter-panel.component.scss'],
})
export class HolFilterPanelComponent implements OnInit {
  public isDeployed = false;

  constructor() {}

  ngOnInit() {}
  public togglePanel(): void {
    this.isDeployed = !this.isDeployed;
  }
}
