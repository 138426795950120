import { Pipe, PipeTransform } from '@angular/core';
import { HolTag } from '../../models/hol-tag';
import { find } from 'lodash';
import { HelperService } from '../../services/helper.service';

@Pipe({
  name: 'tagsStyle',
})
export class TagsStylePipe implements PipeTransform {
  transform(tag: any, selectedTags: any[], defaultTags: any[]): any {
    const isSelected = !!find(selectedTags, { objectId: tag.objectId });
    const isDefault = !!find(defaultTags, { objectId: tag.objectId });

    return {
      'background-color': isSelected || isDefault ? tag.color : this.mixColors('#FFFFFF', tag.color, 90),
      'border-color': tag.color,
      color: isSelected || isDefault ? 'white' : tag.color,
    };
  }
  mixColors(color1, color2, weight): any {
    return HelperService.mixColors(color1, color2, weight);
  }
}
