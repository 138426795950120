<div class="hol-dialog-wrapper hol-modal">
  <mat-toolbar>
    <div mat-dialog-title>
      <i *ngIf="isCreate" class="fa fa-plus"></i>
      <span *ngIf="isCreate" class="title">
        {{ 'CRISIS.MODAL.CREATE_CRISIS.TITLE' | translate }}
      </span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <div class="content-grid">
      <form [formGroup]="form">
        <div *ngIf="crisisInProgress('erp') && canCloseCrisis('erp')" class="close-crisis action-bar">
          <span class="close-crisis-text">{{ 'CRISIS.MODAL.CLOSE_CRISIS.ERP.CLOSE_LABEL' | translate }}</span>
          <button (click)="closeCrisis('erp')" class="action action-validate">
            <span><i aria-hidden="true" class="fa fa-check"></i></span>
            <span>{{ 'CRISIS.MODAL.CLOSE_CRISIS.ERP.CLOSE_BUTTON' | translate }}</span>
          </button>
          <mat-divider *ngIf="crisisInProgress('erp') && canCloseCrisis('erp')"></mat-divider>
        </div>

        <mat-radio-group *ngIf="showModuleControls" formControlName="module">
          <mat-label>
            {{ 'CRISIS.MODAL.CREATE_CRISIS.MODULE.LABEL' | translate }}
          </mat-label>
          <br />
          <mat-radio-button
            (change)="switchModule('erp')"
            [disabled]="crisisInProgress('erp')"
            [matTooltip]="crisisInProgress('erp') ? ('CRISIS.MODAL.CLOSE_CRISIS.ERP.CLOSE_LABEL' | translate) : undefined"
            [value]="erpModuleConfig.config.moduleName"
            color="primary"
          >
            {{ 'CRISIS.MODAL.CREATE_CRISIS.MODULE.ERP' | translate }}
          </mat-radio-button>
        </mat-radio-group>
        <div *ngIf="selectedModule">
          <mat-form-field class="mat-form-field">
            <mat-label>{{ 'CRISIS.MODAL.CREATE_CRISIS.CRISIS_TYPE.LABEL' | translate }}</mat-label>
            <mat-select (selectionChange)="crisisTypeChanged($event)" formControlName="crisisType">
              <mat-option *ngFor="let crisisType of selectedTypeList" [value]="crisisType">
                {{ crisisType.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="selectedCrisisType">
          <div>
            <mat-radio-group formControlName="exercise">
              <mat-radio-button [value]="true" color="warn">
                {{ 'CRISIS.MODAL.CREATE_CRISIS.EXERCISE.YES' | translate }}
              </mat-radio-button>
              <mat-radio-button [value]="false" color="warn">
                {{ 'CRISIS.MODAL.CREATE_CRISIS.EXERCISE.NO' | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-form-field class="mat-form-field">
            <mat-label>{{ selectedCrisisType.descriptionTitle || ('CRISIS.MODAL.CREATE_CRISIS.MAIN_TITLE.lABEL' | translate) }}</mat-label>
            <input
              [maxLength]="80"
              [placeholder]="selectedCrisisType.descriptionHint || ('CRISIS.MODAL.CREATE_CRISIS.MAIN_TITLE.PLACEHOLDER' | translate)"
              formControlName="mainTitle"
              matInput
            />
          </mat-form-field>
          <div>
            <mat-form-field *ngFor="let param of selectedCrisisType.parameters" class="mat-form-field">
              <mat-label>{{ param.title }}</mat-label>
              <input [formControlName]="'param' + param.order" [placeholder]="param.placeholder" matInput maxLength="12" />
            </mat-form-field>
          </div>
          <mat-form-field class="mat-form-field full-width">
            <mat-label>{{ 'CRISIS.MODAL.CREATE_CRISIS.DESCRIPTION.LABEL' | translate }}</mat-label>
            <input [placeholder]="'CRISIS.MODAL.CREATE_CRISIS.DESCRIPTION.PLACEHOLDER' | translate" formControlName="subTitle" matInput />
          </mat-form-field>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="selectedCrisisType">
    <app-acl-selector
      (viaCrisisModal)="viaCrisisModal($event)"
      [extendACLOnly]="!isCreate"
      [formGroup]="form"
      [limitTo]="selectedCrisisType?.acl"
      [moduleName]="selectedModule"
      [object]="selectedCrisis"
      class="acl-full-width"
    ></app-acl-selector>

    <div class="actions-container">
      <button
        (click)="saveCrisis(true)"
        *ngIf="canActivateCrisis && isCreate"
        [disabled]="form.invalid"
        class="actions-addon"
        color="warn"
        mat-raised-button
      >
        {{ 'CRISIS.MODAL.CREATE_CRISIS.BUTTON.ACTIVATE' | translate }}
      </button>
      <button
        (click)="saveCrisis(false)"
        *ngIf="canPrepareCrisis"
        [disabled]="form.invalid"
        class="actions-addon hol-validate-btn-secondary"
        mat-raised-button
      >
        {{
          isCreate ? ('CRISIS.MODAL.CREATE_CRISIS.BUTTON.PREPARE' | translate) : ('CRISIS.MODAL.CREATE_CRISIS.BUTTON.UPDATE' | translate)
        }}
      </button>
    </div>
  </mat-dialog-actions>
</div>
