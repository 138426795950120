<div class="page" id="page-tasks">
  <app-header
    (closeExternalPanels)="closeExternalPanels()"
    [canAddBreakingNews]="!isReadOnly"
    [closeOtherPanels]="showManagersPanel"
    [context]="moduleConfig.config.moduleName"
  >
    <div class="hol-padding">
      <app-acl-filter [module]="moduleConfig.config.moduleName.toUpperCase()"></app-acl-filter>
    </div>
    <ng-container class="buttons-addon">
      <button (click)="toggleManagerPanel()" class="header-button" type="button">
        <img alt="icon managers" src="../../../../assets/images/user.svg" />
      </button>
    </ng-container>
  </app-header>
  <app-hol-addons-panel [expanded]="showManagersPanel">
    <app-ocl-managers-box [isReadOnly]="isReadOnly"></app-ocl-managers-box>
  </app-hol-addons-panel>

  <div class="page-content">
    <app-task-details
      *ngIf="task; else loading"
      [formIoCredentials]="formIoCredentials"
      [functionTitle]="functionTitle"
      [isHolder]="isHolder"
      [taskHistoryLogs]="taskHistoryLogs"
      [taskInput]="task"
    ></app-task-details>

    <ng-template #loading>
      <app-loading class="loading"></app-loading>
    </ng-template>
  </div>
</div>
