import { Inject, Injectable } from '@angular/core';
import { HolRole } from '../models/hol-role';
import { HolUser, HolUserWithCompanies } from '../models/hol-user.model';
import { RequestService } from './request.service';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { flatten, unionBy, uniqBy } from 'lodash';
import { HolObject } from '../models/hol-object';
import { HelperService } from './helper.service';

export interface AclFilterRole {
  company: string;
  color: string;
  writeRoles: HolRole[];
  readRoles: HolRole[];
  userWriteRoles: HolRole[];
  userReadRoles: HolRole[];
}

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  public static readonly UNIVERSES = ['OCC', 'CREW', 'ERP', 'MCC', 'GOC', 'ECL', 'OPS'];
  public static currentUserRoles: { roles: HolRole[]; userId: any };
  public static companiesRolesFilter: string[] = [];
  // tslint:disable-next-line:variable-name
  ParseUser = Parse.Object.extend('User');
  public $companiesRolesFilter: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private allRoles: HolRole[];
  private userCompanyRolesByUniverse: {
    OCC?: AclFilterRole[];
    CREW?: AclFilterRole[];
    ERP?: AclFilterRole[];
    MCC?: AclFilterRole[];
    GOC?: AclFilterRole[];
    ECL?: AclFilterRole[];
    OPS?: AclFilterRole[];
    userId?: string;
  } = {};

  constructor(private requestService: RequestService, @Inject('$rootScope') private $rootScope, private helperService: HelperService) {}

  getAll(forceRefresh?: boolean, withUsers?: boolean): Promise<HolRole[]> {
    return new Promise((resolve, reject) => {
      if (this.allRoles && !forceRefresh) {
        return resolve(this.allRoles);
      } else {
        const query = new Parse.Query(Parse.Role);
        query.addAscending('name');
        query.limit(1000);
        this.requestService
          .performFindAllQuery(query)
          .then((roles: Parse.Role[]) => {
            return Promise.all<Parse.User[]>(
              withUsers
                ? roles.map(r => {
                    return this.requestService.performFindAllQuery<Parse.User>(r.getUsers().query());
                  })
                : [],
            )
              .then((users: Parse.User[][]) => {
                this.allRoles = roles.map((r, index) => {
                  return new HolRole(r, users[index]);
                });
                resolve(this.allRoles);
              })
              .catch(reject);
          })
          .catch(reject);
      }
    });
  }

  getCurrentUserRoles(): Promise<HolRole[]> {
    return new Promise((resolve, reject) => {
      if (RolesService.currentUserRoles && RolesService.currentUserRoles.userId === Parse.User.current().id) {
        return resolve(RolesService.currentUserRoles.roles);
      } else {
        const query = new Parse.Query(Parse.Role);
        query.equalTo('users', Parse.User.current());
        this.requestService
          .performFindAllQuery<Parse.Role>(query)
          .then(roles => {
            RolesService.currentUserRoles = {
              userId: Parse.User.current().id,
              roles: roles.map(r => new HolRole(r)),
            };
            resolve(RolesService.currentUserRoles.roles);
          })
          .catch(reject);
      }
    });
  }

  getUserCompaniesByUniverse(userObjectId: string, universe: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const parseUser = new this.ParseUser({ id: userObjectId });
      const query = new Parse.Query(Parse.Role);
      query.equalTo('users', parseUser);
      this.requestService
        .performFindAllQuery<Parse.Role>(query)
        .then(roles => {
          resolve(
            roles
              .map(r => new HolRole(r))
              .filter(r => r.universe === universe)
              .map(el => el.company),
          );
        })
        .catch(reject);
    });
  }

  getUserRoleByCompanies(userObjectId: string, company: string): Promise<HolRole[]> {
    return new Promise((resolve, reject) => {
      const parseUser = new this.ParseUser({ id: userObjectId });
      const query = new Parse.Query(Parse.Role);
      query.equalTo('users', parseUser);
      // query.contains('users', parseUser);
      this.requestService
        .performFindAllQuery<Parse.Role>(query)
        .then(roles => {
          resolve(roles.map(r => new HolRole(r)).filter(r => r.company === company));
        })
        .catch(reject);
    });
  }

  getUserCompanyRolesByUniverse(universe: string): Promise<AclFilterRole[]> {
    return new Promise(async (resolve, reject) => {
      if (this.userCompanyRolesByUniverse.userId === Parse.User.current().id && this.userCompanyRolesByUniverse[universe]) {
        resolve(this.userCompanyRolesByUniverse[universe]);
      } else {
        try {
          const [allRoles, userRoles] = await forkJoin([this.getAll(), this.getCurrentUserRoles()]).toPromise();
          const universeRoles = allRoles.filter(r => r.universe === universe);
          const userUniverseRoles = userRoles.filter(r => r.universe === universe);

          const allCompanies = unionBy(universeRoles.map(r => r.company));
          this.userCompanyRolesByUniverse.userId = Parse.User.current().id;
          this.userCompanyRolesByUniverse[universe] = allCompanies.map(company => {
            const firstRole = universeRoles.find(r => r.company === company && !!r.color);
            const color = firstRole && firstRole.color;
            return {
              company,
              color,
              writeRoles: universeRoles.filter(r => r.write && r.company === company),
              readRoles: universeRoles.filter(r => r.read && r.company === company),
              userWriteRoles: userUniverseRoles.filter(r => r.write && r.company === company),
              userReadRoles: userUniverseRoles.filter(r => r.read && r.company === company),
            };
          });
          resolve(this.userCompanyRolesByUniverse[universe]);
        } catch (ex) {
          reject(ex);
        }
      }
    });
  }

  getUsersByRolesUnivers(universe: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const query = new Parse.Query(Parse.Role);
      query.startsWith('name', universe.toLocaleUpperCase());
      query.addAscending('name');
      this.requestService
        .performFindAllQuery(query)
        .then((roles: Parse.Role[]) => {
          return Promise.all<Parse.User[]>(
            roles.map(r => {
              return this.requestService.performFindAllQuery<Parse.User>(r.getUsers().query());
            }),
          )
            .then((users: Parse.User[][]) => {
              const userToResolve = uniqBy(flatten(users), 'id').map(user => new HolUser(user));
              resolve(userToResolve);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  addUsers(usersToAdd: HolUser[], role: HolRole): Promise<HolRole> {
    return new Promise((resolve, reject) => {
      const users = role.parseRole.getUsers();
      users.add(
        usersToAdd.map(u => {
          const usr = new Parse.User();
          usr.id = u.objectId;
          return usr;
        }),
      );
      this.requestService.performSaveQuery<Parse.Role>(
        role.parseRole,
        null,
        r => {
          this.requestService.performFindAllQuery<Parse.User>(
            r.getUsers().query(),
            usrs => {
              resolve(new HolRole(role.parseRole, usrs));
            },
            reject,
          );
        },
        reject,
      );
    });
  }

  removeUser(role: HolRole, user: HolUser): Promise<HolRole> {
    return new Promise((resolve, reject) => {
      const users = role.parseRole.getUsers();
      const u = new Parse.User();
      u.id = user.objectId;
      users.remove(u);
      this.requestService.performSaveQuery(
        role.parseRole,
        null,
        r => {
          this.requestService.performFindAllQuery<Parse.User>(
            r.getUsers().query(),
            usrs => {
              resolve(new HolRole(role.parseRole, usrs));
            },
            reject,
          );
        },
        reject,
      );
    });
  }

  removeAllRolesToUser(user: HolUser): Promise<HolRole[]> {
    let roles: HolRole[];
    return new Promise<HolRole[]>((resolve, reject) => {
      /// Récupère tous les rôles
      this.getAll(true, true)
        .then(allRoles => {
          roles = allRoles;
          // Supprime l'utilisateur dans chaque rôle
          roles.forEach(role => {
            const users = role.parseRole.getUsers();
            const u = new Parse.User();
            u.id = user.objectId;
            users.remove(u);
          });
          // Enregistre les modifications
          const saveRoles = roles.map(role => role.parseRole);
          return Parse.Object.saveAll(saveRoles);
        })
        .then(() => {
          resolve(roles); // Retourne les rôles
        });
    });
  }

  createRolesForCompany(companyName: string, subRoles: string[], color: string): Promise<Parse.Role[]> {
    const roles = [];
    const acl = new Parse.ACL({ '*': { read: true } });
    acl.setRoleWriteAccess('Admin', true);
    acl.setRoleReadAccess('Admin', true);
    RolesService.UNIVERSES.forEach(universeName => {
      if ((universeName === 'OCC' || universeName === 'OPS') && subRoles && subRoles.length) {
        roles.push(new Parse.Role(`${universeName}_${companyName}_READ`, acl));
        subRoles.forEach(sr => {
          const name = `${universeName}_${companyName}-${sr}_WRITE`;
          roles.push(new Parse.Role(name, acl));
        });
      } else if (universeName === 'GOC') {
        roles.push(new Parse.Role(`${universeName}_${companyName}_READ`, acl));
        roles.push(new Parse.Role(`${universeName}_${companyName}-EXTERNAL_READ`, acl));
        roles.push(new Parse.Role(`${universeName}_${companyName}_WRITE`, acl));
        roles.push(new Parse.Role(`${universeName}_${companyName}-EXTERNAL_WRITE`, acl));
      } else {
        roles.push(new Parse.Role(`${universeName}_${companyName}_READ`, acl));
        roles.push(new Parse.Role(`${universeName}_${companyName}_WRITE`, acl));
      }
    });
    roles.forEach(r => {
      r.set('color', color);
    });
    return this.requestService.performSaveAllQuery<Parse.Role>(roles);
  }

  filterFromCompanyRoles<T extends HolObject = HolObject>(values?: T[]): T[] {
    if (values) {
      if (!RolesService.companiesRolesFilter || !RolesService.companiesRolesFilter.length) {
        return [];
      }
      return values.filter(item => {
        if (!item.companies || !item.companies.length) {
          return true;
        }
        return !!item.companies.find(c => {
          return RolesService.companiesRolesFilter.indexOf(c) > -1;
        });
      });
    }
    return null;
  }

  filterUserFromCompanyRoles(values: HolUserWithCompanies[]): HolUserWithCompanies[] {
    if (values) {
      if (!RolesService.companiesRolesFilter || !RolesService.companiesRolesFilter.length) {
        return values;
      }
      return values.filter(item => {
        if (!item.companies || !item.companies.length) {
          return true;
        }
        return !!item.companies.find(c => {
          return RolesService.companiesRolesFilter.indexOf(c.name) > -1;
        });
      });
    }
    return null;
  }

  public async getCompaniesColors(): Promise<{ [companyName: string]: string }> {
    const companiesColor = {};
    const allRoles = await this.getAll();
    allRoles.forEach(r => {
      if (r.color && !companiesColor[r.company]) {
        companiesColor[r.company] = r.color;
      }
    });
    return companiesColor;
  }

  setCompanyRolesFilter(companies: string[]): void {
    RolesService.companiesRolesFilter = companies;
    this.$rootScope.companiesRolesFilter = companies;
    this.$companiesRolesFilter.next(companies);
  }

  getACLFromCompaniesRolesFilter(universe: string): Promise<Parse.ACL> {
    return this.getAll().then(roles => {
      const matchingRoles = roles.filter(r => r.universe === universe && RolesService.companiesRolesFilter.includes(r.company));
      const acl = new Parse.ACL();
      matchingRoles.forEach(r => {
        acl.setRoleReadAccess(r.parseRole, true);
        if (r.write) {
          acl.setRoleWriteAccess(r.parseRole, true);
        }
      });
      return acl;
    });
  }

  filterFromAcl<T extends HolObject = HolObject>(values: T[], acl: Parse.ACL): T[] {
    const companies = this.helperService.parseACL(acl);
    if (values) {
      return values.filter(item => {
        if (!item.companies || !item.companies.length) {
          return true;
        }
        return !!item.companies.find(c => {
          return companies.indexOf(c) > -1;
        });
      });
    }
    return null;
  }

  getACLForCompanies(modules: string[], compagnies: string[]): Promise<Parse.ACL> {
    return this.getAll().then(roles => {
      const matchingRoles = roles.filter(r => modules.includes(r.universe) && compagnies.includes(r.company));
      const acl = new Parse.ACL();
      matchingRoles.forEach(r => {
        acl.setRoleReadAccess(r.parseRole, true);
        if (r.write) {
          acl.setRoleWriteAccess(r.parseRole, true);
        }
      });
      return acl;
    });
  }
}
