<app-loading *ngIf="loading" class="full-loader"></app-loading>
<div class="hol-modal hol-dialog-wrapper group-details-modal">
  <mat-toolbar>
    <div mat-dialog-title>
      <i *ngIf="isCreate" class="fa fa-plus"></i>
      <i *ngIf="!isCreate" aria-hidden="true" class="fa fa-pencil"></i>
      <span class="title"> {{ modalTitle }}</span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <div class="content-grid">
      <form [formGroup]="form">
        <div class="col full-width">
          <mat-form-field *ngIf="isCreate" class="description-form-field full-width" tabindex="-1">
            <textarea
              [matAutocomplete]="auto"
              class="description-textarea"
              formControlName="titleForm"
              matInput
              matTextareaAutosize
              maxlength="64"
              placeholder="{{
                moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.TITLE_PLACEHOLDER' | translate
              }}"
              type="text"
            >
            </textarea>
            <!--
            <textarea
              (ngModel)="(selectedTitle)"
              (ngModelChange)="onTitleChange($event)"
              [formControl]="myControl"
              [matAutocomplete]="auto"
              class="description-textarea"
              formControlName="titleForm"
              matInput
              matTextareaAutosize
              maxlength="64"
              placeholder="{{
                moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.TITLE_PLACEHOLDER' | translate
              }}"
              type="text"
            >
            </textarea>

            -->
            <mat-autocomplete #auto="matAutocomplete" isOpen="filteredTitleList.length !== 0">
              <mat-option (onSelectionChange)="selectGroup(option)" *ngFor="let option of filteredTitleList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <!-- <span class="info-msg-title">{{ infoMsgTitle }}</span> -->
          <mat-form-field *ngIf="!isCreate" class="description-form-field full-width" tabindex="-1">
            <textarea
              class="description-textarea"
              formControlName="titleForm"
              matInput
              matTextareaAutosize
              maxlength="64"
              placeholder="{{
                moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.TITLE_PLACEHOLDER' | translate
              }}"
            >
            </textarea>
          </mat-form-field>

          <ng-container>
            <app-ocl-summary
              *ngIf="isSummaryActivated"
              [(isInBriefing)]="isInBriefing"
              [(isInSummary)]="isInSummary"
              [invertedStyle]="true"
            >
            </app-ocl-summary>
          </ng-container>

          <div *ngIf="items.length" class="event-group">
            <div class="content-row">
              <div class="content-column">
                <span class="selection-text">{{
                  moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.SELECT_TEXT' | translate
                }}</span>
              </div>
              <div *ngIf="isInSummary && anyItemSelected" class="content-column">
                <span class="selection-text">
                  {{ 'ECL.DASHBOARD.STATUS_DECISIONS.MODALS.GROUP_MODAL.SYNTHESIS_PUBLISH' | translate }}
                </span>
              </div>
            </div>
            <ul class="hol-list-grid">
              <li *ngFor="let item of itemsForm.controls; let i = index">
                <ng-container *ngIf="type === 'EVENT'">
                  <div class="div1" formArrayName="itemsForm">
                    <mat-checkbox [formControlName]="i" [labelPosition]="'after'" class="checkbox" color="primary"></mat-checkbox>
                  </div>
                  <div class="div2">
                    <app-hol-list-item
                      class="itemcard"
                      [attachments]="items[i].attachements"
                      [canUpdateItem]="false"
                      [isReadOnly]="true"
                      [item]="items[i]"
                      [fromModule]="item.fromERP ? 'ERP' : null"
                      [toModule]="item.toERP ? 'ERP' : null"
                    >
                      <div class="header-addon">
                        <span *ngIf="items[i]" class="checkbox-label scenario">{{ items[i].scenario.code }}{{ items[i].order }}</span>
                        <span *ngIf="items[i]" class="checkbox-label description">{{ items[i].description }}</span>

                        <div class="header-right">
                          <div
                            *ngIf="items[i]['function'] && items[i]['function'].objectId && moduleConfig.config.moduleName == 'ecl'"
                            style="margin-bottom: 5px"
                          >
                            <app-ecl-user-function-detail readonly="true" [function]="items[i]['function']"></app-ecl-user-function-detail>
                          </div>
                        </div>
                      </div>
                      <div class="hol-spaced-line addon">
                        <div class="col-attachements">
                          <app-file-and-image-icons-display [attachments]="items[i].attachments"></app-file-and-image-icons-display>
                        </div>
                      </div>
                    </app-hol-list-item>
                  </div>
                  <div class="div3">&nbsp;</div>
                </ng-container>
                <ng-container *ngIf="type === 'LOGBOOK'">
                  <div class="div1" formArrayName="itemsForm">
                    <mat-checkbox [formControlName]="i" [labelPosition]="'after'" class="checkbox" color="primary"></mat-checkbox>
                  </div>
                  <div class="div2">
                    <app-hol-list-item
                      class="itemcard"
                      [attachments]="items[i].attachements"
                      [canUpdateItem]="false"
                      [isReadOnly]="true"
                      [item]="items[i]"
                      [fromModule]="item.fromERP ? 'ERP' : null"
                      [toModule]="item.toERP ? 'ERP' : null"
                    >
                      <div class="header-addon">
                        <div class="header-right">
                          <div
                            *ngIf="items[i]['function'] && items[i]['function'].objectId && moduleConfig.config.moduleName == 'ecl'"
                            style="margin-bottom: 5px"
                          >
                            <app-ecl-user-function-detail readonly="true" [function]="items[i]['function']"></app-ecl-user-function-detail>
                          </div>
                        </div>
                      </div>
                      <div class="hol-spaced-line addon">
                        <div class="col-attachements">
                          <app-file-and-image-icons-display [attachments]="items[i].attachments"></app-file-and-image-icons-display>
                        </div>
                      </div>
                    </app-hol-list-item>
                  </div>
                  <div class="div3" formArrayName="summaryItemsForm">
                    <mat-checkbox *ngIf="isInSummary && itemsForm.controls[i].value" [formControlName]="i" color="primary"></mat-checkbox>
                  </div>
                </ng-container>
                <ng-container *ngIf="type === 'DECISION'">
                  <div class="div1" formArrayName="itemsForm">
                    <mat-checkbox [formControlName]="i" [labelPosition]="'after'" class="checkbox" color="primary"></mat-checkbox>
                  </div>
                  <div class="div2">
                    <app-hol-list-item
                      class="itemcard"
                      [attachments]="items[i].attachements"
                      [canUpdateItem]="false"
                      [isReadOnly]="true"
                      [item]="items[i]"
                      [fromModule]="item.fromERP ? 'ERP' : null"
                      [toModule]="item.toERP ? 'ERP' : null"
                    >
                      <div class="header-addon">
                        <div class="header-right">
                          <div
                            *ngIf="items[i]['function'] && items[i]['function'].objectId && moduleConfig.config.moduleName == 'ecl'"
                            style="margin-bottom: 5px"
                          >
                            <app-ecl-user-function-detail readonly="true" [function]="items[i]['function']"></app-ecl-user-function-detail>
                          </div>
                        </div>
                      </div>
                      <div class="hol-spaced-line addon">
                        <div class="col-attachements">
                          <app-file-and-image-icons-display [attachments]="items[i].attachments"></app-file-and-image-icons-display>
                        </div>
                      </div>
                    </app-hol-list-item>
                  </div>
                  <div class="div3" formArrayName="summaryItemsForm">
                    <mat-checkbox *ngIf="isInSummary && itemsForm.controls[i].value" [formControlName]="i" color="primary"></mat-checkbox>
                  </div>
                </ng-container>
                <ng-container *ngIf="type === 'GLOBAL_INSTRUCTION'">
                  <div class="div1" formArrayName="itemsForm">
                    <mat-checkbox
                      *ngIf="type === 'GLOBAL_INSTRUCTION'"
                      [formControlName]="i"
                      [labelPosition]="'after'"
                      class="checkbox"
                      color="primary"
                    >
                    </mat-checkbox>
                  </div>
                  <div class="div2">
                    <app-hol-list-item
                      class="itemcard"
                      [attachments]="items[i].attachements"
                      [canUpdateItem]="false"
                      [isReadOnly]="true"
                      [item]="items[i]"
                      [fromModule]="item.fromERP ? 'ERP' : null"
                      [toModule]="item.toERP ? 'ERP' : null"
                    >
                      <div class="header-addon">
                        <div class="header-right">
                          <div
                            *ngIf="items[i]['function'] && items[i]['function'].objectId && moduleConfig.config.moduleName == 'ecl'"
                            style="margin-bottom: 5px"
                          >
                            <app-ecl-user-function-detail readonly="true" [function]="items[i]['function']"></app-ecl-user-function-detail>
                          </div>
                        </div>
                      </div>
                      <div class="hol-spaced-line addon">
                        <div class="col-attachements">
                          <app-file-and-image-icons-display [attachments]="items[i].attachments"></app-file-and-image-icons-display>
                        </div>
                      </div>
                    </app-hol-list-item>
                  </div>
                  <div class="div3">&nbsp;</div>
                </ng-container>
                <div *ngIf="getAlreadyInGroups(items[i]).length > 0" class="already-group-label div4">
                  <p *ngFor="let g of getAlreadyInGroups(items[i])" class="">
                    <img src="../../../../assets/images/group-attach.svg" alt="" />
                    <span>{{ g.title }}</span>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions *ngIf="!isReadOnly">
    <div class="action-bar">
      <button (click)="onDeleteGroup()" *ngIf="!isCreate" class="action action-refuse" tabindex="-1">
        <!-- <img src="../../../../assets/images/group-delete.svg"/> -->
        <span class="material-symbols-outlined">delete</span>
        <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.DELETE_GROUP_TEXT' | translate }}</span>
      </button>
      <div class="spacer"></div>
      <button (click)="isCreate ? onCreateGroup() : onUpdateGroup()" [disabled]="isDisableAddButton()" class="action action-validate">
        <span><i aria-hidden="true" class="fa fa-check"></i></span>
        <span>{{ (isCreate ? addButtonTrad : updateButtonTrad) | translate }}</span>
      </button>
    </div>
  </mat-dialog-actions>
</div>
