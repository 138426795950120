import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addPrefix',
})
export class AddPrefixPipe implements PipeTransform {
  transform(name: string, color: string): string {
    let prefix = '';
    switch (color) {
      case '#FF0000':
        prefix = '#';
        break;
      case '#009DFF':
        prefix = '@';
        break;
      case '#DB00C9':
        prefix = '§';
        break;
      case '#000000':
        prefix = '';
        break;
    }

    return `${prefix}${name}`;
  }
}
