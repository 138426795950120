import { BehaviorSubject, Subscription } from 'rxjs';
import { CommonStoreManager } from '../../../common/store/common.store-manager';
import { OCL_EVENT_MODAL_TOKEN } from '../ocl-event-modal/ocl-event-modal-tokens';
import { OclNotificationsService } from 'src/app/ocl/services/ocl-notifications-service/ocl-notifications.service';
import { HolContext } from '../../../common/models/hol-context.model';
import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe } from 'src/app/common/pipes/translate/translate.pipe';
import * as moment from 'moment';
import { OclEvent } from '../../models/ocl-event.model';
import { OclLogbook } from '../../models/ocl-logbook.model';
import { HolNotification, HolNotifyFunction } from 'src/app/common/models/hol-notification.model';
import { OCL_DECISIONS_MODAL_TOKEN } from '../ocl-decisions-modal/ocl-decisions-modal.token';
import { OclLinkedItemsStoreManager } from '../../store/linked-items/ocl-linked-items-store-manager.service';
import { OclLogbookService } from '../../services/ocl-logbook-service/ocl-logbook.service';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { OclLinksService } from '../../services/ocl-links-service/ocl-links.service';
import { FltFlightService } from '../../../flt/services/flt-flight.service';
import { cloneDeep, difference, intersectionWith } from 'lodash';
import { HolObject } from '../../../common/models/hol-object';
import { OclGroupsStoreManager } from '../../store/groups/ocl-groups.store-manager';
import { OclLogBookGroup } from '../../models/ocl-logbook-group.model';
import { OclGroupService } from '../../services/ocl-group-service/ocl-group.service';
import { OclLogbookDecisionService } from '../../services/ocl-logbook-decision-service/ocl-logbook-decision.service';
import { OclOptionsService } from '../../services/ocl-options-service/ocl-options.service';
import { EclLogbook } from '../../../ecl/models/ecl-logbook';
import { HolModalConfig } from '../../../common/modals/hol-modal/config/HolModalConfig';
import { take } from 'rxjs/operators';
import { ConfirmationModalComponent } from '../../../common/modals/confirmation-modal/confirmation-modal.component';
import { HolOptionsService } from 'src/app/common/services/hol-options.service';
import { nextInfoType } from '../../../common/components/hol-next-info/hol-next-info.component';

@Component({
  selector: 'app-ocl-logbook-modal',
  templateUrl: './ocl-logbook-modal.component.html',
  styleUrls: ['./ocl-logbook-modal.component.scss'],
})
export class OclLogbookModalComponent implements OnInit, OnDestroy {
  public logbook: OclLogbook = new OclLogbook();

  public isCreate: boolean;
  public isReadOnly: boolean;
  public events: OclEvent[] = null;
  public selectedItem = undefined;
  public selectedTags = [];
  public module: string;
  public action: string;
  public isUtc: boolean;
  public loading = false;
  public loadingFlight = false;
  public selectedFlight = undefined;
  public searchText: string;
  public searchData = {
    date: undefined,
    flights: [],
    flightsFiltered: [],
    flightNumber: null,
  };
  public autocompletePlaceHolder: string;
  public decisionItems: any[] = [];
  public linkedDecisions: string[];
  public linkedDecisionsDisplay: any[] = [];
  public linkedDecisionsCopy: string[] = [];
  public linkedEvents: string[];
  public linkedEventsDisplay: any[] = [];
  public logbookGroup: OclLogBookGroup[] = [];
  public linkedGroup: string[] = [];
  public linkedEventsCopy: string[] = [];
  public linkedEventsArchived = [];
  public linkedDecisionsArchived = [];
  public notifications: HolNotification[];
  public isFromDecision = false;
  public disabledLinks = {};
  modeLinkIsActivate;
  public airportsCode: Array<string>;
  public applicabilityIsReadOnly: boolean;
  public context: HolContext;

  public duplicateToOtherModule = false;
  public oldDuplicateToOtherModuleValue = null;
  public newDuplicateToOtherModuleValue = null;
  public updateDuplicateToOtherModuleFlightValue = false;

  public isHistory;
  public fromGOC = false;
  public fromCREW = false;
  public fromERP = false;
  public changeToDecision = false;
  public canUpdateFromOtherModule = false;
  public hasCrisisInProgress = false;
  public toERP = false;
  public hasCrisisInProgressSub: Subscription;
  public hasErpAccess: boolean;

  public isInSummary = false;
  public isInBriefing = false;
  public config: HolModalConfig;

  public config$: BehaviorSubject<HolModalConfig>;

  constructor(
    protected dialog: MatDialog,
    protected translatePipe: TranslatePipe,
    public moduleConfig: ModuleConfigService,
    public flightService: FltFlightService,
    public logbookService: OclLogbookService,
    protected notificationsService: OclNotificationsService,
    protected dialogRef: MatDialogRef<OclLogbookModalComponent>,
    protected occLinkItemsStoreManager: OclLinkedItemsStoreManager,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(OCL_DECISIONS_MODAL_TOKEN) protected occDecisionsModal,
    protected linksService: OclLinksService,
    @Inject(OCL_EVENT_MODAL_TOKEN) protected eventModal,
    protected commonStoreManager: CommonStoreManager,
    @Inject('$rootScope') public $rootScope,
    protected groupStoreManager: OclGroupsStoreManager,
    protected groupService: OclGroupService,
    protected oclLogbookDecisionService: OclLogbookDecisionService,
    protected oclOptionsService: OclOptionsService,
    protected cdr: ChangeDetectorRef,
    protected holOptionsService: HolOptionsService,
  ) {
    this.notificationsService.getAll().then(notif => (this.notifications = notif));
    this.hasErpAccess = $rootScope.accessRights.crisis !== 'UNAUTHORIZED';
  }

  get limitTo(): HolObject | undefined {
    return undefined;
  }

  async ngOnInit() {
    this.module = this.translatePipe.transform('PDF_TITLE.OCC');
    this.action = this.translatePipe.transform('PDF_TITLE.LOGBOOK');

    this.autocompletePlaceHolder = this.translatePipe.transform(
      this.moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.PLACEHOLDER_CHOOSE_DATE',
    );

    this.isUtc = this.data.isUtc;
    this.modeLinkIsActivate = this.data.modeLinkIsActivate;

    this.events = cloneDeep(this.data.events) || [];
    this.decisionItems = cloneDeep(this.data.decisionItems) || [];

    this.airportsCode = this.data.airportsCode;

    this.isHistory = this.data.isHistory ? this.data.isHistory : false;

    this.hasCrisisInProgressSub = this.commonStoreManager.crisis.subscribe(crisis => {
      if (crisis) this.hasCrisisInProgress = crisis.inProgress;
    });

    this.config = new HolModalConfig();

    if (this.data.logbook) {
      this.initLinks();

      this.isReadOnly =
        this.data.isReadOnly ||
        this.logbook.readOnly ||
        this.data.logbook.fromGOC ||
        this.data.logbook.fromCREW ||
        this.data.logbook.fromERP ||
        this.isHistory;
      this.isCreate = false;
      this.applicabilityIsReadOnly = true;

      this.logbook = this.data.logbook;

      this.isInSummary =
        this.data.logbook &&
        this.data.logbook.summary &&
        this.data.logbook.summary.logBook &&
        this.data.logbook.summary.logBook.objectId !== undefined;
      if (this.isInSummary) {
        this.isInBriefing = this.data.logbook.summary.isDisplayInBriefing;
        this.config.isOnSummary = true;
      }

      if (this.logbook.flight && this.logbook.flight.flightNumber) {
        this.selectedFlight = this.logbook.flight;
        this.initSearchDataFlight();
        this.config.haveFlightLink = true;
      }

      this.config.haveGroup = this.linkedGroup.length > 0;
      this.config.item = this.logbook;
      this.config.limitTo = this.limitTo;
      this.config.canArchive = false;
      this.config.canEditTitle = !this.isHistory;
      this.config.canUpdateFromOtherModule = this.canUpdateFromOtherModule;
      this.config.contentTextLength = 200; // <= TODO mettre la valeur HolOptions
      this.config.context = this.context;
      this.config.type = 'LOGBOOK';
      this.config.notifications = this.notifications;
      this.config.isReadOnly = this.isReadOnly || this.isHistory;
      this.config.isHistory = this.isHistory;
      this.config.isCreate = false;
      this.config.historyItemComponent = this.data.itemComponent;
      this.config.historyItemListMode = true;
      this.config.historyItemObjectId = this.logbook.objectId;
      this.config.historyItemType = 'logbook';
      this.config.historyTitle = this.moduleConfig.config.translateKey + '.MODALS.HISTORY.LOGBOOK';
    } else {
      this.isCreate = true;
      this.applicabilityIsReadOnly = false;
      this.linkedEvents = [];
      this.linkedDecisions = [];
      this.logbook.text = '';
      this.logbook.done = false;
      this.logbook.attachments = {};
      this.logbook.tags = [];
      this.logbook.contentText = '';

      if (this.data.fromDecision) {
        this.isFromDecision = true;
        this.logbook.contentText = this.data.fromDecision.message;
        this.logbook.tags = cloneDeep(this.data.fromDecision.tags);
        this.logbook.attachments = cloneDeep(this.data.fromDecision.attachments);
        this.logbook.acl = this.data.fromDecision.acl;
        if (this.data.fromDecision.flight && this.data.fromDecision.flight.flightNumber) {
          this.selectedFlight = this.data.fromDecision.flight;
          this.initSearchDataFlight();
        }
      }
      this.config.haveGroup = false;
      this.config.item = this.logbook;
      this.config.limitTo = this.limitTo;
      this.config.canArchive = false;
      this.config.canEditTitle = !this.isHistory;
      this.config.canUpdateFromOtherModule = this.canUpdateFromOtherModule;
      this.config.contentTextLength = 200; // <= TODO mettre la valeur HolOptions
      this.config.type = 'LOGBOOK';
      this.config.notifications = this.notifications;
      this.config.isReadOnly = this.isReadOnly || this.isHistory;
      this.config.isHistory = this.isHistory;
      this.config.isCreate = true;
    }

    if (this.logbook.fromGOC) {
      this.fromGOC = this.logbook.fromGOC ? true : false;
    }

    if (this.logbook.fromCREW) {
      this.fromCREW = this.logbook.fromCREW ? true : false;
    }

    if (this.logbook.fromERP) {
      this.fromERP = this.logbook.fromERP ? true : false;
    }

    if (this.moduleConfig.config.displayToERP) {
      this.toERP = this.logbook.toERP ? true : false;
    }

    this.context = {
      module: this.moduleConfig.config.translateKey,
      category: 'LOGBOOK',
      htmlTitle: this.logbook.contentText,
      htmlDate: this.logbook.createdAt,
      htmlTemplate: 'A',
    };
    this.config.context = this.context;

    this.canUpdateFromOtherModule = !this.isHistory && !this.logbook.readOnly ? this.logbook.fromGOC || this.logbook.fromCREW : false;
    this.config.canUpdateFromOtherModule = this.canUpdateFromOtherModule;
    // Groups

    const g = await this.groupStoreManager.groupsState.pipe(take(1)).toPromise();
    this.logbookGroup = g.logbooks;

    this.config$ = new BehaviorSubject<HolModalConfig>(this.config);
    this.loading = false;

    this.config.haveGroup = this.linkedGroup.length > 0;
    //  this.config$.next(this.config);
    /*
    this.groupStoreManager.groupsState.pipe(take(1)).subscribe(g => {
      this.logbookGroup = g.logbooks;

      if (this.config.haveGroup && this.linkedGroup.length === 0) {
        this.config.haveGroup = false;
        this.config$.next(this.config);
      } else if (!this.config.haveGroup && this.linkedGroup.length > 0) {
        this.config.haveGroup = true;
        this.config$.next(this.config);
      }
    });

    */
  }

  public initSearchDataFlight(): void {
    this.searchData.flightNumber = this.selectedFlight.flightNumber;
    this.searchData.date = moment.utc(this.selectedFlight.sta).toDate();
    // tslint:disable-next-line: max-line-length
    const date = moment.utc(
      `${this.searchData.date.getFullYear()}/${this.searchData.date.getMonth() + 1}/${this.searchData.date.getDate()}`,
      'YYYY/MM/DD',
    );
    this.flightService.getBySlot(date).then(res => {
      this.searchData.flights = res;
      this.searchData.flightsFiltered = res;
    });
  }

  public async initLinks(): Promise<void> {
    const evts = await this.linksService.getLinkLogBookEventById(this.data.logbook.objectId);

    this.linkedEvents = [];
    if (!evts) {
    } else {
      evts.forEach(evt => {
        if (this.events.findIndex(el => el.objectId === evt.objectId) === -1) {
          this.disabledLinks[evt.objectId] = true;
          this.linkedEventsArchived.push(evt);
        } else {
          this.linkedEvents.push(evt.objectId);
          this.linkedEventsCopy.push(evt.objectId);
        }

        this.linkedEventsDisplay.push(evt);
      });
      // tslint:disable-next-line: max-line-length
      if (
        this.data.logbook.event &&
        this.data.logbook.event.objectId &&
        evts.findIndex(el => el === this.data.logbook.event.objectId) === -1
      ) {
        this.linkedEvents.push(this.data.logbook.event.objectId);
        this.linkedEventsCopy.push(this.data.logbook.event.objectId);
        this.linkedEventsDisplay.push(this.data.logbook.event);
      }
      if (this.config.haveEventLink && this.linkedEvents.length === 0) {
        this.config.haveEventLink = false;
        //  this.config$.next(this.config);
      } else if (!this.config.haveEventLink && this.linkedEvents.length > 0) {
        this.config.haveEventLink = true;
        //  this.config$.next(this.config);
      }
    }
    const dcns = await this.linksService.getLinkDecisionForLogBookById(this.data.logbook.objectId);
    if (!dcns) {
      this.linkedDecisions = [];
    } else {
      this.linkedDecisions = [];
      dcns.forEach(dcn => {
        if (this.decisionItems.findIndex(el => el.objectId === dcn.objectId) === -1) {
          this.disabledLinks[dcn.objectId] = true;
          this.linkedDecisionsArchived.push(dcn);
        } else {
          this.linkedDecisions.push(dcn.objectId);
          this.linkedDecisionsCopy.push(dcn.objectId);
        }
        this.linkedDecisionsDisplay.push(dcn);

        if (this.config.haveDecisionLink && this.linkedDecisions.length === 0) {
          this.config.haveDecisionLink = false;
          //  this.config$.next(this.config);
        } else if (!this.config.haveDecisionLink && this.linkedDecisions.length > 0) {
          this.config.haveDecisionLink = true;
          //  this.config$.next(this.config);
        }
      });
    }
    if (this.config$) {
      this.config$.next(this.config);
    }
  }

  public changeDate(event): void {
    this.searchData.date = new Date(event);
    if (!this.searchData.date) {
      this.selectedFlight = undefined;
      this.searchText = undefined;
      this.selectedFlight = undefined;
      this.searchData.flights = [];
      this.autocompletePlaceHolder = this.translatePipe.transform(
        this.moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.PLACEHOLDER_CHOOSE_DATE',
      );
      return;
    }
    this.searchText = undefined;
    this.selectedFlight = undefined;
    this.searchData.flights = [];
    this.autocompletePlaceHolder = this.translatePipe.transform(
      this.moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.PLACEHOLDER_FLIGHT_LOADING',
    );
    // tslint:disable-next-line: max-line-length
    const date = moment.utc(
      `${this.searchData.date.getFullYear()}/${this.searchData.date.getMonth() + 1}/${this.searchData.date.getDate()}`,
      'YYYY/MM/DD',
    );
    this.flightService.getBySlot(date).then(res => {
      this.searchData.flights = res;
      this.searchData.flightsFiltered = res;
      if (!res.length) {
        this.autocompletePlaceHolder = this.translatePipe.transform(
          this.moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.PLACEHOLDER_NO_FLIGHT_FOUND',
        );
      } else {
        this.autocompletePlaceHolder = this.translatePipe.transform(
          this.moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.PLACEHOLDER_EXAMPLE',
        );
      }
    });
    this.querySearch('');
  }

  public querySearch(query): void {
    this.selectedFlight = null;
    this.searchData.flightNumber = null;
    this.searchData.flightsFiltered = cloneDeep(this.searchData.flights);
    if (!query || query.trim().length === 0) {
      this.searchData.flightsFiltered = cloneDeep(this.searchData.flights);
    } else {
      query = query.toLowerCase();
      this.searchData.flightsFiltered = cloneDeep(
        this.searchData.flights.filter(flight => {
          const dataToFind = flight.flightNumber.toLowerCase();
          if (dataToFind.includes(query)) {
            return flight;
          }
        }),
      );
    }
  }

  public resetFlight(): void {
    this.selectedFlight = null;
    this.searchData = {
      date: undefined,
      flights: [],
      flightsFiltered: [],
      flightNumber: null,
    };
  }

  public selectedItemChange(value: { option: { value: string } }): void {
    this.selectedFlight = this.searchData.flights.find(f => f.objectId === value.option.value);
    this.searchData.flightNumber = this.selectedFlight.flightNumber;
  }

  addEvent(applicability = null): void {
    const dialogRef = this.dialog.open(this.eventModal, {
      data: {
        notifications: this.notifications,
        fromLogbook: {
          attachments: this.logbook.attachments,
          tags: this.logbook.tags,
          text: this.logbook.contentText,
          applicability: applicability,
          acl: this.logbook.acl,
        },
        fromDecision: undefined,
        isUtc: this.isUtc,
        airportsCode: this.airportsCode,
      },
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then(newEvent => {
        if (newEvent) {
          const bufferEvent = cloneDeep(newEvent);
          this.events.push(bufferEvent);
          this.linkedEvents.push(bufferEvent.objectId);
          this.linkedEventsDisplay.push(bufferEvent);
        }
      });
  }

  public changelinkedEvent(selectedValues) {
    this.linkedEventsDisplay = [...intersectionWith(this.events, selectedValues, (i, j) => i.objectId === j), ...this.linkedEventsArchived];
  }

  public removeEvent(ev): void {
    this.linkedEvents = this.linkedEvents.filter(event => event !== ev.objectId);
    this.linkedEventsDisplay = this.linkedEventsDisplay.filter(event => event.objectId !== ev.objectId);
  }

  public updateLinkedGroup(e) {
    this.linkedGroup = e;
    // this.config.item.linkedGroup = this.linkedGroup;
  }

  public addDecision(event, applicability = null): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(this.occDecisionsModal, {
      data: {
        notifications: this.notifications,
        events: this.events,
        isUtc: this.isUtc,
        fromLogBook: {
          attachments: this.logbook.attachments,
          tags: this.logbook.tags,
          message: this.logbook.contentText,
          flight: this.selectedFlight,
          applicability: applicability,
          acl: this.logbook.acl,
        },
        airportsCode: this.airportsCode,
      },
    });
    dialogRef.afterClosed().subscribe(newDecision => {
      if (newDecision) {
        const bufferDecision = cloneDeep(newDecision);
        this.decisionItems.push(bufferDecision);
        this.linkedDecisions.push(bufferDecision.objectId);
        this.linkedDecisionsDisplay.push(bufferDecision);
      }
    });
  }

  public removeDecision(dcn): void {
    this.linkedDecisions = this.linkedDecisions.filter(decision => decision !== dcn.objectId);
    this.linkedDecisionsDisplay = this.linkedDecisionsDisplay.filter(decision => decision.objectId !== dcn.objectId);
  }

  public changelinkedDecision(selectedValues) {
    this.linkedDecisionsDisplay = [
      ...intersectionWith(this.decisionItems, selectedValues, (i, j) => i.objectId === j),
      ...this.linkedDecisionsArchived,
    ];
  }

  public saveLogbook(
    event?: {
      contentTextChanged?: boolean;
      notifications?: HolNotification[];
      nextInfo?: nextInfoType;
      notifyFunction?: HolNotifyFunction;
    },
    closeModal: boolean = false,
  ): void {
    this.loading = true;
    if (this.logbook.gocLogbook && !this.fromGOC) {
      this.deleteGocLogbook();
    }
    if (this.logbook.crewLogbook && !this.fromCREW) {
      this.deleteCrewLogbook();
    }
    if (this.logbook.erpLogbook && !this.fromERP) {
      this.deleteErpLogbook();
    }
    if (this.isCreate) {
      this.logbook.text = this.logbook.contentText;
      this.logbook.flight = this.selectedFlight;
      if (this.moduleConfig.config.displayToGoc) {
        const toGOCValue = this.selectedFlight ? true : false;
        this.logbook.toGOC = toGOCValue;
        this.duplicateToOtherModule = toGOCValue;
      }
      if (this.moduleConfig.config.displayToERP) {
        this.duplicateToOtherModule = this.toERP;
        this.logbook.toERP = this.toERP;
      }
      this.logbookService
        .create(this.logbook, event.notifications, this.context, this.duplicateToOtherModule)
        .then(async newLogbook => {
          if (this.isInSummary) {
            (newLogbook as EclLogbook).summary = (this.logbook as EclLogbook).summary;
            await this.saveSummary(newLogbook, this.isInBriefing, this.isInSummary);
          }
          Promise.all([
            this.linksService.saveLinkLogBookEvent(newLogbook.objectId, this.linkedEvents),
            this.linksService.saveLinkLogBookDecision(newLogbook.objectId, 'logBook', this.linkedDecisions),
            this.updateGroupSelected(newLogbook),
          ])
            .then(() => {
              this.linksService
                .checkLinks(this.linkedDecisions, [newLogbook.objectId], this.linkedEvents, true)
                .then(linkIds => {
                  this.occLinkItemsStoreManager.updateLinkedItems(linkIds);
                  this.dialogRef.close(newLogbook);
                })
                .catch(error => {
                  this.dialogRef.close();
                  console.error('Error creating Decision link', error);
                })
                .finally(() => {
                  this.groupService.fetchNewDataLogBookGroup();
                  this.loading = false;
                  this.dialogRef.close();
                });
            })
            .catch(error => {
              this.dialogRef.close();
              console.error('Error creating LogBook link', error);
            })
            .finally(() => {
              this.dialogRef.close();
            });
        })
        .catch(error => {
          this.dialogRef.close();
          console.error('Error creating OCCLogBook', error);
        });
    } else {
      let isModalNeed = false;
      if (event && event.contentTextChanged) {
        if (this.holOptionsService.isDefaultTimeMinuteForChangeCustomCreateDate()) {
          const defaultTime = this.holOptionsService.getDefaultTimeMinuteForChangeCustomCreateDate();
          if (defaultTime) {
            const now = new Date();
            const defaultMinutesAgo = new Date(now.getTime() - defaultTime * 60000); // 60000 ms = 1 minute

            if (this.data.logbook.createdAt < defaultMinutesAgo) {
              isModalNeed = true;
            }
          }
        }
      }
      if (isModalNeed) {
        const timeDialog = this.dialog.open(ConfirmationModalComponent, {
          data: {
            modalTitle: this.translatePipe.transform('COMMON.MODALS.DATE_CHANGE.TITLE'),
            modalContent: this.translatePipe.transform('COMMON.MODALS.DATE_CHANGE.CONTENT'),
            modalQuestion: this.translatePipe.transform('COMMON.MODALS.DATE_CHANGE.QUESTION'),
          },
        });
        timeDialog.afterClosed().subscribe(value => {
          if (value) {
            this.logbook.customCreatedAt = new Date();
          }
          this.saveOnBase(event, closeModal);
        });
      } else {
        this.saveOnBase(event, closeModal);
      }
    }
  }

  async saveSummary(savedLogbook: OclLogbook, isInBriefing: boolean, isInSummary?: boolean): Promise<void> {
    return;
  }

  public onChangeHolModalConfig(config: HolModalConfig) {
    if (config.transfertToDecision) {
      this.changeToDecision = config.transfertToDecision;

      this.saveLogbook({ contentTextChanged: false, notifications: config.notifications, nextInfo: null }, true);
    }
  }

  deleteGocLogbook(): void {
    this.logbookService.deleteGocLogbook(this.logbook);
    this.dialogRef.close();
  }

  deleteCrewLogbook(): void {
    this.logbookService.deleteCrewLogbook(this.logbook);
    this.dialogRef.close();
  }

  deleteErpLogbook(): void {
    this.logbookService.deleteErpLogbook(this.logbook);
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.hasCrisisInProgressSub.unsubscribe();
    this.config$.unsubscribe();

    const date = moment().subtract(1, 'hours').toDate();
    this.groupService.fetchNewDataLogBookGroup(date).then(r => {});
  }

  public isFastTyping() {
    return this.oclOptionsService.getFastTypingModal();
  }

  protected updateGroupSelected(logBook?: OclLogbook): Promise<any>[] {
    const listGroupPromise = [];

    // First we remove this item from all groups & then we add the item to the list of groups.
    this.logbookGroup.forEach(lgr => {
      if (this.data.logbook) lgr.items = lgr.items.filter(lg => lg.objectId !== this.data.logbook.objectId);
      if (this.linkedGroup.includes(lgr.objectId)) {
        if (logBook) {
          lgr.items.push(logBook);
        } else {
          lgr.items.push(this.data.logbook);
        }
      }
      listGroupPromise.push(this.groupService.save(lgr, 'LOGBOOK'));
    });
    return listGroupPromise;
  }

  private saveOnBase(
    event?: {
      contentTextChanged?: boolean;
      notifications?: HolNotification[];
      nextInfo?: nextInfoType;
      notifyFunction?: HolNotifyFunction;
    },
    closeModal: boolean = true,
  ) {
    if (event && event.contentTextChanged) {
      this.logbook.text = this.logbook.contentText;
      //  this.logbook.customCreatedAt = new Date();
    }
    if (this.moduleConfig.config.displayToGoc) {
      this.oldDuplicateToOtherModuleValue = this.logbook.toGOC;
      const toGOCValue = this.selectedFlight ? true : false;
      this.logbook.toGOC = toGOCValue;
      this.newDuplicateToOtherModuleValue = toGOCValue;
      this.updateDuplicateToOtherModuleFlightValue = this.logbook.flight !== this.selectedFlight;
    }
    if (this.moduleConfig.config.displayToERP) {
      this.oldDuplicateToOtherModuleValue = this.logbook.toERP;
      this.newDuplicateToOtherModuleValue = this.toERP;
      this.duplicateToOtherModule = this.toERP;
      this.logbook.toERP = this.toERP;
    }
    this.logbook.flight = this.selectedFlight;
    const linkedEventsToUpdate = difference(this.linkedEvents, this.linkedEventsCopy);
    const linkedEventsToDelete = difference(this.linkedEventsCopy, this.linkedEvents);
    const linkedDecisionsToUpdate = difference(this.linkedDecisions, this.linkedDecisionsCopy);
    const linkedDecisionToDelete = difference(this.linkedDecisionsCopy, this.linkedDecisions);
    if (this.changeToDecision) {
      this.oclLogbookDecisionService
        .transformLogbookToDecision(this.logbook, event.notifications, this.context)
        .catch(e => {
          console.error(e);
        })
        .finally(async () => {
          this.loading = false;
          this.dialogRef.close();
          await this.groupService.fetchNewDataLogBookGroup();
        });
      return;
    }
    this.logbookService
      .update(
        this.logbook,
        event.notifications,
        this.context,
        this.oldDuplicateToOtherModuleValue,
        this.newDuplicateToOtherModuleValue,
        this.updateDuplicateToOtherModuleFlightValue,
      )
      .then(async newLogBook => {
        if (newLogBook instanceof EclLogbook) {
          (newLogBook as EclLogbook).summary = (this.logbook as EclLogbook).summary;
          await this.saveSummary(newLogBook, this.isInBriefing, this.isInSummary);
        }
        Promise.all([
          this.linksService.saveLinkLogBookEvent(this.logbook.objectId, linkedEventsToUpdate),
          this.linksService.saveLinkLogBookDecision(this.logbook.objectId, 'logBook', linkedDecisionsToUpdate),
          this.linksService.deleteLinkLogBookEventById(this.logbook.objectId, linkedEventsToDelete),
          this.linksService.deleteLinkDecisionForLogBookById(this.logbook.objectId, linkedDecisionToDelete),
          this.updateGroupSelected(),
        ])
          .then(() => {
            try {
              this.linksService
                .checkLinks([], [], [], true)
                .then(linkIds => {
                  this.occLinkItemsStoreManager.updateLinkedItems(linkIds);
                  this.dialogRef.close(newLogBook);
                })
                .catch(error => {
                  this.dialogRef.close();
                  console.error('Error creating Decision link', error);
                })
                .finally(() => {
                  this.groupService.fetchNewDataLogBookGroup();
                  this.loading = false;
                  this.dialogRef.close();
                });
            } catch (e) {
              console.error(e);
              this.loading = false;
              this.dialogRef.close();
            }
          })
          .catch(error => {
            console.log(error);
            this.dialogRef.close();
          });
      })
      .catch(error => {
        console.log(error);
        this.dialogRef.close();
      })
      .finally(() => {
        this.dialogRef.close();
      });
  }
}
