<div class="datetime-input-container">
  <input
    type="text"
    [(ngModel)]="displayDateTime"
    (input)="onInputChange($event)"
    placeholder="JJ/MM/AAAA HH:MM"
    maxlength="16"
    autocomplete="off"
  />
  <span class="icon" (click)="togglePicker($event)">
    <span *ngIf="useUTC" class="utc-display"> UTC</span>
    <i class="material-icons">calendar_today</i>
  </span>
</div>
<div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>

<!-- Pop-up pour la sélection de la date et de l'heure -->
<div class="overlay" *ngIf="pickerOpen" (click)="closePicker()">
  <div class="picker-container" (click)="$event.stopPropagation()">
    <!-- Sélecteur de date -->
    <div class="date-picker">
      <div class="header">
        <button (click)="changeMonth(-1)">&lt;</button>
        <span>{{ months[currentMonth] }} {{ currentYear }}</span>
        <button (click)="changeMonth(1)">&gt;</button>
      </div>
      <div class="week-days">
        <div *ngFor="let day of weekDays">{{ day }}</div>
      </div>
      <div class="days">
        <div
          *ngFor="let day of calendarDays"
          [class.selected]="isSelectedDate(day)"
          [class.current]="isCurrentDate(day)"
          (click)="selectDate(day)"
        >
          {{ day.getDate() }}
        </div>
      </div>
    </div>
    <!-- Sélecteur d'heure -->
    <div class="time-picker">
      <label class="time-label"> Heure : </label>
      <input class="hour-input" type="time" [(ngModel)]="selectedTime" pattern="^([0-1]\d|2[0-3]):([0-5]\d)$" required />
    </div>
    <div class="action-bar">
      <button class="action action-refuse" (click)="closePicker()">
        <span class="material-symbols-outlined">cancel</span>
        <span>{{ 'COMMON.CANCEL' | translate | uppercase }}</span>
      </button>
      <div class="spacer"></div>
      <button class="action action-validate" (click)="confirm()">
        <span class="material-symbols-outlined">check</span>
        <span>{{ 'COMMON.SAVE' | translate | uppercase }}</span>
      </button>
    </div>
  </div>
</div>
