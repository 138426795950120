import { HolTag } from '../../common/models/hol-tag';
import { EclSummary } from '../../ecl/models/ecl-summary';

export interface IsPinned {
  isPinned?: boolean;
}

export interface HasTag {
  tags?: HolTag[];
}

export interface Done {
  done: boolean;
}

export interface InSummary {
  summary: EclSummary;
}

export interface ToErp {
  toERP: boolean;
}

export interface Internal {
  isPrivate: boolean;
}

export function isIsPinned(obj: any): obj is IsPinned {
  return 'isPinned' in obj;
}

// Gardien de type pour HasTag
export function isHasTag(obj: any): obj is HasTag {
  return 'tags' in obj;
}

// Gardien de type pour Done
export function isDone(obj: any): obj is Done {
  return 'done' in obj;
}

export function isInSummary(obj: any): obj is InSummary {
  return 'summary' in obj;
}

export function isToErp(obj: any): obj is ToErp {
  return 'toERP' in obj;
}

export function isInternal(obj: any): obj is Internal {
  return 'isPrivate' in obj;
}
