import { Component, Input, OnInit } from '@angular/core';
import { RolesService } from '../../services/roles.service';
import { Company } from '../../models/hol-user.model';

@Component({
  selector: 'app-acl-indicator-ecl',
  templateUrl: './acl-indicator-ecl.component.html',
  styleUrls: ['./acl-indicator-ecl.component.scss'],
})
export class AclIndicatorEclComponent implements OnInit {
  @Input() userCompany: Company[];
  companiesColors: any;
  companyWrite: Company[];
  companyReadOnly: Company[];

  constructor(private rolesService: RolesService) {}

  async ngOnInit() {
    this.companiesColors = await this.rolesService.getCompaniesColors();
    this.companyWrite = this.userCompany.filter(value => value.write);
    this.companyReadOnly = this.userCompany.filter(value => !value.write && value.read);
  }
}
