<div *ngIf="task" class="hol-clickable task {{ task.status }}" (click)="openModal($event)">
  <div class="order">
    <span class="label-order">{{ task.code }}{{ task.order }}</span>
  </div>

  <div class="title">
    <div class="title-wrapper" [innerHtml]="task.taskTitle"></div>
    <div class="attachments">
      <app-file-and-image-icons-display [attachments]="task.attachments"></app-file-and-image-icons-display>
    </div>
  </div>
</div>
