<span class="user-roles-container">
  <span
    *ngFor="let company of companyWrite"
    [style.background-color]="companiesColors[company.name]"
    class="user-role full"
    style="border-radius: 20px; color: white"
  >
    <span>{{ company.name }}</span>
    <mat-icon>edit</mat-icon>
  </span>
  <span
    *ngFor="let company of companyReadOnly"
    [style.background-color]="companiesColors[company.name]"
    class="user-role full"
    style="border-radius: 20px; color: white"
  >
    <span>{{ company.name }}</span>
    <mat-icon>remove_red_eye</mat-icon>
  </span>
</span>
