<div class="hol-box events-box">
  <div class="hol-box__header">
    <div class="header-title-container title-box-occ">
      <span class="word-break">{{ eventsTitle }}</span>
      <small *ngIf="isReadOnly || localHourOnly" class="hol-box__subtitle">{{
        moduleConfig.config.translateKey + '.COMMON.TIMES_LT' | translate
      }}</small>
      <small *ngIf="!isReadOnly && !localHourOnly" class="hol-box__subtitle">{{
        moduleConfig.config.translateKey + '.COMMON.TIMES_UTC' | translate
      }}</small>

      <div class="fill-space"></div>
      <div *ngIf="helperLinkList?.length">
        <button [matMenuTriggerFor]="menu" aria-label="helper link list" mat-icon-button>
          <mat-icon>emoji_objects</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button *ngFor="let helper of helperLinkList" mat-menu-item>
            <span (click)="goToTemplateHelper(helper.link)">{{ helper.name }}</span>
          </button>
        </mat-menu>
      </div>
      <button
        (click)="openGroupModal()"
        *ngIf="
          (userCanEditCards || userCanEditCards === undefined) && !isReadOnly && !modeLinkIsActivate && (!isItemsRequired || events.length)
        "
        aria-label="Add new group"
        class="md-icon-button md-raised hol-invert-no-border"
        mat-icon-button=""
        tabindex="-1"
      >
        <img src="../../../../assets/images/group-attach.svg" />
      </button>
      <button
        (click)="openAddModal()"
        *ngIf="(userCanEditCards || userCanEditCards === undefined) && !isReadOnly && !modeLinkIsActivate"
        aria-label="Add event"
        class="md-icon-button md-raised"
        mat-icon-button=""
        tabindex="-1"
      >
        <i class="fa fa-plus"></i>
      </button>
    </div>
    <div class="header-action-container">
      <mat-checkbox
        *ngIf="enableViewGroup"
        [(ngModel)]="isSetGroups"
        [disabled]="isReadOnly || modeLinkIsActivate"
        [labelPosition]="'after'"
        color="primary"
      >
        <span class="checkbox-label">{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.GROUP_CHECKBOX' | translate }}</span>
      </mat-checkbox>

      <div class="fill-space"></div>

      <div class="col scenario filter-scenario full-width">
        <mat-form-field *ngIf="!modeLinkIsActivate" class="mat-form-field scenario">
          <mat-label>{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.SCENARIO_FILTER' | translate }}</mat-label>
          <mat-select (selectionChange)="onScenarioChanged($event.value)" matNativeControl>
            <mat-option class="custom-option-departure" value="All">All</mat-option>
            <mat-option *ngFor="let s of originalScenarios" value="{{ s.objectId }}">
              {{ s.code + ' - ' + s.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="hol-box__content">
    <div class="iscroll-wrapper" iscroll>
      <div class="iscroll-scroller">
        <ul *ngIf="emptyEventsGroup.length" class="hol-list">
          <ng-container *ngFor="let emptyGroup of emptyEventsGroup; trackBy: trackByFunction">
            <li *ngIf="isSetGroups && emptyGroup.objectId" [@EnterLeave]="'flyIn'">
              <app-ocl-container-group-item
                [airportsCode]="airportsCode"
                [eclInfos]="eclInfos"
                [events]="events"
                [group]="emptyGroup"
                [groupsFromApi]="groupsFromApi"
                [isReadOnly]="isReadOnly"
                [linkedIds]="linkedIds"
                [type]="'EVENT'"
                [userCanEditCards]="userCanEditCards"
                [utcDisplay]="utcDisplay"
              ></app-ocl-container-group-item>
            </li>
          </ng-container>
        </ul>
        <ul *ngIf="eventsGrouped && eventsGrouped.length; else noResultEventOccTemplate" class="hol-list">
          <ng-container
            *ngIf="
              eventsGrouped
                | occSearchDashboard: searchCriteriasForPipe:context:scenarioId
                | aclGroupFilter: RolesService.companiesRolesFilter as eventsGroupedFilteredFromPipe
            "
          >
            <ng-container *ngFor="let eventGroup of eventsGroupedFilteredFromPipe; trackBy: trackByFunction">
              <li
                *ngIf="
                  eventGroup.displayInGroupViewStatus === 'ALWAYS_VISIBLE' ||
                  (isSetGroups && eventGroup.objectId) ||
                  (!isSetGroups && eventGroup.displayInGroupViewStatus === 'NOT_VISIBLE_IN_GROUP_VIEW')
                "
                [@EnterLeave]="'flyIn'"
                class="event-item"
              >
                <app-ocl-container-group-item
                  (updatedEvent)="onEventUpdated($event)"
                  [airportsCode]="airportsCode"
                  [eclInfos]="eclInfos"
                  [events]="events"
                  [group]="eventGroup"
                  [groupsFromApi]="groupsFromApi"
                  [isReadOnly]="isReadOnly"
                  [linkedIds]="linkedIds"
                  [mostCriticalNextInfo]="eventGroup.getMostCriticalNextInfo()"
                  [type]="'EVENT'"
                  [userCanEditCards]="userCanEditCards"
                  [utcDisplay]="utcDisplay"
                >
                </app-ocl-container-group-item>
              </li>
            </ng-container>

            <div *ngIf="eventsGroupedFilteredFromPipe.length === 0" class="no-result">
              <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.NO_RESULT' | translate }}</span>
            </div>
          </ng-container>
        </ul>
        <ng-template #noResultEventOccTemplate>
          <ul class="hol-list">
            <div class="no-result">
              <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.NO_RESULT' | translate }}</span>
            </div>
          </ul>
        </ng-template>
      </div>
    </div>
  </div>
</div>
