import { Directive, ElementRef, OnDestroy, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appHorizontalScroll]',
})
export class HorizontalScrollDirective implements OnDestroy, AfterViewInit {
  public btnNext;
  public btnPrev;
  public listenNext;
  public listenPrev;
  public listenScroll;
  public scrollWrapper;

  public SETTINGS = {
    scrollDistance: 0, // difference between client width and scroll width
    scrollWidth: 0, // scroll width
    clientWidth: 0, // client width
    scrollItemBounds: 0, // with of an item inside scroll wrapper (client witdh + 10px for margin)
  };

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  public ngAfterViewInit(): void {
    const container = this.element.nativeElement;
    this.btnPrev = container.children[0];
    this.scrollWrapper = container.children[1];
    this.btnNext = container.children[2];

    this.SETTINGS.scrollItemBounds = this.scrollWrapper.children[0].clientWidth + 10; // +10 is for margin
    this.SETTINGS.scrollWidth = this.scrollWrapper.scrollWidth;
    this.SETTINGS.clientWidth = this.scrollWrapper.clientWidth;
    this.SETTINGS.scrollDistance = this.scrollWrapper.scrollWidth - this.scrollWrapper.clientWidth;
    this.setButtonsVisibility();
    this.listenPrev = this.renderer.listen(this.btnPrev, 'click', () => this.onPrevFlights());
    this.listenNext = this.renderer.listen(this.btnNext, 'click', () => this.onNextFlights());
    this.listenScroll = this.renderer.listen(this.scrollWrapper, 'scroll', () => this.setButtonsVisibility());
  }

  public ngOnDestroy(): void {
    this.listenPrev();
    this.listenNext();
    this.listenScroll();
  }

  public setButtonsVisibility(): void {
    if (!this.isOverflown(this.scrollWrapper)) {
      this.renderer.addClass(this.btnPrev, 'hidden');
      this.renderer.addClass(this.btnNext, 'hidden');
      return;
    }

    if (this.scrollWrapper.scrollLeft === 0) {
      this.renderer.addClass(this.btnPrev, 'hidden');
    } else {
      this.renderer.removeClass(this.btnPrev, 'hidden');
    }

    if (this.scrollWrapper.scrollLeft === this.SETTINGS.scrollDistance) {
      this.renderer.addClass(this.btnNext, 'hidden');
    } else {
      this.renderer.removeClass(this.btnNext, 'hidden');
    }
  }

  public onPrevFlights(): void {
    if (this.scrollWrapper.scrollLeft - this.SETTINGS.scrollDistance >= 0) {
      this.scrollWrapper.scrollLeft += -this.SETTINGS.scrollItemBounds;
    } else {
      this.scrollWrapper.scrollLeft = 0;
    }
  }

  public onNextFlights(): void {
    if (this.SETTINGS.clientWidth - this.scrollWrapper.scrollLeft > this.SETTINGS.scrollItemBounds) {
      this.scrollWrapper.scrollLeft += this.SETTINGS.scrollItemBounds * 2;
    } else {
      this.scrollWrapper.scrollLeft = this.SETTINGS.scrollDistance;
    }
  }

  public isOverflown(element): boolean {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }
}
