import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HolUser} from "../../models/hol-user.model";
import {RequestService} from "../../services/request.service";

@Component({
  selector: 'app-confirmation-by-password-modal',
  templateUrl: './confirmation-by-password-modal.component.html',
  styleUrls: ['./confirmation-by-password-modal.component.scss']
})
export class ConfirmationByPasswordModalComponent implements OnInit {
  public modalTitle: string;
  public modalContent: string;
  private currentUser: HolUser;
  private userToReset: HolUser;

  public wrongCurrentPassword: boolean = false;

  passwordForm: FormGroup = new FormGroup({
    currentPasswordControl: new FormControl('', [Validators.required]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<ConfirmationByPasswordModalComponent>,
    private requestService: RequestService,
    @Inject('CONSTANTS') private CONSTANTS,
  ) {
    this.modalTitle = data.modalTitle;
    this.modalContent = data.modalContent;
    this.currentUser = data.currentUser;
    this.userToReset = data.userToReset;
  }

  ngOnInit(): void {
  }

  onTypePassword() {
    if (this.wrongCurrentPassword) {
      this.wrongCurrentPassword = false;
      this.passwordForm.controls.currentPasswordControl.setErrors(null);
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  valid(): void {
    // Try to log in user with given "current password" to make sure it's the same
    Parse.User.logIn(this.currentUser.username, this.passwordForm.controls.currentPasswordControl.value)
      .then(() => {
        this.dialogRef.close(true);
      })
      .catch(() => {
        // given 'current password' is not the actual user password, show error
        this.wrongCurrentPassword = true;
        this.passwordForm.controls.currentPasswordControl.setErrors({ incorrect: true });
      });

  }

}
