import { OclLogbook } from '../../ocl/models/ocl-logbook.model';
import { HolTag } from '../../common/models/hol-tag';
import { EclFunction } from './ecl-function';
import { EclCrisis } from './ecl-crisis';
import { EclSummary } from './ecl-summary';
import { InSummary } from '../../ocl/models/ocl-interface.model';

export class EclLogbook extends OclLogbook implements InSummary {
  public function: EclFunction;
  public crisis: EclCrisis;
  public summary: EclSummary;

  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject, tags);
    if (!parseObject) {
      return;
    }
    this.function = new EclFunction(parseObject.get('function'));
    this.crisis = new EclCrisis(parseObject.get('crisis'));
  }
}
