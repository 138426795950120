import { Component, Input, OnInit } from '@angular/core';
import { ModuleConfigService } from '../../../../common/services/module-config/module-config.service';
import { OclTimeSlot } from '../../../services/ocl-history-service/ocl-history.service';
import { RolesService } from '../../../../common/services/roles.service';

@Component({
  selector: 'app-ocl-history-time-slot',
  templateUrl: './ocl-history-time-slot.component.html',
  styleUrls: ['./ocl-history-time-slot.component.scss'],
})
export class OclHistoryTimeSlotComponent implements OnInit {
  @Input()
  public isNewDay: boolean;
  @Input()
  public isReadOnly: boolean;
  @Input()
  public isVeryFirstDay: boolean;
  @Input()
  public timeSlot: OclTimeSlot;

  public isUtc: boolean;

  public constructor(public moduleConfig: ModuleConfigService) {}

  public ngOnInit() {
    this.isUtc = this.moduleConfig.config.localHours ? false : !this.isReadOnly;
  }

  public trackByFunction(index, item): any {
    return item && item.objectId ? item.objectId : null;
  }

  protected readonly RolesService = RolesService;
}
