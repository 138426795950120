<div
  [ngClass]="{
    elapsed: isElapsed(nextInfo.nextInfoTime),
    'almost-elapsed': isAlmostElapsed(nextInfo.nextInfoTime),
    'hol-success': nextInfo.nextInfoDone
  }"
  class="next-info"
>
  <app-datetime-picker [useUTC]="isUTC" (dateTimeSelected)="onDateTimeSelected($event)" [defaultDate]="nextInfoDate"></app-datetime-picker>

  <!--
    <mat-form-field appearance="outline" class="date-input">
      <input
        (ngModelChange)="callSaveInfo()"
        [(ngModel)]="nextInfoDate"
        [disabled]="isReadOnly"
        (dateInput)="callSaveInfo()"
        [matDatepicker]="picker"
        required
        matInput
        placeholder="DD/MM/YYYY"
      />
      <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    &nbsp;&nbsp;
    <mat-form-field appearance="outline" class="time-input">
      <input
        required
        (ngModelChange)="callSaveInfo()"
        (input)="callSaveInfo()"
        [(ngModel)]="nextInfoTime"
        [disabled]="isReadOnly"
        matInput
        type="time"
      />
      <mat-datepicker-toggle matSuffix></mat-datepicker-toggle>
    </mat-form-field>
    <div class="utc">
      <span *ngIf="isUTC">UTC</span>
    </div>

    -->
  <div *ngIf="!isReadOnly && hasDoneBtn" class="col-done">
    <button
      (click)="markAsDone($event)"
      [disabled]="isReadOnly || (!nextInfoTime && !nextInfoDate)"
      aria-label="mark as done"
      class="mat-icon-button mark-done-button"
    >
      <i class="icon icon-check"></i>
    </button>
  </div>
</div>
