import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddNextInfoModalComponent } from '../add-next-info-modal/add-next-info-modal.component';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent {
  modalTitle: string;
  modalContent: string;
  modalType: string; // error / warning / info
  inProgress: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, public dialogRef: MatDialogRef<AddNextInfoModalComponent>) {
    this.modalContent = data.modalContent;
    this.modalTitle = data.modalTitle;
    this.modalType = data.modalType;
    this.inProgress = data.inProgress ? data.inProgress : false;
  }

  close() {
    this.dialogRef.close();
  }
}
