<div class="hol-dialog-wrapper add-next-info-modal">
  <mat-toolbar class="mat-toolbar">
    <div class="title" mat-dialog-title>
      <i class="fa fa-plus"></i>
      <span [innerHTML]="title"></span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <div class="content-grid">
      <form [formGroup]="formGroup" class="full-width">
        <div class="hol-dialog-section full-width">
          <mat-form-field class="full-width description-textarea">
            <mat-label>{{ 'COMMON.MODALS.NEXT_INFO.DESCRIPTION' | translate }}</mat-label>
            <textarea
              class="description"
              formControlName="message"
              autofocus
              matInput
              [maxLength]="nextInfoLimit"
              type="text"
              mat-autosize
            ></textarea>
          </mat-form-field>
        </div>
        <div class="hol-dialog-section inputs-date">
          <div class="hol-spaced-line">
            <span class="next-info-libelle">
              <strong>{{ 'COMMON.MODALS.NEXT_INFO.EXPECTED' | translate }}</strong>
            </span>
            <app-hol-next-info
              [nextInfo]="{ nextInfoTime: nextInfoTime }"
              [hasDoneBtn]="false"
              [isUTC]="isUtc"
              (saveNextInfo)="saveNextInfo($event)"
              (isValideDate)="isValideDate($event)"
            >
            </app-hol-next-info>
          </div>
        </div>
        <div class="hol-dialog-section full-width">
          <app-section-with-title [icon]="'demography'" [titleKey]="'Note'">
            <app-note-markdown
              (isNoteSave)="isNoteSave($event)"
              [attachment]="attachments.note"
              [attachments]="attachments"
              [context]="context"
              [noteFile]="attachments.noteFile"
            ></app-note-markdown>
          </app-section-with-title>
          <app-section-with-title [icon]="'attachment'" [titleKey]="'COMMON.MODALS.BUTTONS.ATTACHMENTS' | translate">
            <app-file-and-image-upload
              [attachments]="attachments"
              [context]="{
                module: context.module,
                category: context.category,
                htmlTitle: context.htmlTitle,
                htmlDate: context.htmlDate,
                htmlScenario: context.htmlScenario,
                htmlTemplate: 'C',
                htmlNextInfo: gethtmlNextInfo()
              }"
            >
            </app-file-and-image-upload>
          </app-section-with-title>
        </div>
        <div class="hol-dialog-section notifications" *ngIf="notifications.length">
          <label>{{ 'COMMON.SEND_NOTIFICATIONS' | translate }}</label>
          <app-notification-table [notifications]="notifications" [acl]="acl"></app-notification-table>
        </div>
      </form>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="action-bar">
      <div class="spacer"></div>
      <button
        [disabled]="formGroup.status !== 'VALID' || !noteSaved || !isValidNextInfoDate"
        (click)="save()"
        class="action action-validate"
      >
        <mat-icon>done</mat-icon>
        <span>{{ 'DASHBOARD.MODAL.SUBMIT' | translate | uppercase }}</span>
      </button>
    </div>
  </mat-dialog-actions>
</div>
