import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { ErpVisiblityStatus } from '../../models/erp-crisisTask';

@Component({
  selector: 'app-erp-task-visibility',
  templateUrl: './erp-task-visibility.component.html',
  styleUrls: ['./erp-task-visibility.component.scss'],
})
export class ErpTaskVisibilityComponent implements OnInit {
  @Input()
  public canChangeVisibility: boolean;
  @Input() public isInErdTeam: boolean;
  @Input() public disabledVisiblitySelector: boolean;
  @Input() public frozenByErd = false;
  @Input() public showFrozenByErd: boolean;
  @Input() public disableFrozenByErd: boolean;
  @Input() public isCreationMode = false;
  @Input() public currentVisibility: ErpVisiblityStatus;
  @Output()
  public updateCurrentVisibility: EventEmitter<ErpVisiblityStatus> = new EventEmitter();
  @Output()
  public updateFrozenByErd: EventEmitter<boolean> = new EventEmitter();

  visibilityValues = [
    { name: 'ALL', value: ErpVisiblityStatus.all },
    { name: 'GROUP', value: ErpVisiblityStatus.group },
    { name: 'ERD', value: ErpVisiblityStatus.erd },
  ];
  @Input() required: boolean = false;

  constructor(public moduleConfig: ModuleConfigService) {}

  ngOnInit(): void {
    if (!this.isCreationMode && !this.currentVisibility) {
      this.currentVisibility = ErpVisiblityStatus.all;
    }
  }

  changeVisibility(event): void {
    this.currentVisibility = event;
    this.updateCurrentVisibility.emit(event);
  }

  changeFrozenByErd(event): void {
    this.frozenByErd = event;
    this.updateFrozenByErd.emit(event);
  }
}
