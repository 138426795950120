<span class="task-label" [ngClass]="{ required: required && !currentVisibility }">
  {{ moduleConfig.config.translateKey + '.VISIBILITY.TITLE' | translate }} <span class="hol-warning" *ngIf="isCreationMode">*</span>
</span>
<mat-form-field>
  <mat-select
    (ngModelChange)="changeVisibility($event)"
    [ngModel]="currentVisibility"
    [ngModelOptions]="{ standalone: true }"
    [disabled]="disabledVisiblitySelector"
    matNativeControl
  >
    <mat-option *ngFor="let visibilityValue of visibilityValues" [value]="visibilityValue.value">
      {{ moduleConfig.config.translateKey + '.VISIBILITY.' + visibilityValue.name | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>
<div class="checkbox">
  <ng-container *ngIf="showFrozenByErd">
    <mat-checkbox
      [ngModel]="frozenByErd"
      (ngModelChange)="changeFrozenByErd($event)"
      [ngModelOptions]="{ standalone: true }"
      [labelPosition]="'before'"
      [disabled]="disableFrozenByErd"
      color="primary"
    >
      <span>{{ moduleConfig.config.translateKey + '.VISIBILITY.FROZEN_BY_ERD' | translate }}</span>
    </mat-checkbox>
  </ng-container>
</div>
