<app-loading *ngIf="loading" class="full-loader"></app-loading>

<!--
<div class="hol-dialog-wrapper hol-modal">
  <mat-toolbar>
    <div mat-dialog-title>
      <i *ngIf="isCreate" class="fa fa-plus"></i>
      <span *ngIf="isCreate" class="title">{{
          moduleConfig.config.translateKey + '.DASHBOARD.GLOBAL_INSTRUCTION.MODAL.ADD_INSTRUCTION' | translate
        }}</span>
       <span *ngIf="!isCreate" class="title"
      >{{ moduleConfig.config.translateKey + '.DASHBOARD.GLOBAL_INSTRUCTION.MODAL.TITLE_INSTRUCTION' | translate }}
        {{ globalInstuction.createdAt | time: !isReadOnly }}</span
      >
    </div>
    <app-acl-selector
      *ngIf="!isReadOnly"
      [formGroup]="form"
      [isCompact]="true"
      [limitTo]="limitTo && limitTo.acl"
      [moduleName]="moduleConfig.config.moduleName"
      [object]="globalInstuction"
      [readOnly]="isReadOnly"
      [startAllSelected]="isCreate"
      alwaysOneSelected="true"
      class="acl-full-width"
    ></app-acl-selector>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <form [formGroup]="form" class="content-grid-container">
      <div class="section section-title">
        <div class="icon">
          <span class="material-symbols-outlined iconsize">edit</span>
        </div>
        <div class="title">Description de la décision</div>
        <div class="empty">&nbsp;</div>
        <div class="content">
          <div *ngIf="isCreate" class="hol-dialog-section">
            <mat-form-field *ngIf="isCreate" class="full-width description-form-field">
          <textarea
            formControlName="description"
            mat-autosize
            matInput
            maxlength="200"
            placeholder="{{ 'GOC.MODALS.COMMON.DESCRIPTION' | translate }}"
            type="text"
          >
          </textarea>
            </mat-form-field>
          </div>

          <mat-form-field *ngIf="!isCreate" class="full-width">
            <mat-label>{{ moduleConfig.config.translateKey + '.DASHBOARD.GLOBAL_INSTRUCTION.MODAL.TITLE_PLACEHOLDER' | translate }}</mat-label>
            <textarea class="hol-modal-title" formControlName="description" mat-autosize matInput maxlength="200"
                      type="text"></textarea>
          </mat-form-field>
        </div>
      </div>


      <ng-container [ngTemplateOutlet]="template_TAG"></ng-container>


      <div class="hol-dialog-section">
        <app-file-and-image-upload
          [attachments]="attachments"
          [context]="{
            module: moduleConfig.config.translateKey,
            category: 'GLOBALCONSIGNE',
            htmlTitle: form.value.description,
            htmlDate: globalInstuction?.createdAt,
            htmlTemplate: 'A'
          }"
          [disabled]="isReadOnly"
        >
        </app-file-and-image-upload>
      </div>
      <div *ngIf="notifications && notifications.length > 0" class="hol-dialog-section">
        <div class="modal-column">
          <label>{{ 'DASHBOARD.MODAL.NOTIFICATION_LABEL' | translate }}</label>
          <app-notification-table
            (saveNotifications)="saveNotifications($event)"
            [acl]="globalInstuction.acl"
            [context]="context"
            [notifications]="notifications"
            [unselectAll]="true"
          ></app-notification-table>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="bottom-modal">


      <div class="hol-dialog-section">
      </div>
    </div>
    <div class="hol-modal__actions">
      <button (click)="archive()" *ngIf="!isReadOnly && !isCreate && !globalInstuction.archived" color="warn"
              mat-stroked-button>
        <i class="icon fa fa-archive"></i>
        {{ 'COMMON.BUTTON.ARCHIVE' | translate }}
      </button>
      <div class="fill-space"></div>
      <button (click)="save()" *ngIf="!isReadOnly" [disabled]="form.invalid" class="hol-validate-btn" mat-raised-button>
        <mat-icon>done</mat-icon>
        <span *ngIf="isCreate">{{ 'COMMON.BUTTON.ADD' | translate | uppercase }}</span>
        <span *ngIf="!isCreate">{{ 'COMMON.BUTTON.OK' | translate | uppercase }}</span>
      </button>
      <button *ngIf="isReadOnly" class="hol-validate-btn" mat-dialog-close mat-raised-button>
        <mat-icon>done</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</div>

-->
<app-hol-modal
  (saveItem)="save()"
  *ngIf="config$ | async as config"
  [canArchive]="false"
  [canEditTitle]="!isCreate"
  [canUpdateFromOtherModule]="false"
  [config$]="config$"
  [config]="config"
  [contentTextLength]="200"
  [context]="context"
  [isCreate]="isCreate"
  [isHistory]="false"
  [isReadOnly]="isReadOnly"
  [item]="globalInstuction"
  [limitTo]="limitTo"
  [notifications]="notifications"
  [type]="'GLOBALINSTRUCTION'"
>
  <ng-container class="template_GROUP_LINK">
    <!-- LINKED GROUP -->
    <app-group-selector
      (groupChange)="updateLinkedGroup($event)"
      [isReadOnly]="isReadOnly"
      [linkedGroup]="linkedGroup"
      [listGroup]="globalInstGroup"
      [type]="'globalInstuction'"
    ></app-group-selector>
  </ng-container>
</app-hol-modal>

<ng-template #template_TAG>
  <div class="section section-title">
    <div class="icon">
      <span class="material-symbols-outlined iconsize">shoppingmode</span>
    </div>
    <div class="title">{{ 'COMMON.MODALS.BUTTONS.TAG' | translate }}</div>
    <div class="empty">&nbsp;</div>
    <div class="content">
      <app-tag-filter
        [(selectedTags)]="tags"
        [canAddTag]="true"
        [isCabin]="true"
        [isFilterMode]="false"
        [readOnly]="isReadOnly"
        [showTitle]="false"
        [viewOnly]="isReadOnly"
      >
      </app-tag-filter>

      <app-tag-selector [(selectedTags)]="tags" [readOnly]="isReadOnly" class="tag-selector"></app-tag-selector>
    </div>
  </div>
</ng-template>
