import { Pipe, PipeTransform } from '@angular/core';
import { HolTag } from '../../models/hol-tag';

@Pipe({
  name: 'tagsFilter',
})
export class TagsFilterPipe implements PipeTransform {
  transform(values: { tags: HolTag[] }[], tags?: HolTag[]): any {
    if (!tags || !tags.length || !values) {
      return values;
    }
    values = values.filter(item => {
      return item.tags.some(t => {
        return tags.some(t2 => t2.objectId === t.objectId);
      });
    });
    return values;
  }
}
