import { take } from 'rxjs/operators';
import { RolesService } from './../../../common/services/roles.service';
import { HelperService } from './../../../common/services/helper.service';
import { OclLogbook } from 'src/app/ocl/models/ocl-logbook.model';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { EclSmsService } from './../ecl-sms-service/ecl-sms.service';
import { EclMailService } from './../ecl-mail-service/ecl-mail.service';
import { Inject, Injectable } from '@angular/core';
import { OclLogbookService } from '../../../ocl/services/ocl-logbook-service/ocl-logbook.service';
import { RequestService } from '../../../common/services/request.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { GocFlightLogbookService } from '../../../goc/services/goc-flight-logbook-service/goc-flight-logbook.service';
import { OclLogBooksStoreManager } from '../../../ocl/store/logbooks/ocl-log-books-store-manager.service';
import { EclOptionsService } from '../ecl-options-service/ecl-options.service';
import { EclHistoryService } from '../ecl-history-service/ecl-history.service';
import { EclLogbookTagService } from '../ecl-logbook-tag-service/ecl-logbook-tag.service';
import { EclModuleConfigService } from '../ecl-module-config/ecl-module-config.service';
import { UserService } from 'src/app/common/services/user.service';
import { FilesService } from 'src/app/common/services/files.service';
import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';
import { EclFunctionStoreManager } from '../../store/function/function.store-manager';
import { EclLogbook } from '../../models/ecl-logbook';
import { HolTag } from '../../../common/models/hol-tag';
import { EclCrisis } from '../../models/ecl-crisis';
import { EclFunctionState } from '../../ecl.model';
import { EclCrisisStoreManager } from '../../store/crisis/crisis.store-manager';

@Injectable({
  providedIn: 'root',
})
export class EclLogbookService extends OclLogbookService {
  // tslint:disable:variable-name
  protected ParseLogbook = Parse.Object.extend('ECLLogBook');
  protected ParseOccEvents = Parse.Object.extend('ECLEvents');
  protected ParseLogbookTag = Parse.Object.extend('ECLLogBookTag');
  protected ParseTag = Parse.Object.extend('ECLTag');
  protected ParseEvents = Parse.Object.extend('ECLEvents');
  protected ParseErpLogbook = Parse.Object.extend('GDCCrisisNews');
  protected ParseEclFunction = Parse.Object.extend('ECLFunction');
  protected ParseEclCrisis = Parse.Object.extend('ECLCrisis');
  protected eclFunctionState: EclFunctionState;
  protected eclOpenCrisis: EclCrisis;
  private ParseCrisis = Parse.Object.extend('GDCCrisis');

  // tslint:enabled

  public constructor(
    @Inject('$rootScope') protected $rootScope,
    protected requestService: RequestService,
    protected userService: UserService,
    protected optionsService: EclOptionsService,
    protected historyService: EclHistoryService,
    protected parseMapper: ParseMapperService,
    protected logBookTagService: EclLogbookTagService,
    protected gocLogbookService: GocFlightLogbookService,
    protected occLogBooksStoreManager: OclLogBooksStoreManager,
    public moduleConfig: EclModuleConfigService,
    protected notificationsService: NotificationsService,
    protected smsService: EclSmsService,
    protected mailService: EclMailService,
    public commonStoreManager: CommonStoreManager,
    private helperService: HelperService,
    private rolesService: RolesService,
    protected readonly filesService: FilesService,
    protected markdownService: MarkdownService,
    protected eclFunctionStoreManager: EclFunctionStoreManager,
    protected eclCrisisStoreManager: EclCrisisStoreManager,
  ) {
    super(
      $rootScope,
      requestService,
      userService,
      optionsService,
      historyService,
      parseMapper,
      logBookTagService,
      gocLogbookService,
      occLogBooksStoreManager,
      moduleConfig,
      notificationsService,
      smsService,
      mailService,
      commonStoreManager,
      filesService,
      markdownService,
    );
    eclFunctionStoreManager.$eclFunctionState.subscribe(eclFs => (this.eclFunctionState = eclFs));
    // this.commonStoreManager.eclCrisis.subscribe(c => (this.eclOpenCrisis = c));
    this.eclCrisisStoreManager.$eclSelectedCrisis.subscribe(crisis => (this.eclOpenCrisis = crisis));
  }

  protected async duplicateLogbookToOtherModule(logbook: OclLogbook) {
    const crisis = await this.commonStoreManager.crisis.pipe(take(1)).toPromise();
    const parseErpLogbook = new this.ParseErpLogbook();
    parseErpLogbook.set('eclLogbook', new this.ParseLogbook({ id: logbook.objectId }));

    const companies = this.helperService.parseACL(logbook.acl);
    const acl: Parse.ACL = await this.rolesService.getACLForCompanies(['ERP'], companies);
    parseErpLogbook.setACL(acl);
    this.setAdditionalFields(logbook, parseErpLogbook);
    parseErpLogbook.set('crisis', new this.ParseCrisis({ id: crisis.objectId }));
    this.requestService.performSaveAllQuery(parseErpLogbook).then();
  }

  protected deleteDuplicateLogbookFromModule(logbook: OclLogbook) {
    const parseLogbook = new this.ParseLogbook({ id: logbook.objectId });
    const query = new Parse.Query(this.ParseErpLogbook);
    query.equalTo('eclLogbook', parseLogbook);

    this.requestService.performFirstQuery(query).then(result => {
      if (result) {
        const parseErpLogbook = new this.ParseErpLogbook({ id: result.id });
        this.setAdditionalFields(logbook, parseErpLogbook);
        this.requestService.performDestroyQuery(parseErpLogbook).then();
      }
    });
  }

  protected newLogbook(parseObject?: Parse.Object, tags?: Parse.Object[]): EclLogbook {
    return new EclLogbook(parseObject, tags && tags.map(t => new HolTag(t.get('tag'))));
  }

  protected setAdditionalFields(logbook: OclLogbook, parseLogbook: Parse.Object) {
    super.setAdditionalFields(logbook, parseLogbook);
    const l = logbook as EclLogbook;
    // Set existing function or new from selected user function
    if (this.eclFunctionState.functions.length) {
      const objectId =
        (l.function && l.function.objectId) ||
        (this.eclFunctionState.selectedUserFunction && this.eclFunctionState.selectedUserFunction.objectId);
      parseLogbook.set(
        'function',
        new this.ParseEclFunction({
          id: objectId,
        }),
      );
    }
    parseLogbook.set(
      'crisis',
      new this.ParseEclCrisis({
        id: this.eclOpenCrisis.objectId,
      }),
    );
  }

  protected getAdditionnalQueries(query, queryPinned, filterDataStartDate): Parse.Query {
    query.include('function');
    return super.getAdditionnalQueries(query, queryPinned, filterDataStartDate);
  }
}
