export function getFileType(url: string): TypeFile {
  // Expression régulière pour détecter une URL standard (sans extension de fichier)
  const urlPattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
  const urlObjet = new URL(url);

  if (urlPattern.test(urlObjet.pathname) && !urlObjet.pathname.includes('.')) {
    return TypeFile.url; // C'est une URL sans extension de fichier
  }

  let extension = urlObjet.pathname.split('.').pop();
  if (extension) {
    extension = extension.toLowerCase();
  }
  switch (extension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'svg':
    case 'webp':
      return TypeFile.image;
    case 'pdf':
      return TypeFile.pdf;
    case 'doc':
    case 'docx':
      return TypeFile.word;
    case 'xls':
    case 'xlsx':
      return TypeFile.excel;
    case 'zip':
    case 'rar':
    case '7z':
    case 'tar':
    case 'gz':
    case 'bz2':
    case 'cab':
    case 'iso':
      return TypeFile.archive;
    default:
      return TypeFile.other;
  }
}

export enum TypeFile {
  image = 'image',
  pdf = 'pdf',
  url = 'url',
  word = 'word',
  excel = 'excel',
  other = 'other',
  archive = 'archive',
}
