import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';

import { MailSenderService } from 'src/app/common/services/mail/mail-sender.service';
import { MailTemplateService } from 'src/app/common/services/mail/mail-template.service';

import { OclMailService } from '../../../ocl/services/ocl-mail-service/ocl-mail.service';
import { EclAnnouncement } from '../../models/ecl-annoucement';
import { EclModuleConfigService } from '../ecl-module-config/ecl-module-config.service';
import { EclCrisis } from '../../models/ecl-crisis';
import { HolUser } from '../../../common/models/hol-user.model';
import { take } from 'rxjs/operators';
import { CommonStoreManager } from '../../../common/store/common.store-manager';
import { HolUserWithFunctions } from '../../../erp/services/erp-users.service';
import { EclUsersService } from '../ecl-users-service/ecl-users.service';
import { map, uniqBy } from 'lodash';
import { EclCrisisDirectorStoreManager } from '../../store/crisis-director/ecl-crisis-director.store-manager';
import { HolManagersSelectors } from '../../../common/store/hol-managers.selectors';
import { TranslatePipe } from '../../../common/pipes/translate/translate.pipe';
import { mergeDirectorsAndUsers } from '../../common/user-utils';
import { EclFunction } from '../../models/ecl-function';

@Injectable({
  providedIn: 'root',
})
export class EclMailService extends OclMailService {
  constructor(
    protected readonly mailSenderService: MailSenderService,
    protected translatePipe: TranslatePipe,
    @Inject('$translate') protected $translate,
    @Inject('CONSTANTS') protected CONSTANTS,
    protected mailTemplateService: MailTemplateService,
    protected moduleConfig: EclModuleConfigService,
    private commonStoreManager: CommonStoreManager,
    private usersService: EclUsersService,
    private eclCrisisDirectorStoreManager: EclCrisisDirectorStoreManager,
    private managerSelector: HolManagersSelectors,
  ) {
    super(mailSenderService, translatePipe, $translate, CONSTANTS, mailTemplateService, moduleConfig);
    this.$translate.onReady(() => {
      this.SENDER_SUFFIX = this.translatePipe.transform('MAIL.ECL.SENDER_SUFFIX') || 'ECL';
    });
  }

  sendECLAnnouncementMail(eclAnnouncement: EclAnnouncement, user: any, mailsToSend: string[]) {
    if (!eclAnnouncement) {
      console.warn('EclMailService::sendECLAnnouncement: crisisAnnouncement empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('EclMailService::sendECLAnnouncement: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: map(mailsToSend, m => {
          return { email: m };
        }),
        subject: this.translatePipe.transform('MAIL.ECL.NEW_ANNOUNCEMENT.SUBJECT', { user }),
        contentHtml: this.getECLsAnnouncementMail(eclAnnouncement),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      true,
      true,
    );
  }

  async sendNewCrisisEmails(crisis: EclCrisis, userToNotifyFromUser: string[]) {
    // We're sending emails for people:
    // 1. Who has crisis director rights
    // OR
    // 2. Whose functions are included in crisis.crisisType.functionsToNotify
    return Promise.all([
      this.eclCrisisDirectorStoreManager.$eclRealCrisisDirectorsWithFunctions().pipe(take(1)).toPromise(),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
      this.usersService.getUsersWithFunctionsForCrisis(crisis),
    ]).then(([crisisDirectors, currentUser, usersToNotify]) => {
      let users = mergeDirectorsAndUsers(crisisDirectors, usersToNotify);
      if (userToNotifyFromUser) {
        users = users.filter(us => userToNotifyFromUser.includes(us.userId));
      }
      for (const user of users) {
        this.sendNewCrisisEmailToUser(crisis, user, currentUser);
      }
    });
  }

  async sendCloseCrisisMail(crisis: EclCrisis) {
    // We're sending emails for people:
    // 1. Who has crisis director rights
    // OR
    // 2. Whose functions are included in crisis.crisisType.functionsToNotify
    const [directorsToNotify, usersToNotify] = await Promise.all([
      this.eclCrisisDirectorStoreManager.$eclRealCrisisDirectorsWithFunctions().pipe(take(1)).toPromise(),
      this.usersService.getUsersWithFunctionsForCrisis(crisis),
    ]);

    await this.mailSenderService.sendMail(
      {
        recipients: mergeDirectorsAndUsers(directorsToNotify, usersToNotify).map(user => {
          return { email: user.email };
        }),
        subject: this.translatePipe.transform('MAIL.ECL.CRISIS.CRISIS_OVER.SUBJECT', { crisisMainTitle: crisis.mainTitle }),
        contentText: this.translatePipe.transform('MAIL.ECL.CRISIS.CRISIS_OVER.CONTENT_TEXT'),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      false,
      true,
    );
  }

  async sendMailAddMemberOfFunction(user: HolUser, crisis: EclCrisis, newFunction: EclFunction) {
    const [directorsToNotify, usersToNotify, currentUser] = await Promise.all([
      this.eclCrisisDirectorStoreManager.$eclRealCrisisDirectorsWithFunctions().pipe(take(1)).toPromise(),
      this.usersService.getUsersWithFunctionsForCrisis(crisis),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
    ]);

    let msg = this.translatePipe.transform('MAIL.ECL.CRISIS.MEMBER_ADD.CONTENT_TEXT', {
      functionName: newFunction.title,
      user: currentUser.fullName,
    });

    msg += this.translatePipe.transform('MAIL.ECL.CRISIS.PLEASE_LOGIN', { appUrl: location.origin });

    await this.mailSenderService.sendMail(
      {
        recipients: usersToNotify.filter(value => {
          return value.userId == user.userId;
        }),
        subject: this.translatePipe.transform('MAIL.ECL.CRISIS.MEMBER_ADD.SUBJECT', { crisisMainTitle: crisis.mainTitle }),
        contentText: msg,
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      false,
      true,
    );
  }

  async sendAcessDone(user: HolUser, crisis: EclCrisis) {
    const [directorsToNotify, usersToNotify, currentUser] = await Promise.all([
      this.eclCrisisDirectorStoreManager.$eclRealCrisisDirectorsWithFunctions().pipe(take(1)).toPromise(),
      this.usersService.getUsersWithFunctionsForCrisis(crisis),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
    ]);

    let msg = this.translatePipe.transform('MAIL.ECL.CRISIS.MEMBER_REMOVE.CONTENT_TEXT', {
      crisisName: crisis.mainTitle,
    });

    msg += this.translatePipe.transform('MAIL.ECL.CRISIS.PLEASE_LOGIN', { appUrl: location.origin });

    await this.mailSenderService.sendMail(
      {
        recipients: usersToNotify.filter(value => {
          return value.userId == user.userId;
        }),
        subject: this.translatePipe.transform('MAIL.ECL.CRISIS.MEMBER_REMOVE.SUBJECT', { crisisMainTitle: crisis.mainTitle }),
        contentText: msg,
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      false,
      true,
    );
  }

  async sendRepoenCrisisEmails(crisis: EclCrisis) {
    return Promise.all([
      this.eclCrisisDirectorStoreManager.$eclRealCrisisDirectorsWithFunctions().pipe(take(1)).toPromise(),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
      this.usersService.getUsersWithFunctionsForCrisis(crisis),
    ]).then(([crisisDirectors, currentUser, usersToNotify]) => {
      const users = mergeDirectorsAndUsers(crisisDirectors, usersToNotify);
      for (const user of users) {
        this.sendReopenCrisisEmailToUser(crisis, user, currentUser);
      }
    });
  }

  private getECLsAnnouncementMail(eclAnnouncement: EclAnnouncement): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg +=
      '<td width="1px" style="white-space: nowrap">' +
      moment(eclAnnouncement.createdAt).utc().format('HH:mm[UTC]') +
      '<br/>' +
      moment(eclAnnouncement.createdAt).utc().format('ddd[&nbsp;]DD') +
      '</td>';
    msg += '<td>' + eclAnnouncement.message + '</td>';
    // tslint:disable-next-line: max-line-length
    if (
      eclAnnouncement.attachments &&
      (eclAnnouncement.attachments.note ||
        eclAnnouncement.attachments.noteFile ||
        eclAnnouncement.attachments.file ||
        eclAnnouncement.attachments.image)
    ) {
      msg += '<td width="1" style="white-space: nowrap">';
      if (eclAnnouncement.attachments.noteFile) {
        // tslint:disable-next-line: max-line-length
        msg +=
          '<a href="' +
          eclAnnouncement.attachments.noteFile.url +
          '" target="_blank" title="' +
          eclAnnouncement.attachments.noteFile.fileName +
          '">📝</a>&nbsp;';
      } else if (eclAnnouncement.attachments.note) {
        msg += '<span title="' + eclAnnouncement.attachments.note + '">📝</span>&nbsp;';
      }
      if (eclAnnouncement.attachments.file) {
        // tslint:disable-next-line: max-line-length
        msg +=
          '<a title="' + eclAnnouncement.attachments.file.fileName + '" href="' + eclAnnouncement.attachments.file.url + '">📎</a>&nbsp;';
      }
      if (eclAnnouncement.attachments.image) {
        // tslint:disable-next-line: max-line-length
        msg +=
          '<a title="' + eclAnnouncement.attachments.image.fileName + '" href="' + eclAnnouncement.attachments.image.url + '">🖼</a>&nbsp;';
      }
      msg += '</td>';
    }

    msg += '</tr></table>';

    return msg;
  }

  private sendNewCrisisEmailToUser(crisis: EclCrisis, user: HolUserWithFunctions, crisisCreator: HolUser) {
    const msg = this.getNewCrisisMessage(crisis, user, crisisCreator);
    const subject = crisis.isTraining
      ? this.translatePipe.transform('MAIL.CRISIS.EXERCICE_HEADER', { crisisMainTitle: crisis.mainTitle })
      : this.translatePipe.transform('MAIL.CRISIS.NOT_EXERCICE_HEADER', { crisisMainTitle: crisis.mainTitle });
    this.mailSenderService.sendMail(
      {
        recipients: [{ email: user.email }],
        subject,
        contentHtml: msg,
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      false,
      true,
    );
  }

  private sendReopenCrisisEmailToUser(crisis: EclCrisis, user: HolUserWithFunctions, crisisCreator: HolUser) {
    const msg = this.getNewCrisisMessage(crisis, user, crisisCreator, true);
    const subject = crisis.isTraining
      ? this.translatePipe.transform('MAIL.CRISIS.EXERCICE_HEADER', { crisisMainTitle: crisis.mainTitle })
      : this.translatePipe.transform('MAIL.CRISIS.NOT_EXERCICE_HEADER', { crisisMainTitle: crisis.mainTitle });
    this.mailSenderService.sendMail(
      {
        recipients: [{ email: user.email }],
        subject,
        contentHtml: msg,
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      false,
      true,
    );
  }

  private getNewCrisisMessage(crisis: EclCrisis, user: HolUserWithFunctions, crisisCreator: HolUser, reOpen: boolean = false) {
    let msg = this.translatePipe.transform(reOpen ? 'MAIL.ECL.CRISIS.CRISIS_REOPEN' : 'MAIL.ECL.CRISIS.CRISIS_CREATED') + '<br/><br/>';

    msg += this.translatePipe.transform(reOpen ? 'MAIL.ECL.CRISIS.REOPEN_CRISIS' : 'MAIL.ECL.CRISIS.NEW_CRISIS_CREATED', {
      crisisCreatorName: crisisCreator.fullName,
      crisisMainTitle: crisis.mainTitle,
      crisisSubtitle: crisis.subTitle,
    });
    if (user.functions.length) {
      for (const func of user.functions) {
        try {
          msg += this.translatePipe.transform('MAIL.ECL.CRISIS.MEMBER_OF', {
            function: func.title,
            company: func.company,
          });
          // msg += this.translatePipe.transform('MAIL.CRISIS.FIRST_ACTIONS');
          //msg += marked(func.tasksSummary, { breaks: true });
        } catch (error) {
          console.warn('Invalid taskSummary for function ' + func.title + ': ', func.tasksSummary);
        }

        if (func.otherUsers.length > 0) {
          msg += this.translatePipe.transform('MAIL.ECL.CRISIS.COORDINATION') + '<ul>';
          for (const u of func.otherUsers) {
            msg += `<li>${u}</li>`;
          }
          msg += '</ul>';
        } else {
          msg += '<br/>';
        }

        msg += '<hr/>';
      }
    } else {
      console.warn('no function for user ', user);
    }

    msg += this.translatePipe.transform('MAIL.ECL.CRISIS.PLEASE_LOGIN', { appUrl: location.origin }) + '<br/><br/>';

    msg +=
      this.translatePipe.transform('MAIL.ECL.CRISIS.CONTACT_CREATOR', {
        crisisCreatorName: crisisCreator.fullName,
        crisisCreatorPhone: crisisCreator.phone || '',
        crisisCreatorEmail: crisisCreator.email || '',
      }) + '<br/><br/>';

    msg += this.translatePipe.transform('MAIL.ECL.CRISIS.ECL_MARKETING') + '<br/><br/>';

    msg += this.translatePipe.transform('MAIL.ECL.CRISIS.USE_IT');

    return msg;
  }
}
