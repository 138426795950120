import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { OclEventService } from '../../../../ocl/services/ocl-event-service/ocl-event.service';
import { MatDialog } from '@angular/material/dialog';
import { OclTask } from '../../../../ocl/models/ocl-task';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';

@Component({
  selector: 'app-checklist-task',
  templateUrl: './checklist-task.component.html',
  styleUrls: ['./checklist-task.component.scss'],
})
export class ChecklistTaskComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public mod: string;
  @Input()
  public index: number;
  @Input()
  public isReadOnly: boolean;

  @Input()
  set taskInput(value: OclTask) {
    this._task.next(value);
  }

  get taskInput(): OclTask {
    return this._task.getValue();
  }

  public taskSub: Subscription;
  public task: OclTask;
  private _task = new BehaviorSubject<OclTask>(undefined);

  constructor(
    private sanitizer: DomSanitizer,
    private eventService: OclEventService,
    private dialog: MatDialog,
    @Inject('$state') private $state,
    private moduleConfig: ModuleConfigService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.taskSub = this._task.subscribe(result => {
      if (!result) {
        return;
      }
      this.task = result;
    });
  }

  public ngOnDestroy(): void {
    this.taskSub.unsubscribe();
  }

  public openModal(e): void {
    // TODO goto task details page
    if (e.target.tagName === 'A') {
      return;
    }

    this.$state.go('app.' + this.moduleConfig.config.moduleName + '.task', {
      taskId: this.task.objectId,
    });
  }
}
