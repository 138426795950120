import { OclGroupsStoreManager } from '../../../ocl/store/groups/ocl-groups.store-manager';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { combineLatest, Observable } from 'rxjs';
import { OclGroupsStoreState } from '../../../ocl/ocl.model';
import { EclCrisis } from '../../models/ecl-crisis';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { EclDecisionGroup } from '../../models/ecl-decision-group.model';
import { EclEventGroup } from '../../models/ecl-event-group.model';
import { EclLogbookGroup } from '../../models/ecl-logbook-group.model';
import { EclGlobalInstructionGroup } from '../../models/ecl-global-instruction-group.model';

@Injectable({
  providedIn: 'root',
})
export class EclGroupsStoreManager extends OclGroupsStoreManager {
  private $selectedCrisis: Observable<EclCrisis>;

  constructor(store: Store<AppState>) {
    super(store);
    this.$selectedCrisis = store.select('ecl', 'crisisState', 'selectedCrisis');
  }

  get groupsState(): Observable<OclGroupsStoreState> {
    return combineLatest([this.$selectedCrisis, this._groupsState]).pipe(
      map(([crisis, groupState]) => {
        const crisisId = crisis && crisis.objectId;
        const groupStateResult = cloneDeep(groupState);
        groupStateResult.decisions = groupStateResult.decisions.filter(
          decisionGroup => (decisionGroup as EclDecisionGroup).crisis && (decisionGroup as EclDecisionGroup).crisis.objectId === crisisId,
        );
        groupStateResult.events = groupStateResult.events.filter(
          eventsGroup => (eventsGroup as EclEventGroup).crisis && (eventsGroup as EclEventGroup).crisis.objectId === crisisId,
        );
        groupStateResult.logbooks = groupStateResult.logbooks.filter(
          logbooksGroup => (logbooksGroup as EclLogbookGroup).crisis && (logbooksGroup as EclLogbookGroup).crisis.objectId === crisisId,
        );
        /*groupStateResult.globalInstructions = groupStateResult.globalInstructions.filter(
          globalInstructionsGroup => (globalInstructionsGroup as EclGlobalInstructionGroup).crisis.objectId === crisisId,
        );*/
        return groupStateResult;
      }),
    );
  }
}
