<div *ngIf="isNewDay || isVeryFirstDay" class="new-date">
  <div *ngIf="!isVeryFirstDay" class="top-gap"></div>

  <div class="new-date-label">
    <!--New day-->
    <div *ngIf="isVeryFirstDay && !isUtc && timeSlot.timeEnd" class="new-day hol-centered-line">
      <i class="fa fa-long-arrow-down"></i> {{ timeSlot.timeEnd | date : 'dd MMM' }} <i class="fa fa-long-arrow-down"></i>
    </div>

    <div *ngIf="isVeryFirstDay && isUtc && timeSlot.timeEnd" class="new-day hol-centered-line">
      <i class="fa fa-long-arrow-down"></i> {{ timeSlot.timeEnd | date : 'dd MMM' : 'UTC' }} <i class="fa fa-long-arrow-down"></i>
    </div>
    <div
      *ngIf="
        !isVeryFirstDay &&
        !isUtc &&
        timeSlot.timeStart &&
        timeSlot.timeEnd &&
        timeSlot.timeStart.get('day') !== timeSlot.timeEnd.get('day') &&
        isNewDay
      "
      class="new-day hol-centered-line"
    >
      <i class="fa fa-arrow-down"></i> {{ timeSlot.timeEnd | date : 'dd MMM' }} <i class="fa fa-arrow-down"></i>
    </div>
    <div
      *ngIf="
        !isVeryFirstDay &&
        isUtc &&
        timeSlot.timeStart &&
        timeSlot.timeEnd &&
        timeSlot.timeStart.utc().get('day') !== timeSlot.timeEnd.utc().get('day') &&
        isNewDay
      "
      class="new-day hol-centered-line"
    >
      <i class="fa fa-long-arrow-down"></i>&nbsp;<span> {{ timeSlot.timeEnd | date : 'dd MMM' : 'UTC' }} </span>&nbsp;<i
        class="fa fa-long-arrow-down"
      ></i>
    </div>
  </div>

  <div class="bottom-gap"></div>
</div>

<!--New supervisor-->
<div *ngIf="timeSlot.logs && timeSlot.logs.shift_supervisor && timeSlot.logs.shift_supervisor.length" class="supervisor">
  <div class="top-gap"></div>

  <div [ngClass]="{ 'empty-supervisor': !timeSlot.logs.shift_supervisor[0].shiftSupervisor }" class="new-supervisor hol-centered-line">
    <div *ngIf="timeSlot.logs.shift_supervisor[0].shiftSupervisor">
      <i class="fa fa-long-arrow-up"></i>&nbsp;<span
        >{{
          timeSlot.logs.shift_supervisor[0].shiftSupervisor.firstName + ' ' + timeSlot.logs.shift_supervisor[0].shiftSupervisor.lastName
        }}
        (ON DUTY started at {{ timeSlot.logs.shift_supervisor[0].moment | time : isUtc }})</span
      >&nbsp;<i class="fa fa-long-arrow-up"></i>
    </div>
    <div *ngIf="!timeSlot.logs.shift_supervisor[0].shiftSupervisor">
      <i class="fa fa-long-arrow-up"></i>&nbsp;<span
        >Nobody (started at {{ timeSlot.logs.shift_supervisor[0].moment | time : isUtc }} )</span
      >&nbsp;<i class="fa fa-long-arrow-up"></i>
    </div>
  </div>

  <div class="bottom-gap"></div>
</div>

<div class="time-slot hol-spaced-line">
  <div *ngIf="timeSlot.timeStart" class="time">
    <strong>
      <span *ngIf="!isUtc">{{ timeSlot.timeStart | date : 'HH:mm' }}</span>
      <span *ngIf="isUtc">{{ timeSlot.timeStart | date : 'HH:mm' : 'UTC' }}Z</span>
    </strong>
  </div>

  <div *ngIf="timeSlot && timeSlot.logs" class="logs">
    <!-- COL LOGBOOK -->
    <div class="col-logbook" [style.order]="moduleConfig.config.logbooksOrder">
      <ul *ngIf="timeSlot.logs && timeSlot.logs.logbook" class="hol-list">
        <li *ngFor="let log of timeSlot.logs.logbook | aclFilter : RolesService.companiesRolesFilter; trackBy: trackByFunction">
          <strong *ngIf="log && log.jsonObject" class="time">
            <div>
              <app-acl-indicator [object]="log"></app-acl-indicator>
              <span *ngIf="!isUtc">{{ log.moment | date : 'HH:mm' }}</span>
              <span *ngIf="isUtc">{{ log.moment | date : 'HH:mm' : 'UTC' }}Z</span>
              <span *ngIf="log.user && log.user.firstName && log.user.lastName" class="text-muted"
                >{{ log.user.firstName[0] }}{{ log.user.lastName[0] }}</span
              >
            </div>

            <div class="status">
              <strong *ngIf="log.subType === 'create'" class="hol-warning full long-status">Create</strong>
              <strong *ngIf="log.subType === 'checked'" class="hol-warning full long-status">Checked</strong>
              <strong *ngIf="log.subType === 'update'" class="hol-primary full long-status">Update</strong>
              <strong *ngIf="log.subType === 'reactivate'" class="hol-primary full long-status">Reactivate</strong>
              <strong *ngIf="log.subType === 'uncompleted'" class="hol-archive full long-status">Uncompleted</strong>
              <strong *ngIf="log.subType === 'unchecked'" class="hol-archive full long-status">Unchecked</strong>
              <strong *ngIf="log.subType === 'close'" class="hol-done full long-status">Close</strong>
              <strong *ngIf="log.subType === 'completed'" class="hol-done full long-status">Completed</strong>
              <strong *ngIf="log.subType === 'archive'" class="hol-archive full long-status">Archive</strong>
              <strong *ngIf="log.subType === 'deactivate'" class="hol-archive full long-status">Deactivate</strong>
            </div>

            <div *ngIf="log.attachments" class="attachments">
              <app-file-and-image-icons-display [attachments]="log.attachments"></app-file-and-image-icons-display>
            </div>
            <div *ngIf="log.jsonObject.flight && log.jsonObject.flight.flightNumber" class="time-occ-flight-linked">
              <span>{{ log.jsonObject.flight.flightNumber }}/{{ log.jsonObject.flight.std | date : 'ddMMM' }}</span>
            </div>
          </strong>

          <div *ngIf="log && log.jsonObject" class="comment">
            <div *ngIf="log.jsonObject.tags">
              <span
                class="chips"
                *ngFor="let tag of log.jsonObject.tags | aclFilter : RolesService.companiesRolesFilter"
                [ngStyle]="{ 'background-color': tag.color }"
              ></span>
            </div>
            <div>
              <span *ngIf="log.jsonObject.event && log.jsonObject.event.scenario && log.jsonObject.event.order" class="col-linked-event">
                {{ log.jsonObject.event.scenario?.code + log.jsonObject.event.order }}
              </span>
              <span *ngIf="log.comment" [innerHtml]="log.comment"></span>
            </div>
          </div>

          <img *ngIf="log.jsonObject && log.jsonObject.isPrivate" class="private" src="/assets/images/internal.svg" />
        </li>
      </ul>
      <div *ngIf="!timeSlot.logs.logbook" class="empty-log text-muted">-</div>
    </div>

    <!-- COL EVENTS -->
    <div class="col-event" [style.order]="moduleConfig.config.eventsOrder">
      <ul *ngIf="timeSlot.logs.event" class="hol-list">
        <li *ngFor="let log of timeSlot.logs.event | aclFilter : RolesService.companiesRolesFilter; trackBy: trackByFunction">
          <strong *ngIf="log" class="time">
            <div>
              <app-acl-indicator [object]="log"></app-acl-indicator>
              <span *ngIf="!isUtc">{{ log.moment | date : 'HH:mm' }}</span>
              <span *ngIf="isUtc">{{ log.moment | date : 'HH:mm' : 'UTC' }}Z</span>
              <span *ngIf="log.user && log.user.firstName && log.user.lastName" class="text-muted"
                >{{ log.user.firstName[0] }}{{ log.user.lastName[0] }}</span
              >
            </div>
            <div class="status">
              <strong *ngIf="log.subType === 'open'" class="hol-error full">Open</strong>
              <strong *ngIf="log.subType === 'close'" class="hol-done full">Close</strong>
              <strong *ngIf="log.subType.indexOf('info') > -1" class="hol-primary full">Info</strong>
              <strong *ngIf="log.subType === 'title_update'" class="hol-primary full">Title</strong>
              <strong *ngIf="log.subType === 'DONE'" class="hol-done">Done</strong>
              <strong *ngIf="log.subType === 'FROZEN'" class="hol-frozen">Frozen</strong>
              <strong *ngIf="log.subType === 'TODO'" class="hol-backtodo">Todo</strong>
              <strong *ngIf="log.subType === 'NOT_APPLICABLE'" class="hol-notapplicable">N/A</strong>
              <strong *ngIf="log.subType === 'update'" class="hol-primary full long-status">Update</strong>
              <!-- HolTask -->
              <span *ngIf="log.type === 'holTask'">
                <strong class="hol-primary full">{{
                  moduleConfig.config.translateKey + '.HISTORY.' + log.subType | uppercase | translate
                }}</strong>
              </span>
            </div>
            <div *ngIf="log.attachments" class="attachments">
              <app-file-and-image-icons-display [attachments]="log.attachments"></app-file-and-image-icons-display>
            </div>
          </strong>
          <div *ngIf="log" class="comment">
            <div *ngIf="log.jsonObject?.tags">
              <span
                class="chips"
                *ngFor="let tag of log.jsonObject.tags | aclFilter : RolesService.companiesRolesFilter"
                [ngStyle]="{ 'background-color': tag.color }"
              ></span>
            </div>
            <div *ngIf="!log.event && log.comment" [innerHtml]="log.comment"></div>

            <div *ngIf="log.event">
              <div *ngIf="log.type === 'event'">
                <strong class="event-title"> {{ log.event.scenario?.code }}{{ log.event.order }} - {{ log.event.scenario?.title }} </strong>
              </div>
              <div *ngIf="log.type === 'event'" [ngClass]="{ 'log-muted': log.subType !== 'open' }">
                {{ log.event.description }}
              </div>
              <div *ngIf="log.subType === 'title_update'">
                {{ log.comment }}
              </div>
              <div
                *ngIf="
                  log.subType.indexOf('info') > -1 || (log.jsonObject && log.jsonObject.infos[0] && log.jsonObject.infos[0].nextInfoTime)
                "
                class="nextInfo"
              >
                <hr />
                <div *ngIf="log.subType.indexOf('info') > -1">
                  {{ log.comment }}
                  <i *ngIf="log.subType === 'info-done'" class="hol-done icon icon-check"></i>
                  <i *ngIf="log.subType === 'info-backtodo'" class="hol-backtodo icon icon-reinstate"></i>
                </div>
                <div
                  *ngIf="log.jsonObject && log.jsonObject.infos[0] && log.jsonObject.infos[0].nextInfoTime"
                  class="text-right text-muted next-info"
                >
                  <span>{{ log.jsonObject.infos[0].nextInfoTime | nextInfoTime : isUtc }}</span>
                </div>
              </div>
              <div *ngIf="log.subType === 'close'">
                <span *ngIf="log.event.closeReason === 0">{{
                  moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_REASON_1' | translate
                }}</span>
                <span *ngIf="log.event.closeReason === 1">{{
                  moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_REASON_2' | translate
                }}</span>
                <span *ngIf="log.event.closeReason === 2">{{ log.event.closeReasonText }}</span>
              </div>
              <div *ngIf="log.type === 'task'">
                <div class="log-nowrap hol-primary">
                  <strong
                    >{{ log.event.scenario?.code }}{{ log.event.order }} - {{ log.task.code }}{{ log.task.order }} -
                    {{ log.task.title }}</strong
                  >
                </div>
                <div>{{ log.comment }}</div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div *ngIf="!timeSlot.logs.event" class="empty-log text-muted">-</div>
    </div>

    <!-- COL DECISIONS -->
    <div class="col-decision" [style.order]="moduleConfig.config.decisionsOrder">
      <ul *ngIf="timeSlot.logs && timeSlot.logs.decision" class="hol-list">
        <li *ngFor="let log of timeSlot.logs.decision | aclFilter : RolesService.companiesRolesFilter; trackBy: trackByFunction">
          <strong *ngIf="log && log.jsonObject" class="time">
            <div>
              <app-acl-indicator [object]="log"></app-acl-indicator>
              <span *ngIf="!isUtc">{{ log.moment | date : 'HH:mm' }}</span>
              <span *ngIf="isUtc">{{ log.moment | date : 'HH:mm' : 'UTC' }}Z</span>
              <span *ngIf="log.user && log.user.firstName && log.user.lastName" class="text-muted"
                >{{ log.user.firstName[0] }}{{ log.user.lastName[0] }}</span
              >
            </div>
            <div class="status">
              <strong *ngIf="log.subType === 'create'" class="hol-warning full long-status">Create</strong>
              <strong *ngIf="log.subType === 'update'" class="hol-primary full long-status">Update</strong>
            </div>
            <div *ngIf="log.attachments" class="attachments">
              <app-file-and-image-icons-display [attachments]="log.attachments"></app-file-and-image-icons-display>
            </div>
            <div *ngIf="log.jsonObject.flight && log.jsonObject.flight.flightNumber" class="time-occ-flight-linked">
              <span>{{ log.jsonObject.flight.flightNumber }}/{{ log.jsonObject.flight.std | date : 'ddMMM' }}</span>
            </div>
          </strong>
          <div *ngIf="log && log.jsonObject" class="comment">
            <div *ngIf="log.jsonObject.tags">
              <span
                class="chips"
                *ngFor="let tag of log.jsonObject.tags | aclFilter : RolesService.companiesRolesFilter"
                [ngStyle]="{ 'background-color': tag.color }"
              ></span>
            </div>
            <div>
              <span *ngIf="log.jsonObject.event" class="col-linked-event">{{
                log.jsonObject.event.scenario?.code + log.jsonObject.event.order
              }}</span>
              <span [innerHtml]="log.comment"></span>
            </div>
          </div>
          <i *ngIf="log && log.subType === 'done'" class="hol-done icon icon-check"></i>
          <i *ngIf="log && log.subType === 'backtodo'" class="hol-backtodo icon icon-reinstate"></i>
        </li>
      </ul>
      <div *ngIf="!timeSlot.logs.decision" class="empty-log text-muted">-</div>
    </div>
  </div>
</div>
