<table>
  <thead>
    <tr>
      <th></th>
      <th>{{ moduleConfig.config.translateKey + '.COMMON.BY_SMS' | translate }}</th>
      <th>{{ moduleConfig.config.translateKey + '.COMMON.BY_MAIL' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="notifyFunction">
      <th>
        {{ 'DASHBOARD.MODAL.NOTIFY_FUNCTION' | translate }}
      </th>
      <td>
        <mat-slide-toggle
          (toggleChange)="callSaveNotifyFunction()"
          [checked]="notifyFunction.sendBySms"
          [disabled]="notifyFunction.disabled"
        ></mat-slide-toggle>
      </td>
      <td>
        <mat-slide-toggle
          (ngModelChange)="callSaveNotifyFunction()"
          [(ngModel)]="notifyFunction.sendByMail"
          [disabled]="notifyFunction.disabled"
        ></mat-slide-toggle>
      </td>
    </tr>
    <tr *ngFor="let notif of notifications">
      <th>{{ notif.name }}</th>
      <td>
        <mat-slide-toggle
          [(ngModel)]="notif.sendBySms"
          [checked]="notif.sendBySms"
          [disabled]="notif.disabled || notif.readOnly || notif.disabled === undefined"
          aria-label="Send Notifications by SMS"
          id="notificationSMS{{ notif.objectId + type }}"
        >
        </mat-slide-toggle>
      </td>
      <td>
        <mat-slide-toggle
          [(ngModel)]="notif.sendByMail"
          [checked]="notif.sendByMail"
          [disabled]="notif.disabled || notif.readOnly || notif.disabled === undefined"
          aria-label="Send Notifications by MAIL"
          id="notificationMail{{ notif.objectId + type }}"
        >
        </mat-slide-toggle>
      </td>
    </tr>
  </tbody>
</table>
