import { cloneDeep, keyBy } from 'lodash';
import { OclGroupsActions, OclGroupsActionTypes } from './ocl-groups.actions';
import { OclGroupsStoreState } from '../../ocl.model';

const groupsInitialState: OclGroupsStoreState = {
  events: [],
  logbooks: [],
  decisions: [],
  globalInstructions: [],
};

export function oclGroupsReducer(
  state: OclGroupsStoreState = cloneDeep(groupsInitialState),
  action: OclGroupsActions,
): OclGroupsStoreState {
  switch (action.type) {
    case OclGroupsActionTypes.INIT_GROUPS:
      return {
        ...state,
        [action.typeKey]: action.groups,
      };
    case OclGroupsActionTypes.UPDATE_GROUPS_FROM_POOLING:
      console.log('UPDATE_GROUPS_FROM_POOLING');
      /*
      return {
        ...state,
        [action.typeKey]: action.groups,
      };
      */
      return {
        ...state,
        [action.typeKey]: state[action.typeKey]
          ? mergeGroups(state[action.typeKey], action.groups) // Fusionner avec les groupes existants
          : action.groups, // Si aucun groupe n'existe encore, on ajoute simplement les groupes du payload
      };

    case OclGroupsActionTypes.UPDATE_ONE_GROUP:
      const index = state[action.typeKey].findIndex(group => group.objectId === action.group.objectId);
      if (index !== -1) {
        return {
          ...state,
          [action.typeKey]: [...state[action.typeKey].slice(0, index), action.group, ...state[action.typeKey].slice(index + 1)],
        };
      }
      return state;
    default:
      return state;
  }
}

function mergeGroups(existingGroups, newGroups) {
  const existingGroupsById = keyBy(existingGroups, 'objectId'); // Utiliser 'objectId' comme clé unique
  newGroups.forEach(newGroup => {
    existingGroupsById[newGroup.objectId] = newGroup; // Remplace ou ajoute le nouveau groupe
  });
  return Object.values(existingGroupsById);
}
