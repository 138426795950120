<div class="page new-holis-theme" id="page-tasks">
  <app-loading *ngIf="loading" class="full-loader"></app-loading>
  <app-header
    (closeExternalPanels)="closeExternalPanels()"
    [canAddBreakingNews]="!isReadOnly"
    [closeOtherPanels]="showManagersPanel"
    [context]="moduleConfig.config.moduleName"
  >
    <ng-container class="header-acl">
      <div>
        <app-acl-filter [module]="moduleConfig.config.moduleName.toUpperCase()"></app-acl-filter>
      </div>
    </ng-container>
    <ng-container class="header-search">
      <app-search-bar (newSearchCriteria)="onChangeSearchEvent($event)" [placeholder]="'.DASHBOARD.SEARCH'"></app-search-bar>
    </ng-container>
    <ng-container class="buttons-addon">
      <button (click)="toggleManagerPanel()" class="header-button" type="button">
        <img alt="icon managers" src="../../../../assets/images/user.svg" />
      </button>
    </ng-container>
  </app-header>
  <app-hol-addons-panel [expanded]="showManagersPanel">
    <app-ocl-managers-box [isReadOnly]="isReadOnly"></app-ocl-managers-box>
  </app-hol-addons-panel>
  <div class="tabs">
    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="{{ 'TASKS.EVENEMENT' | translate }}">
        <div class="tab-content">
          <app-checklist-event
            *ngIf="checkList.length >= 1"
            [checklistInput]="checkList"
            [isReadOnly]="isReadOnly"
            [mod]="moduleConfig.config.moduleName"
            [searchCriteria]="searchCriteria"
          ></app-checklist-event>
          <p *ngIf="!checkList.length">{{ 'TASKS.NO_RESULT_EVENT' | translate }}</p>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'TASKS.VACATION' | translate }}">
        <div class="tab-content">
          <app-checklist-vacation
            [isReadOnly]="isReadOnly"
            [mod]="moduleConfig.config.moduleName"
            [searchCriteria]="searchCriteria"
          ></app-checklist-vacation>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
