import { HolUser, HolUserWithCompanies } from '../../../common/models/hol-user.model';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { HolUserWithFunctions } from '../../../erp/services/erp-users.service';
import { Injectable } from '@angular/core';
import { EclFunctionStoreManager } from '../../store/function/function.store-manager';
import { EclCrisisStoreManager } from '../../store/crisis/crisis.store-manager';
import { EclFunctionService } from '../ecl-function-service/ecl-function-service';
import { RequestService } from '../../../common/services/request.service';
import { HolRole } from '../../../common/models/hol-role';
import { forEach, groupBy, intersection, uniqBy } from 'lodash';
import { EclCrisis } from '../../models/ecl-crisis';
import { EclFunction, EclUserFunction, EclUserFunctionRef } from '../../models/ecl-function';
import { EclOptions, EclOptionsService } from '../ecl-options-service/ecl-options.service';
import { HolOptionsService } from '../../../common/services/hol-options.service';

@Injectable({
  providedIn: 'root',
})
export class EclUsersService {
  private ParseUser = Parse.Object.extend('_User');

  constructor(
    private eclFunctionStoreManager: EclFunctionStoreManager,
    private eclCrisisStoreManager: EclCrisisStoreManager,
    private eclFunctionService: EclFunctionService,
    private requestService: RequestService,
    private eclOptionsService: EclOptionsService,
    private holOptionsService: HolOptionsService,
  ) {}

  async isCurrentUserCanCreateCrisis(): Promise<boolean> {
    const directorFunc = this.eclOptionsService.getFunctionIdCrisisForCrisisDirector();
    const initializerFunc = this.eclOptionsService.getFunctionIdEclCrisisInitializer();

    const eclCurrentUserFunctionRefs: EclUserFunctionRef[] = await this.eclFunctionService.getCurrentUserFunctionsRef();

    let isInCrisis = false;
    forEach(eclCurrentUserFunctionRefs, function (value) {
      if (directorFunc.includes(value.functionId) || initializerFunc.includes(value.functionId)) {
        isInCrisis = true;
      }
    });
    return isInCrisis;
  }

  async isCurrentUserCanGenerateBriefing(): Promise<boolean> {
    const directorFunc = this.eclOptionsService.getFunctionIdCrisisForCrisisDirector();
    const initializerFunc = this.eclOptionsService.getFunctionIdEclCrisisInitializer();
    const observorFunc = this.eclOptionsService.getFunctionIdForCrisisObservor();

    const eclCurrentUserFunctionRefs: EclUserFunctionRef[] = await this.eclFunctionService.getCurrentUserFunctionsRef();

    let isInCrisis = false;
    forEach(eclCurrentUserFunctionRefs, function (value) {
      if (
        directorFunc.includes(value.functionId) ||
        observorFunc.includes(value.functionId) ||
        initializerFunc.includes(value.functionId)
      ) {
        isInCrisis = true;
      }
    });
    return isInCrisis;
  }

  async isCurrentUserCanSeeTeamsRef(): Promise<boolean> {
    const directorFunc = this.eclOptionsService.getFunctionIdCrisisForCrisisDirector();
    const observorFunc = this.eclOptionsService.getFunctionIdForCrisisObservor();
    const managerFunc = this.holOptionsService.getFunctionsIdsForManagerByModule('ECL');
    const eclCurrentUserFunctionRefs: EclUserFunctionRef[] = await this.eclFunctionService.getCurrentUserFunctionsRef();

    let isInCrisis = false;
    forEach(eclCurrentUserFunctionRefs, function (value) {
      if (
        (directorFunc && directorFunc.includes(value.functionId)) ||
        (observorFunc && observorFunc.includes(value.functionId)) ||
        (managerFunc && managerFunc.includes(value.functionId))
      ) {
        isInCrisis = true;
      }
    });
    return isInCrisis;
  }

  async getUsersWithFunctionsForCrisis(crisis: EclCrisis): Promise<HolUserWithFunctions[]> {
    let eclUserFunctions: EclUserFunction[] = await this.eclFunctionService.allUserFunctionForCrisis(crisis);

    const [fs, crisisTypes] = await combineLatest([
      this.eclFunctionStoreManager.$eclFunctionState,
      this.eclCrisisStoreManager.$eclCrisisTypes,
    ])
      .pipe(take(1))
      .toPromise();

    const allEclUsers = fs.allEclUsers;

    eclUserFunctions.forEach(eclUf => {
      eclUf.function = fs.functions.find(func => func.functionId == eclUf.functionId);
      eclUf.user = allEclUsers.find(user => user.userId == eclUf.userId);
    });

    const filteredEclUserFunctions = eclUserFunctions.filter(eclUf => {
      const foundFunction = fs.functions.find(func => func.functionId == eclUf.functionId);
      return foundFunction !== undefined; // Ne garder que les eclUserFunctions où la fonction correspondante est trouvée
    });

    filteredEclUserFunctions.forEach(eclUf => {
      eclUf.function = fs.functions.find(func => func.functionId == eclUf.functionId);
      eclUf.user = allEclUsers.find(user => user.userId == eclUf.userId);
    });

    const eclUsersConcerned: HolUserWithCompanies[] = [];
    for (let user of allEclUsers) {
      if (filteredEclUserFunctions.find(value => value.userId === user.userId) != undefined) {
        eclUsersConcerned.push(user);
      }
    }

    const eclUserFunctionsByUser = groupBy(filteredEclUserFunctions, 'userId');
    const eclUserFunctionsByFunction = groupBy(filteredEclUserFunctions, 'functionId');

    //  const crisisType = crisisTypes.find(ct => ct.crisisTypeId === crisis.crisisTypeId);

    return eclUsersConcerned.map(u => {
      const userFunctions: EclUserFunction[] = eclUserFunctionsByUser[u.userId] || [];
      const result: HolUserWithFunctions = {
        userId: u.userId,
        fullName: u.fullName,
        email: u.email,
        phone: u.phone,
        functions: uniqBy(userFunctions, 'functionId').map((uf: EclUserFunction) => ({
          company: '',
          functionId: uf.functionId,
          title: uf.function.title,
          shortTitle: uf.function.shortTitle,
          tasksSummary: uf.function.tasksSummary,
          otherUsers: uniqBy(eclUserFunctionsByFunction[uf.functionId], 'userId')
            .filter(ouf => ouf.userId !== u.userId && ouf.user && ouf.user.fullName)
            .map(ouf => ouf.user.fullName),
        })),
      };
      return result;
    });
  }

  async getUserWithFunctionsRef(): Promise<HolUserWithFunctions[]> {
    const [fs, crisisTypes] = await combineLatest([
      this.eclFunctionStoreManager.$eclFunctionState,
      this.eclCrisisStoreManager.$eclCrisisTypes,
    ])
      .pipe(take(1))
      .toPromise();
    const eclUserFunctionsByUser = groupBy(fs.allUserFunctionsRef, 'userId');
    const eclUserFunctionsByFunction = groupBy(fs.allUserFunctionsRef, 'functionId');
    const allEclUsers = fs.allEclUsers;
    //   const crisisType = crisisTypes.find(ct => ct.crisisTypeId === crisis.crisisTypeId);
    //   const functionsToNotify = (crisisType && crisisType.functionsToNotify) || [];

    return (
      allEclUsers
        /*
              .filter(u => {
                const aclMatch = intersection(
                  crisis.companies,
                  u.companies.map(c => c.name),
                );
                const userHasFunctionToNotify = ((eclUserFunctionsByUser[u.userId] || []) as EclUserFunction[]).some(uf =>
                  functionsToNotify.includes(uf.functionId),
                );
                return aclMatch && userHasFunctionToNotify;
              })
              */
        .map(u => {
          const userFunctions: EclUserFunctionRef[] = eclUserFunctionsByUser[u.userId] || [];
          const result: HolUserWithFunctions = {
            userId: u.userId,
            fullName: u.fullName,
            email: u.email,
            phone: u.phone,
            functions: uniqBy(userFunctions, 'functionId').map((uf: EclUserFunctionRef) => ({
              company: '',
              functionId: uf.functionId,
              title: uf.function.title,
              shortTitle: uf.function.shortTitle,
              tasksSummary: uf.function.tasksSummary,
              otherUsers: uniqBy(eclUserFunctionsByFunction[uf.functionId], 'userId')
                .filter(ouf => ouf.userId !== u.userId && !!ouf.user.fullName)
                .map(ouf => ouf.user.fullName),
            })),
          };

          return result;
        })
    );

    return [];
  }

  /**
   * Get all users with at least one ECL role
   */
  async getAll(): Promise<HolUserWithCompanies[]> {
    const roles = await this.getEclRoles();
    const rolesAndUsers = await Promise.all(
      roles.map(async r => {
        const users = await this.requestService.performFindAllQuery<Parse.User>(r.getUsers().query());
        return {
          role: r,
          users,
        };
      }),
    );
    return this.reduceRolesWithUsers(rolesAndUsers);
  }

  private getEclRoles(): Promise<Parse.Role[]> {
    const rolesQuery = new Parse.Query(Parse.Role);
    rolesQuery.startsWith('name', 'ECL_');
    return this.requestService.performFindQuery<Parse.Role>(rolesQuery);
  }

  private reduceRolesWithUsers(rolesWithUsers: { role: Parse.Role; users: Parse.User[] }[]): HolUserWithCompanies[] {
    const userMap: { [id: string]: HolUserWithCompanies } = {};
    rolesWithUsers.forEach(rwu => {
      const role = new HolRole(rwu.role);
      rwu.users.forEach(u => {
        if (userMap[u.id]) {
          const bufferUserComp = userMap[u.id].companies.find(el => role.company === el.name);
          if (!bufferUserComp) {
            userMap[u.id].companies.push({ name: role.company, read: role.read, write: role.write });
          } else {
            bufferUserComp.write = bufferUserComp.write || role.write;
          }
        } else {
          const user = new HolUserWithCompanies(u);
          user.companies = [{ name: role.company, read: role.read, write: role.write }];
          userMap[u.id] = user;
        }
      });
    });
    return Object.values(userMap);
  }

  protected newUserObject(parser: Parse.Object): HolUser {
    return new HolUser(parser);
  }

  public async getAllUserWithLastSeenUpdatedAfterLastPolling(lastSeenAt: Date): Promise<HolUser[]> {
    const lastUserWithLastSeenUpdated = new Parse.Query(this.ParseUser);
    lastUserWithLastSeenUpdated.greaterThanOrEqualTo('lastSeenAt', lastSeenAt);
    const parseResult = await this.requestService.performFindAllQuery(lastUserWithLastSeenUpdated);
    const result = parseResult ? parseResult.map(parseObject => this.newUserObject(parseObject)) : [];

    this.eclFunctionStoreManager.updateSeveralUser(result);
    return result;
  }
}
