import { OclEvent } from './ocl-event.model';
import { HolTag } from '../../common/models/hol-tag';
import { HolFlight } from 'src/app/common/models/hol-flight.model';
import { HolObject } from '../../common/models/hol-object';
import { OclLinkedDisplayedItem } from './ocl-linked-displayed-items.model';
import { HolUser } from 'src/app/common/models/hol-user.model';
import { HolAttachments } from 'src/app/common/models/hol-attachments.model';
import { OclDecision } from './ocl-decision.model';
import { Done, HasTag, IsPinned } from './ocl-interface.model';

export class OclLogbook extends HolObject implements IsPinned, HasTag, Done {
  public text: string;
  public event?: OclEvent;
  public flight?: HolFlight;
  public tags?: HolTag[];
  public done: boolean = false;
  public nextInfoDate?: Date;
  public isPinned?: boolean;

  public fromGOC?: boolean;
  public gocLogbook?: any;
  public toGOC?: boolean;
  public fromOCC?: boolean;
  public occLogbook?: any;
  public occDecision?: any;
  public toOCC?: boolean;
  public fromCREW?: boolean;
  public crewLogbook?: any;
  public fromERP?: boolean;
  public erpLogbook?: any;
  public toERP?: boolean;

  displayFromLinkedValues?: string[];
  linkedData?: OclLinkedDisplayedItem;

  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.text = parseObject.get('text');
    this.event = new OclEvent(parseObject.get('event'));
    this.flight = new HolFlight(parseObject.get('flight'));
    // tags need to set separately due to join table, this.tags = parseObject.get('tags').map(tag => new HolTag(tag));
    this.done = parseObject.get('done') === undefined ? false : parseObject.get('done');
    this.nextInfoDate = parseObject.get('nextInfoDate');
    this.isPinned = parseObject.get('isPinned');
    this.tags = tags;

    this.fromGOC = parseObject.get('fromGOC');
    this.gocLogbook = parseObject.get('gocLogbook') && new OclLogbook(parseObject.get('gocLogbook'));
    this.toGOC = parseObject.get('toGOC') ? parseObject.get('toGOC') : false;
    this.fromOCC = parseObject.get('fromOCC') ? parseObject.get('fromOCC') : false;
    this.occLogbook = parseObject.get('occLogbook') && new OclLogbook(parseObject.get('occLogbook'));
    this.occDecision = parseObject.get('occDecision') && new OclDecision(parseObject.get('occDecision'));
    this.toOCC = parseObject.get('toOCC');
    this.fromCREW = parseObject.get('fromCREW');
    this.crewLogbook = parseObject.get('crewLogbook') && new OclLogbook(parseObject.get('crewLogbook'));
    this.fromERP = parseObject.get('fromERP');
    this.erpLogbook = parseObject.get('erpLogbook') && new OclLogbook(parseObject.get('erpLogbook'));
    this.toERP = parseObject.get('toERP');

    if (parseObject && parseObject.get('gocLogbook')) {
      this.gocLogbook = new OclLogbook(parseObject.get('gocLogbook'));
      this.flight = new HolFlight(parseObject.get('gocLogbook').get('flight'));
      this.createdBy = new HolUser(parseObject.get('gocLogbook').get('createdBy'));
      const jsonAttachments = parseObject.get('gocLogbook').get('attachments');
      this.text = parseObject.get('gocLogbook').get('text');
      this.setAttachments(jsonAttachments);
      this.fromGOC = true;
      this.toGOC = false;
    }
    if (parseObject && parseObject.get('crewLogbook')) {
      this.crewLogbook = new OclLogbook(parseObject.get('crewLogbook'));
      this.createdBy = new HolUser(parseObject.get('crewLogbook').get('createdBy'));
      const jsonAttachments = parseObject.get('crewLogbook').get('attachments');
      this.setAttachments(jsonAttachments);
      this.fromCREW = true;
    }
    if (parseObject && parseObject.get('erpLogbook')) {
      this.createdBy = new HolUser(parseObject.get('erpLogbook').get('createdBy'));
      const jsonAttachments = parseObject.get('erpLogbook').get('attachments');
      this.setAttachments(jsonAttachments);
      this.fromERP = true;
      this.toERP = false;
    }
  }

  protected setAttachments(jsonAttachments) {
    if (typeof jsonAttachments === 'string') {
      jsonAttachments = JSON.parse(jsonAttachments);
    }
    if (jsonAttachments) {
      this.attachments = new HolAttachments(
        jsonAttachments.note,
        jsonAttachments.file,
        jsonAttachments.image,
        jsonAttachments.noteFile,
        jsonAttachments.link,
        jsonAttachments.files,
      );
    } else {
      this.attachments = new HolAttachments();
    }
  }

  protected getContentText(parseObject: Parse.Object) {
    let crewText = parseObject.get('text') + ' - ';
    if (parseObject.get('crewLogbook')) {
      crewText += parseObject.get('crewLogbook').get('text');
      crewText = crewText.length > 200 ? crewText.substring(0, 200) + '...' : crewText;
    }
    return parseObject.get('gocLogbook') // if
      ? parseObject.get('gocLogbook').get('text')
      : parseObject.get('crewLogbook') // else if
      ? crewText
      : parseObject.get('erpLogbook') // else if
      ? parseObject.get('erpLogbook').get('content')
      : parseObject.get('text'); // else
  }
}
