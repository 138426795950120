import { Component, Input, OnInit } from '@angular/core';
import { HolFlight } from '../../../models/hol-flight.model';
import * as moment from 'moment';
import { GocStopover } from 'src/app/goc/models/goc-stopover.model';

@Component({
  selector: 'app-indicator-stopover-timeline',
  templateUrl: './indicator-stopover-timeline.component.html',
  styleUrls: ['./indicator-stopover-timeline.component.scss'],
})
export class IndicatorStopoverTimelineComponent implements OnInit {
  @Input()
  public dayTargeted: any;
  @Input()
  public stopover: GocStopover;
  @Input()
  public showInfo: boolean;
  @Input()
  public departureSelected: string;

  notSameDayInStd: boolean;
  notSameDayInSta: boolean;
  notSameDayOutSta: boolean;
  notSameDayOutStd: boolean;

  inStdPosition: number;
  inStaPosition: number;
  outStdPosition: number;
  outStaPosition: number;

  widthInLine: number;
  widthOutLine: number;

  aiportPosition: number;

  constructor() {}

  public ngOnInit(): void {
    this.dayTargeted = !this.dayTargeted ? moment().utc(false) : moment(this.dayTargeted).utc(false);
    this.notSameDayInStd = !moment(this.stopover.inFlight.std).utc(false).isSame(this.dayTargeted, 'day');
    this.notSameDayInSta = !moment(this.stopover.inFlight.sta).utc(false).isSame(this.dayTargeted, 'day');
    this.notSameDayOutStd = !moment(this.stopover.outFlight.std).utc(false).isSame(this.dayTargeted, 'day');
    this.notSameDayOutSta = !moment(this.stopover.outFlight.sta).utc(false).isSame(this.dayTargeted, 'day');

    // init position
    this.inStdPosition = (+moment(this.stopover.inFlight.std).utc(false).format('HH') / 24) * 100;
    this.inStaPosition = (+moment(this.stopover.inFlight.sta).utc(false).format('HH') / 24) * 100;
    this.outStdPosition = (+moment(this.stopover.outFlight.std).utc(false).format('HH') / 24) * 100;
    this.outStaPosition = (+moment(this.stopover.outFlight.sta).utc(false).format('HH') / 24) * 100;

    // if all movements are in the day
    if (!this.notSameDayInStd && !this.notSameDayInSta && !this.notSameDayOutStd && !this.notSameDayOutSta) {
      // fix ajustement
      this.fixAjustement();
      // init lines width
      this.widthInLine = this.inStaPosition - this.inStdPosition;
      this.widthOutLine = this.outStaPosition - this.outStdPosition;
      // init airport postion
      this.aiportPosition = (this.outStdPosition - this.inStaPosition) / 2 + this.inStaPosition - 7;
    }

    // outFlight std not in the day
    if (!this.notSameDayInStd && !this.notSameDayInSta && !this.notSameDayOutStd && this.notSameDayOutSta) {
      this.outStaPosition = 100;
      this.fixAjustement(false, false, true);
      this.widthInLine = this.inStaPosition - this.inStdPosition;
      this.widthOutLine = this.outStaPosition - this.outStdPosition;
      this.aiportPosition = (this.outStdPosition - this.inStaPosition) / 2 + this.inStaPosition - 7;
    }

    // outFlight not in the day
    if (!this.notSameDayInStd && !this.notSameDayInSta && this.notSameDayOutStd && this.notSameDayOutSta) {
      this.fixAjustement(true);

      this.outStdPosition = 100;
      this.outStaPosition = 100;
      this.widthInLine = this.inStaPosition - this.inStdPosition;
      this.widthOutLine = 0;
      this.aiportPosition = (this.outStdPosition - this.inStaPosition) / 2 + this.inStaPosition - 7;
    }

    // inFlight not in the day
    if (this.notSameDayInStd && this.notSameDayInSta && !this.notSameDayOutStd && !this.notSameDayOutSta) {
      this.fixAjustement(true);
      this.inStdPosition = 0;
      this.inStaPosition = 0;
      this.widthInLine = 0;
      this.widthOutLine = this.outStaPosition - this.outStdPosition;
      this.aiportPosition = (this.outStdPosition - this.inStaPosition) / 2 + this.inStaPosition - 7;
    }

    // just inFlight std un the day
    if (!this.notSameDayInStd && this.notSameDayInSta && this.notSameDayOutStd && this.notSameDayOutSta) {
      this.fixAjustement(true);
      this.inStaPosition = 100;
      this.outStdPosition = 100;
      this.outStaPosition = 100;
      this.widthInLine = this.inStaPosition - this.inStdPosition;
      this.widthOutLine = 0;
      this.aiportPosition = (this.outStdPosition - this.inStaPosition) / 2 + this.inStaPosition - 7;
    }

    // inFlight std not in the day
    if (this.notSameDayInStd && !this.notSameDayInSta && !this.notSameDayOutStd && !this.notSameDayOutSta) {
      this.fixAjustement(false, true, false);
      this.inStdPosition = 0;
      this.widthInLine = this.inStaPosition - this.inStdPosition;
      this.widthOutLine = this.outStaPosition - this.outStdPosition;
      this.aiportPosition = (this.outStdPosition - this.inStaPosition) / 2 + this.inStaPosition - 7;
    }

    // inFlight std and outFlight sta not in the day
    if (this.notSameDayInStd && !this.notSameDayInSta && !this.notSameDayOutStd && this.notSameDayOutSta) {
      this.fixAjustement();
      this.inStdPosition = 0;
      this.outStaPosition = 100;
      this.widthInLine = this.inStaPosition - this.inStdPosition;
      this.widthOutLine = this.outStaPosition - this.outStdPosition;
      this.aiportPosition = (this.outStdPosition - this.inStaPosition) / 2 + this.inStaPosition - 7;
    }
  }

  private fixAjustement(ignoreBetweenTwoFlight = false, ignoreMinWidthInFlight = false, ignoreMinWidthOutFlight = false) {
    // min width between two flights
    if (!ignoreBetweenTwoFlight) {
      if (this.outStdPosition - this.inStaPosition <= 25) {
        this.inStdPosition -= 25;
        if (this.inStaPosition !== 0) {
          this.inStaPosition -= 25;
        }
        this.outStdPosition += 25;
        this.outStaPosition += 25;
      }
    }

    // min width inFlight
    if (!ignoreMinWidthInFlight) {
      if (this.inStaPosition - this.inStdPosition <= 15) {
        this.inStaPosition += 7.5;
        this.inStdPosition -= 7.5;
      }
    }

    // min with outFlight
    if (!ignoreMinWidthOutFlight) {
      if (this.outStaPosition - this.outStdPosition <= 15) {
        this.outStaPosition += 7.5;
        this.outStdPosition -= 7.5;
      }
    }
    // fix out of the line
    if (this.inStdPosition < 0) {
      const add = this.inStdPosition * -1;
      this.inStdPosition += add;
      this.inStaPosition += add;
      if (this.outStdPosition < 70) {
        this.outStdPosition += add;
        this.outStaPosition += add;
      }
    }

    // fix out of the line
    if (this.outStdPosition < 0) {
      const add = this.outStdPosition * -1;
      this.outStdPosition += add;
      this.outStaPosition += add;
    }
    // fix out of the line
    if (this.outStdPosition > 100) {
      const add = this.outStdPosition - 100;
      this.outStdPosition -= add;
      this.outStaPosition -= add;
      if (this.inStdPosition > 30) {
        this.inStdPosition -= add;
        this.inStaPosition -= add;
      }
    }
    // fix out of the line
    if (this.outStaPosition > 100) {
      const add = this.outStaPosition - 100;
      this.outStdPosition -= add;
      this.outStaPosition -= add;
      if (this.inStdPosition > 30) {
        this.inStdPosition -= add;
        this.inStaPosition -= add;
      }
    }
  }
}
