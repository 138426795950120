<div class="hol-dialog-wrapper confirmation-modal">
  <mat-toolbar class="mat-toolbar">
    <div class="title" mat-dialog-title>
      <span [innerHTML]="modalTitle"></span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1" type="button">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content >
    <div class="content-grid">
      <div class="form-container">
        <p [innerHTML]="modalContent"></p>
        <form>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'ECL.MODALS.REINIT_PASSWORD.LABEL' | translate }}</mat-label>
            <input
              matInput
              required
              type="password"
              (change)="onTypePassword()"
              [formControl]="passwordForm.controls.currentPasswordControl"
              placeholder="{{ 'ECL.MODALS.REINIT_PASSWORD.PLACEHOLDER' | translate }}"
            />
            <mat-error *ngIf="wrongCurrentPassword">
              {{ 'ECL.MODALS.REINIT_PASSWORD.PLACEHOLDER' | translate }}
            </mat-error>
          </mat-form-field>
        </form>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="action-bar">
      <button (click)="cancel()" class="action action-refuse">
        <span class="material-symbols-outlined">cancel</span>
        <span>{{ 'COMMON.CANCEL' | translate | uppercase }}</span>
      </button>
      <div class="spacer"></div>
      <button (click)="valid()" [disabled]="!passwordForm.valid" class="action action-validate">
        <span class="material-symbols-outlined">check</span>
        <span>{{ 'ECL.MODALS.REINIT_PASSWORD.CONFIRM' | translate | uppercase }}</span>
      </button>
    </div>
  </mat-dialog-actions>
</div>
