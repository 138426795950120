<div class="goc-table">
  <div class="hol-spaced-line time-slot header text-muted">
    <div class="time">{{ moduleConfig.config.translateKey + '.TIMELINE.HISTORY.TIME_COL' | translate }}</div>

    <div class="logs">
      <div class="col-logbook" [style.order]="moduleConfig.config.logbooksOrder">
        <span class="title">{{ moduleConfig.config.translateKey + '.TIMELINE.HISTORY.LOGBOOK_COL' | translate }}</span>
      </div>
      <div class="col-event" [style.order]="moduleConfig.config.eventsOrder">
        <span class="title">{{ moduleConfig.config.translateKey + '.TIMELINE.HISTORY.EVENT_COL' | translate }}</span>
      </div>
      <div class="col-decision" [style.order]="moduleConfig.config.decisionsOrder">
        <span class="title">{{ moduleConfig.config.translateKey + '.TIMELINE.HISTORY.DECISION_COL' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="iscroll-wrapper hol-no-padding" iscroll>
    <div class="iscroll-scroller">
      <ul [ngClass]="{ selectable: !phoneResolution }" class="histories hol-list">
        <li
          *ngFor="let timeSlot of timeSlots; let i = index"
          [ngClass]="{ 'new-day-line': isNewDay(timeSlot, !moduleConfig.config.localHours) }"
        >
          <app-ocl-history-time-slot
            *ngIf="timeSlot"
            [isNewDay]="isNewDay(timeSlot, !moduleConfig.config.localHours)"
            [isReadOnly]="isReadOnly"
            [isVeryFirstDay]="i === 0"
            [timeSlot]="timeSlot"
          >
          </app-ocl-history-time-slot>
        </li>
        <li
          *ngFor="let timeSlot of additionalTimeSlots; trackBy: trackByFunction"
          [ngClass]="{ 'new-day-line': isNewDay(timeSlot, !moduleConfig.config.localHours) }"
        >
          <app-ocl-history-time-slot
            *ngIf="timeSlot"
            [isNewDay]="isNewDay(timeSlot, !moduleConfig.config.localHours)"
            [isReadOnly]="isReadOnly"
            [isVeryFirstDay]="false"
            [timeSlot]="timeSlot"
          >
          </app-ocl-history-time-slot>
        </li>

        <li *ngIf="loading == true" class="hol-centered-line">
          <app-loading></app-loading>
        </li>
        <li class="hol-centered-line load-more-container">
          <div class="hol-centered-line">
            <button (click)="loadMore()" [disabled]="loading" color="primary" mat-raised-button>
              <span *ngIf="!loading">{{ 'COMMON.LOAD_MORE' | translate }}</span>
              <span *ngIf="loading">{{ 'COMMON.LOADING' | translate }}</span>
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
