import { Injectable } from '@angular/core';
import { OclLogbook } from '../../models/ocl-logbook.model';
import { OclDecision } from '../../models/ocl-decision.model';
import { OclDecisionService } from '../ocl-decision-service/ocl-decision.service';
import { HolNotification } from '../../../common/models/hol-notification.model';
import { HolContext } from '../../../common/models/hol-context.model';
import { OclLogbookService } from '../ocl-logbook-service/ocl-logbook.service';
import { OclLinksService } from '../ocl-links-service/ocl-links.service';
import { OclGroupService } from '../ocl-group-service/ocl-group.service';
import { OclLogbookTagService } from '../ocl-logbook-tag-service/ocl-logbook-tag.service';
import { OclDecisionTagService } from '../ocl-decision-tag-service/ocl-decision-tag.service';
import { EclDecision } from '../../../ecl/models/ecl-decision';
import { EclLogbook } from '../../../ecl/models/ecl-logbook';
import { EclSummaryService } from '../../../ecl/services/ecl-summary-service/ecl-summary-service';

@Injectable({
  providedIn: 'root',
})
export class OclLogbookDecisionService {
  constructor(
    protected oclDecisionService: OclDecisionService,
    protected oclLogbookService: OclLogbookService,
    protected oclLinksService: OclLinksService,
    protected olcGroupService: OclGroupService,
    protected oclLogbookTagService: OclLogbookTagService,
    protected oclDecisionTagService: OclDecisionTagService,
  ) {}

  async transformLogbookToDecision(logbook: OclLogbook, notifications: HolNotification[], context?: HolContext): Promise<void> {
    const newDecision = this.parseToDecision(logbook);
    if (context.module === 'ECL') {
      (newDecision as EclDecision).function = (logbook as EclLogbook).function;
    }
    await this.oclDecisionService.create(newDecision, notifications, context, null, true);
    await this.oclLinksService.deleteAllLinkFromLogbook(logbook.objectId);
    await this.olcGroupService.deleteLogbookInGroup(logbook.objectId);
    await this.oclLogbookTagService.deleteLogbookTagsFor(logbook);
    await this.oclLogbookService.delete(logbook.objectId);

    return;
  }

  async transformDecisionToLogbook(decision: OclDecision, notifications: HolNotification[], context?: HolContext): Promise<void> {
    const objectIdDecision = decision.objectId;

    const newLogbook = this.parseToLogbook(decision);
    await this.oclLogbookService.create(newLogbook, notifications, context, null, true);
    await this.oclLinksService.deleteAllLinkFromDecision(decision.objectId);
    await this.olcGroupService.deleteDecisionInGroup(decision.objectId);
    await this.oclDecisionTagService.deleteTags(decision);
    await this.oclDecisionService.delete(decision.objectId);

    return;
  }

  parseToDecision(logbook: OclLogbook): OclDecision {
    const newDecision = new OclDecision();
    newDecision.acl = logbook.acl;
    newDecision.attachments = logbook.attachments;
    newDecision.done = logbook.done;
    newDecision.doneBy = logbook.doneBy;
    newDecision.isPinned = logbook.isPinned;
    newDecision.message = logbook.text;
    newDecision.toERP = logbook.toERP;
    newDecision.customCreatedAt = logbook.customCreatedAt;
    newDecision.tags = logbook.tags;
    newDecision.createdBy = logbook.createdBy;
    newDecision.createdAt = logbook.createdAt;
    newDecision.customCreatedAt = logbook.customCreatedAt;
    return newDecision;
  }

  parseToLogbook(decision: OclDecision): OclLogbook {
    const newLogbook = new OclLogbook();
    newLogbook.acl = decision.acl;
    newLogbook.attachments = decision.attachments;
    newLogbook.done = decision.done;
    newLogbook.doneBy = decision.doneBy;
    newLogbook.isPinned = decision.isPinned;
    newLogbook.text = decision.message;
    newLogbook.toERP = decision.toERP;
    newLogbook.customCreatedAt = decision.customCreatedAt;
    newLogbook.tags = decision.tags;
    newLogbook.createdBy = decision.createdBy;
    newLogbook.createdAt = decision.createdAt;

    newLogbook.customCreatedAt = decision.customCreatedAt;

    return newLogbook;
  }
}
